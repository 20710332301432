import React, { useState } from "react";
import { IconButton } from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import "./../../../styles/Common/Menu/menuFooter.sass";

type Props = {
  currentTheme: string;
  changeTheme: (theme: string) => void;
};

const ToggleTheme: React.FC<Props> = ({ currentTheme, changeTheme }) => {
  const [theme, setTheme] = useState(currentTheme);
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    changeTheme(newTheme);
  };

  return (
    <IconButton onClick={toggleTheme} style={{ marginRight: "5%" }}>
      {theme === "light" ? (
        <LightModeIcon
          style={{ color: currentTheme === "light" ? "black" : "white" }}
        />
      ) : (
        <ModeNightIcon
          style={{ color: currentTheme === "light" ? "black" : "white" }}
        />
      )}
    </IconButton>
  );
};

export default ToggleTheme;
