import { DateTime } from "luxon";
import { isNull, includes, isArray, has, every } from "lodash";
import { SignalElapsed, SignalValues } from "./../constants/enums";
import { TChartCandles, TChartLines } from "./../types/entities";

export const setThemeInStorage = (theme: any) => {
  localStorage.setItem("theme", theme);
};

export const getThemeInStorage = () => {
  return localStorage.getItem("theme") || "light";
};

export const setLanguageInStorage = (language: any) => {
  localStorage.setItem("language", language);
};

export const getLanguageInStorage = () => {
  return localStorage.getItem("language") || "en";
};

export const setUserInStorage = (user: any) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const getUserInStorage = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user as string) : null;
};

export const removeUserInStorage = () => {
  localStorage.removeItem("user");
};

export const setSettingsInStorage = (settings: any) => {
  localStorage.setItem("settings", JSON.stringify(settings));
};

export const getSettingsInStorage = () => {
  const settings = localStorage.getItem("settings");
  return settings ? JSON.parse(settings as string) : null;
};

export const pretty = (json: string) => {
  return json !== "" ? JSON.stringify(JSON.parse(json), null, 2) : "";
};

export const parseDbDates = (date: any) => {
  const format = "yyyy-MM-dd HH:mm:ss";
  return DateTime.fromFormat(date, format, {
    zone: "utc",
  });
};

export const isBuy = (field: number, elapsed: number | boolean = false) => {
  return elapsed === false
    ? field === SignalValues.Buy
    : field === SignalValues.Buy && elapsed === SignalElapsed.One;
};

export const isSell = (field: number, elapsed: number | boolean = false) => {
  return elapsed === false
    ? field === SignalValues.Sell
    : field === SignalValues.Sell && elapsed === SignalElapsed.One;
};

export const isNeutral = (field: number) => {
  return field === SignalValues.Neutral;
};

export const isBullish = (field: number) => {
  return field === SignalValues.Bullish;
};

export const isBearish = (field: number) => {
  return field === SignalValues.Bearish;
};

export const isGolden = (field: number) => {
  return field === SignalValues.Golden;
};

export const isDeath = (field: number) => {
  return field === SignalValues.Death;
};

export const isPositive = (field: number) => {
  return field === SignalValues.Positive;
};

export const isNegative = (field: number) => {
  return field === SignalValues.Negative;
};

export const validateCandle = (candle: any): boolean =>
  !isNull(candle.OPEN) &&
  !isNull(candle.CLOSE) &&
  !isNull(candle.HIGH) &&
  !isNull(candle.LOW);

export const validateEconomy = (economy: any): boolean =>
  !isNull(economy.VALUE);

export const validateFearGreed = (fearGreed: any): boolean =>
  !isNull(fearGreed.FG_VALUE);

export const createChartCandles = (candleData: any) => {
  let transformed: TChartCandles = [];
  for (const candle of candleData) {
    if (validateCandle(candle))
      transformed.push({
        time: parseDbDates(candle.OPENED_AT).toFormat("yyyy-MM-dd"),
        open: candle.OPEN,
        close: candle.CLOSE,
        high: candle.HIGH,
        low: candle.LOW,
      });
  }
  return transformed;
};

export const createChartEconomy = (economyData: any) => {
  let transformed: TChartLines = [];
  for (const economy of economyData) {
    if (validateEconomy(economy))
      transformed.push({
        time: parseDbDates(economy.DATE).toSeconds(),
        value: economy.VALUE,
      });
  }
  return transformed;
};

export const createChartFearGreed = (fearGreedData: any) => {
  let transformed: TChartLines = [];
  for (const fearGreed of fearGreedData) {
    if (validateFearGreed(fearGreed))
      transformed.push({
        time: parseDbDates(fearGreed.FG_AT).toSeconds(),
        value: fearGreed.FG_VALUE,
      });
  }
  return transformed;
};

export const createHorizontalLine = (
  economyData: any,
  value: number | null = null
) => {
  let transformed: TChartLines = [];
  if (value === null) return transformed;
  for (const economy of economyData) {
    transformed.push({
      time: parseDbDates(economy.DATE).toSeconds(),
      value,
    });
  }
  return transformed;
};

export const createMarkers = (
  candle: any,
  markerConfig: Array<any>,
  selected: Array<any>,
  validatorFn: (marker: any, candle: any) => boolean
) => {
  let transformed: any = [];
  const fromFormat = parseDbDates(candle.OPENED_AT);
  if (validateCandle(candle)) {
    const codes = selected.map((s) => s.CODE);
    for (const config of markerConfig) {
      const { code, markers } = config;
      if (includes(codes, code)) {
        for (const marker of markers) {
          if (validatorFn(marker, candle)) {
            transformed.push({
              time: {
                year: fromFormat.year,
                month: fromFormat.month,
                day: fromFormat.day,
              },
              ...marker.config,
            });
          }
        }
      }
    }
  }
  return transformed;
};

export const parseChartDates = (time: any | null | undefined) => {
  if (time === null || time === undefined || typeof time === "string")
    return time;
  else if (typeof time === "number")
    return DateTime.fromSeconds(time, {
      zone: "utc",
    }).toFormat("yyyy-MM-dd");
  else if (typeof time === "object")
    return DateTime.fromObject(time, {
      zone: "utc",
    }).toFormat("yyyy-MM-dd");
};

export const getDisabledDates = (period: any) => {
  const code = period.CODE;
  switch (code) {
    case "1w":
      return (date: any) => date.toFormat("EEEE") !== "Monday";
    case "1m":
      return (date: any) => date.toFormat("dd") !== "01";
    default:
      return (date: any) => false;
  }
};

export const getMaxDate = (period: any) => {
  const code = period.CODE;
  switch (code) {
    case "1d":
      return DateTime.utc().startOf("day").minus({ days: 1 });
    case "1w":
      return DateTime.utc().startOf("week").minus({ weeks: 1 });
    case "1m":
      return DateTime.utc().startOf("month").minus({ months: 1 });
  }
};

export const isValidJson = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return false;
  }
};

export const isCandle = (candle: any) =>
  has(candle, "OPENED_AT") &&
  has(candle, "OPEN") &&
  has(candle, "CLOSE") &&
  has(candle, "HIGH") &&
  has(candle, "LOW") &&
  parseDbDates(candle.OPENED_AT).isValid;

export const isCandles = (data: Array<any>) =>
  isArray(data) && every(data.map((c) => isCandle(c)));

export const isBacktestingCandles = (data: any) =>
  has(data, "symbol") &&
  has(data, "candles") &&
  isArray(data.candles) &&
  every(data.candles.map((candle: any) => isCandle(candle)));

export const isBacktestingSignal = (signal: any) =>
  has(signal, "OPENED_AT") &&
  has(signal, "BUY") &&
  has(signal, "SELL") &&
  parseDbDates(signal.OPENED_AT).isValid;

export const isBacktestingSignals = (data: Array<any>) =>
  isArray(data) &&
  every(
    data.map(
      (type) =>
        has(type, "name") &&
        has(type, "signals") &&
        every(type.signals.map((signal: any) => isBacktestingSignal(signal)))
    )
  );

export const isCandleJson = (json: string) =>
  isValidJson(json) ? isCandles(isValidJson(json)) : false;

export const isBacktestingCandleJson = (json: string) =>
  isValidJson(json) ? isBacktestingCandles(isValidJson(json)) : false;

export const isBacktestingSignalJson = (json: string) =>
  isValidJson(json) ? isBacktestingSignals(isValidJson(json)) : false;
