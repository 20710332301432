import React, { SyntheticEvent } from "react";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import BugReportIcon from "@mui/icons-material/BugReport";
import SpeedIcon from "@mui/icons-material/Speed";
import i18n from "i18next";
import { Box, Tabs, Tab } from "@mui/material";
import "../../../styles/Pages/Other/other.sass";
import RankingTab from "./Ranking/RankingTab";
import BacktestingTab from "./Backtesting/BacktestingTab";
import FearGreedTab from "./FearGreed/FearGreedTab";

const OtherPage: React.FC = () => {
  const [value, setValue] = React.useState("ranking");

  const onTabChange = (e: SyntheticEvent, tab: string) => {
    setValue(tab);
  };

  return (
    <div className="other-page">
      <Box sx={{ width: "100%", paddingBottom: "4%" }}>
        <Tabs value={value} onChange={onTabChange} variant="fullWidth">
          <Tab
            icon={<MilitaryTechIcon />}
            iconPosition="start"
            value="ranking"
            label={i18n.t("OTHER_RANKINGS_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
          <Tab
            icon={<SpeedIcon />}
            iconPosition="start"
            value="feargreed"
            label={i18n.t("OTHER_FEARGREED_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
          <Tab
            icon={<BugReportIcon />}
            iconPosition="start"
            value="backtesting"
            label={i18n.t("OTHER_BACKTESTING_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        {value === "ranking" && <RankingTab />}
        {value === "feargreed" && <FearGreedTab />}
        {value === "backtesting" && <BacktestingTab />}
      </Box>
    </div>
  );
};

export default OtherPage;
