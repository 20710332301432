import {
  OpenInFull,
  CloseFullscreen,
  Upload,
  Save,
  Close,
  Delete,
  ContentCopy,
  Download,
  LoginOutlined,
  LogoutOutlined,
  Settings,
} from "@mui/icons-material";
import { BaseButton } from "./BaseButton";

type Props = {
  [key: string]: any;
};

export const OpenInFullButton: React.FC<Props> = (props) => {
  return <BaseButton props={props} children={<OpenInFull />}></BaseButton>;
};

export const CloseFullscreenButton: React.FC<Props> = (props) => {
  return <BaseButton props={props} children={<CloseFullscreen />}></BaseButton>;
};

export const UploadButton: React.FC<Props> = (props) => {
  return <BaseButton props={props} children={<Upload />}></BaseButton>;
};

export const SaveButton: React.FC<Props> = (props) => {
  return <BaseButton props={props} children={<Save />}></BaseButton>;
};

export const CloseButton: React.FC<Props> = (props) => {
  return <BaseButton props={props} children={<Close />}></BaseButton>;
};

export const DeleteButton: React.FC<Props> = (props) => {
  return <BaseButton props={props} children={<Delete />}></BaseButton>;
};

export const ContentCopyButton: React.FC<Props> = (props) => {
  return <BaseButton props={props} children={<ContentCopy />}></BaseButton>;
};

export const DownloadButton: React.FC<Props> = (props) => {
  return <BaseButton props={props} children={<Download />}></BaseButton>;
};

export const LoginButton: React.FC<Props> = (props) => {
  return <BaseButton props={props} children={<LoginOutlined />}></BaseButton>;
};

export const LogoutButton: React.FC<Props> = (props) => {
  return <BaseButton props={props} children={<LogoutOutlined />}></BaseButton>;
};

export const SettingsButton: React.FC<Props> = (props) => {
  return <BaseButton props={props} children={<Settings />}></BaseButton>;
};
