import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { first } from "lodash";
import { Box, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../../../../store/configureStore";
import { TAppState } from "../../../../../store/reducers";
import BaseSelect from "../../../../Common/BaseSelect/BaseSelect";
import { DownloadButton } from "../../../../App/Buttons/Buttons";
import {
  liveProviderChanged,
  livePairChanged,
  liveJsonChanged,
} from "../../../../../store/reducers/charts";
import { ProviderTypes } from "../../../../../constants/enums";
import { chartsSelector } from "../../../../selectors";
import { isCandleJson } from "../../../../../utilities/helpers";
import {
  useGetLiveProvidersQuery,
  useGetLivePairsQuery,
} from "../../../../../features/api";
import { fetchCandles } from "../../../../../store/actions/charts";

type Props = {};

const ChartsLiveFields: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { data: providers, isLoading: providersLoading } =
    useGetLiveProvidersQuery();
  const { data: pairs, isLoading: pairsLoading } = useGetLivePairsQuery();
  const {
    live: {
      json,
      filters: { period, provider, pair },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));

  const providerSelected = (providers: any) => {
    dispatch(liveProviderChanged(first(providers)));
    if (first(providers).TYPE_CODE === ProviderTypes.Cex) {
      dispatch(
        livePairChanged(
          first(pairs.filter((p) => p.PROVIDER_ID === first(providers).ID))
        )
      );
      dispatch(liveJsonChanged(""));
    }
  };

  const pairSelected = (pairs: any) => {
    dispatch(livePairChanged(first(pairs)));
    dispatch(liveJsonChanged(""));
  };

  const download = () => {
    dispatch(fetchCandles({ period, provider, pair }));
  };

  const onJsonChange = (e) => {
    dispatch(liveJsonChanged(e.target.value));
  };

  return (
    <Stack style={{ padding: "2%" }}>
      <Stack
        spacing={{ xs: 2, sm: 2 }}
        direction="row"
        useFlexGap
        flexWrap="wrap"
        marginTop={2}
        marginBottom={2}
      >
        <Box>
          <BaseSelect
            data={providers}
            loading={providersLoading}
            onChange={providerSelected}
            selectKey="CODE"
            displayKey="NAME"
            inputLabel={t("PROVIDER_SELECT_INPUT_LABEL")}
            label={t("PROVIDER_SELECT_LABEL")}
            minWidth={200}
            maxWidth={200}
            optionInfo={["TYPE_DESCRIPTION"]}
            optionInfoStyle={{
              fontSize: "70%",
              color: "rgba(212,255,0,0.8)",
              paddingBottom: "1%",
            }}
            defaultValue={provider?.CODE}
          />
        </Box>
        <Box>
          <BaseSelect
            data={pairs?.filter((p: any) => p.PROVIDER_ID === provider?.ID)}
            loading={pairsLoading}
            onChange={pairSelected}
            selectKey="ID"
            displayKey="SYMBOL"
            inputLabel={t("PAIR_SELECT_INPUT_LABEL")}
            label={t("PAIR_SELECT_LABEL")}
            minWidth={200}
            maxWidth={200}
            formatDisplay={(row, key) =>
              row[key].replaceAll(row.SYMBOL_PREFIX, "")
            }
            optionInfo={["TYPE_NAME", "PROVIDER_NAME"]}
            optionInfoStyle={{
              fontSize: "70%",
              color: "rgba(212,255,0,0.8)",
              paddingBottom: "1%",
            }}
            sortFn={(row: any) => [
              row.SYMBOL.replaceAll(row.SYMBOL_PREFIX, ""),
            ]}
            hasSearch
            searchKey="SYMBOL"
            defaultValue={pair?.ID}
          />
        </Box>
        <Box>
          <DownloadButton onClick={() => download()} />
        </Box>
      </Stack>
      <Box>
        <TextField
          multiline
          onChange={onJsonChange}
          value={json}
          fullWidth
          rows={11}
          label={t("CHARTS_LIVE_JSON_LABEL")}
          error={!isCandleJson(json)}
        />
      </Box>
    </Stack>
  );
};

export default ChartsLiveFields;
