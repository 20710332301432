import React, { useEffect, useMemo, useState } from "react";
import { includes, flatten, sortBy } from "lodash";
import {
  FormControl,
  Select,
  InputLabel,
  Checkbox,
  MenuItem,
  ListItemText,
  SelectChangeEvent,
  OutlinedInput,
  ListSubheader,
  InputAdornment,
  TextField,
  useScrollTrigger,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

type Props = {
  onChange: any;
  multiselect?: boolean;
  disabled?: boolean;
  data?: any[];
  loading?: boolean;
  selectKey?: string;
  displayKey?: string;
  label?: string | null | undefined;
  inputLabel?: string | null | undefined;
  minWidth?: string | number;
  maxWidth?: string | number;
  className?: string;
  defaultValue?: string | string[];
  itemHeight?: number;
  itemPaddingTop?: number;
  formatDisplay?: (row: any, key: string) => string;
  override?: boolean;
  overrideData?: (row: any) => any[];
  overrideSelectKey?: string;
  overrideDisplayKey?: string;
  optionInfo?: string[];
  optionInfoStyle?: any;
  optionInfoSeparator?: string;
  hasHeader?: boolean | ((row: any) => boolean);
  headerTitleKey?: string;
  sortFn?: (row: any) => any | string[] | [];
  hasSearch?: boolean;
  searchKey?: string;
  menuZIndex?: number;
};

const BaseSelect: React.FC<Props> = ({
  onChange,
  multiselect = false,
  disabled = false,
  data = [],
  loading = false,
  selectKey = "",
  displayKey = "",
  label = "",
  inputLabel = "",
  minWidth = 100,
  maxWidth,
  className = "",
  defaultValue,
  itemHeight = 48,
  itemPaddingTop = 8,
  formatDisplay = (row, key) => row[key],
  override = false,
  overrideData = (row) => [row],
  overrideSelectKey = "",
  overrideDisplayKey = "",
  optionInfo = [],
  optionInfoStyle = {},
  optionInfoSeparator = "-",
  sortFn = (row: any) => [],
  hasHeader = false,
  headerTitleKey = "",
  hasSearch = false,
  searchKey = "",
  menuZIndex = 1000000000,
}) => {
  const scrollTrigger = useScrollTrigger();
  /*
   * For override defaultValue has to be provided externally
   */
  const initial = useMemo(
    () =>
      multiselect
        ? defaultValue
          ? defaultValue
          : []
        : defaultValue
        ? defaultValue
        : "",
    [defaultValue, multiselect]
  );

  const { t } = useTranslation();

  const [values, setValues] = useState<"" | string | string[] | undefined>(
    initial
  );

  const [isOpen, setIsOpen] = useState(false);

  const [searchText, setSearchText] = useState("");

  const displayData = useMemo(
    () =>
      data.filter((row) =>
        hasSearch && searchText !== ""
          ? row[searchKey].toLowerCase().indexOf(searchText.toLowerCase()) > -1
          : true
      ),
    [data, hasSearch, searchKey, searchText]
  );

  const handleChange = (event: SelectChangeEvent<typeof values>) => {
    /* value: multiselect returns [] while single string */
    const {
      target: { value },
    } = event;
    setValues(value);
    const sKey = override ? overrideSelectKey : selectKey;
    const dataset = override ? flatten(data.map((s) => overrideData(s))) : data;
    const result = dataset.filter((b) =>
      multiselect ? includes(value, b[sKey]) : b[sKey] === value
    );
    onChange(result);
  };

  const getMenuItem = (row: any, sKey: string, dKey: string) => (
    <MenuItem value={row[sKey]} key={`menu_item_${row[sKey]}`}>
      {multiselect ? (
        <>
          <Checkbox checked={includes(values, row[sKey])} />
          <ListItemText primary={formatDisplay(row, dKey)} />
        </>
      ) : (
        <div>
          <div>{formatDisplay(row, dKey)}</div>
          <div style={optionInfoStyle}>
            {optionInfo.map((oi) => row[oi]).join(` ${optionInfoSeparator} `)}
          </div>
        </div>
      )}
    </MenuItem>
  );

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setSearchText("");
    setIsOpen(false);
  };

  useEffect(() => {
    setValues(initial);
  }, [initial]);

  useEffect(() => {
    scrollTrigger && onClose();
  }, [scrollTrigger]);

  return (
    <FormControl size="small" fullWidth>
      {loading ? (
        <>{t("BASE_SELECT_LOADING")}</>
      ) : (
        <>
          <InputLabel id="select-label">{inputLabel}</InputLabel>
          <Select
            style={{ minWidth, maxWidth }}
            disabled={disabled}
            className={className}
            label={label}
            labelId="select-label"
            open={isOpen}
            onChange={handleChange}
            onOpen={onOpen}
            onClose={onClose}
            value={values}
            input={<OutlinedInput label="Tag" />}
            multiple={multiselect}
            renderValue={(selected) => {
              const sKey = override ? overrideSelectKey : selectKey;
              const dKey = displayKey;
              const dataset = override
                ? flatten(data.map((s) => overrideData(s)))
                : data;
              return dataset
                .filter((r) =>
                  multiselect
                    ? includes(selected, r[sKey])
                    : selected === r[sKey]
                )
                .map((s) => formatDisplay(s, dKey))
                .join(", ");
            }}
            MenuProps={{
              autoFocus: !hasSearch,
              style: {
                zIndex: menuZIndex,
              },
              PaperProps: {
                style: {
                  maxHeight: itemHeight * 4.5 + itemPaddingTop,
                  minWidth,
                },
              },
            }}
          >
            {hasSearch && (
              <TextField
                size="small"
                autoFocus
                sx={{ padding: "2%" }}
                placeholder={
                  t("BASE_SELECT_SEARCH_PLACEHOLDER") || "Type to search..."
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    /* Prevents autoselect while typing */
                    e.stopPropagation();
                  }
                }}
              />
            )}
            {displayData.length > 0 &&
              sortBy(displayData, (r) => sortFn(r)).map((s) => {
                const sKey = override ? overrideSelectKey : selectKey;
                const dKey = override ? overrideDisplayKey : displayKey;
                return [
                  typeof hasHeader === "boolean"
                    ? hasHeader
                    : hasHeader(s) && (
                        <ListSubheader key={`header_${s[selectKey]}`}>
                          {s[headerTitleKey]}
                        </ListSubheader>
                      ),
                  override
                    ? overrideData(s).map((o) => getMenuItem(o, sKey, dKey))
                    : getMenuItem(s, sKey, dKey),
                ];
              })}
          </Select>
        </>
      )}
    </FormControl>
  );
};

export default BaseSelect;
