import { CircularProgress } from "@mui/material";
import React from "react";

const Spinner: React.FC = () => {
  return (
    <div
      className="app-spinner-container hide"
      style={{
        position: "fixed",
        top: "45%",
        left: "50%",
        zIndex: 300000000,
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default Spinner;
