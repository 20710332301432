import { DateTime } from "luxon";
import { includes, first } from "lodash";
import {
  createMarkers,
  parseDbDates,
  validateCandle,
  isBuy,
  isSell,
  isBullish,
  isBearish,
  isGolden,
  isDeath,
} from "../../utilities/helpers";

export const filterSignalData = (code: any, data: Array<any> | null) =>
  data ? data.filter((row: any) => validateSignalByCode(code, row)) : [];

export const validateSignalByCode = (code: string, signal: any) => {
  switch (code) {
    case "escan":
      return (
        isBuy(signal.ESCAN_5, signal.ELAPSED_5) ||
        isSell(signal.ESCAN_5, signal.ELAPSED_5)
      );
    case "cross":
      return isGolden(signal.CROSS_50_200) || isDeath(signal.CROSS_50_200);
    case "macd4cdiv":
      return (
        isBullish(signal.CLASSIC_20_50_9) ||
        isBearish(signal.CLASSIC_20_50_9) ||
        isBullish(signal.HIDDEN_20_50_9) ||
        isBearish(signal.HIDDEN_20_50_9)
      );
    case "uscan":
      return (
        isBuy(signal.USCAN_5, signal.ELAPSED_5) ||
        isSell(signal.USCAN_5, signal.ELAPSED_5)
      );
  }
};

export const validateSignalByName = (
  keyValue: any,
  candle: any,
  full: boolean = true
) => {
  const { key, value } = keyValue;
  switch (key) {
    case "CROSS_50_200":
    case "CLASSIC_20_50_9":
    case "HIDDEN_20_50_9":
      return candle[key] === value;
    case "ESCAN_5":
      return (
        candle[key] === value &&
        (full ? candle.ESCAN_ELAPSED_5 === 1 : candle.ELAPSED_5 === 1)
      );
    case "USCAN_5":
      return (
        candle[key] === value &&
        (full ? candle.USCAN_ELAPSED_5 === 1 : candle.ELAPSED_5 === 1)
      );
    default:
      return false;
  }
};

export const candleOperation = (left: any, right: any, op: string) => {
  switch (op) {
    case "=":
      return left === right;
    case ">":
      return left > right;
    case "<":
      return left < right;
    case ">=":
      return left >= right;
    case "<=":
      return left <= right;
    default:
      return false;
  }
};

export const validateAnalysisByName = (
  marker: any,
  candle: any,
  op: string
) => {
  const { key, value } = marker;
  switch (key) {
    case "RSIDIV_14":
    case "RSIDIV_21":
    case "MFIDIV_7":
    case "MFIDIV_14":
    case "TAIL":
      return candleOperation(candle[key], value, op);
    default:
      return false;
  }
};

export const validateIndicator = (marker: any, candle: any) => {
  const { key, value } = marker;
  switch (key) {
    case "FRACTAL_2":
      return candle[key] === value;
  }
};

export const validatePattern = (marker: any, candle: any) => {
  const { key, value } = marker;
  switch (key) {
    case "FRACTAL_TREND_2":
    case "FRACTAL_PRICE_TREND_1":
      return candle[key] === value;
  }
};

export const toPrecision = (value: number) => {
  if (Math.floor(value) === value) return 0;
  const split = value.toString().split(".");
  return split.length > 1 ? split[1].replace(/0+$/, "").length || 0 : 0;
};

export const isNotNull = (value: any): boolean => value !== null;

export const getChartLineSeries = (
  candleData: Array<any>,
  lineSeriesConfig: Array<any>,
  indicators: Array<string>,
  dateField = "OPENED_AT"
) => {
  let transformed: any = {};
  let previous = null;
  let count = 0;
  for (const candle of candleData) {
    const date = parseDbDates(candle[dateField]);
    for (const serie of lineSeriesConfig) {
      const { code, label, lines } = serie;
      if (includes(indicators, code)) {
        for (const line of lines) {
          const { key, config, options = {}, style = {} } = line;
          const {
            field,
            zero,
            upColor,
            downColor,
            firstCandleUpDown = false,
          } = style;
          if (!transformed[code])
            transformed[code] = {
              lines: {},
              label,
            };
          if (!transformed[code].lines[key])
            transformed[code].lines[key] = {
              config,
              options,
              data: [],
            };
          if (isNotNull(candle[key])) {
            let v = {
              time: date.toSeconds(),
              value: Number(candle[key]),
            } as any;
            if (
              field &&
              zero !== undefined &&
              upColor &&
              downColor &&
              candle[field]
            ) {
              if (zero) {
                v.color = candle[field] > 0 ? upColor : downColor;
              } else {
                if (previous?.[field])
                  v.color =
                    previous[field] <= candle[field] ? upColor : downColor;
                else if (firstCandleUpDown && count === 0)
                  v.color = candle.OPEN <= candle.CLOSE ? upColor : downColor;
              }
            }
            transformed?.[code]?.lines[key]?.data?.push(v);
          }
        }
      }
    }
    previous = candle;
    count++;
  }
  return transformed;
};

export const getChartMarkers = (
  candleData: Array<any>,
  signalMarkers: Array<any>,
  signals: Array<any>,
  indicatorMarkers: Array<any>,
  indicators: Array<any>,
  patternMarkers: Array<any>,
  patterns: Array<any>
) => {
  let transformed: any = [];
  for (const candle of candleData) {
    const date = parseDbDates(candle.OPENED_AT);
    const { year, month, day } = date;
    const created = createMarkers(
      candle,
      signalMarkers,
      signals,
      validateSignalByName
    );
    transformed = [...transformed, ...created];
    for (const indicator of indicatorMarkers) {
      const { code, markers } = indicator;
      if (includes(indicators, code)) {
        for (const marker of markers) {
          const { config } = marker;
          if (validateIndicator(marker, candle)) {
            transformed.push({
              time: { year, month, day },
              ...config,
            });
          }
        }
      }
    }
    for (const pattern of patternMarkers) {
      const { code, markers } = pattern;
      if (includes(patterns, code)) {
        for (const marker of markers) {
          const { config } = marker;
          if (validatePattern(marker, candle)) {
            transformed.push({
              time: { year, month, day },
              ...config,
            });
          }
        }
      }
    }
  }
  return transformed;
};

export const getAnalysisMarkers = (
  candleData: Array<any>,
  markerConfig: Array<any>,
  activeMetrics: Array<any>
) => {
  let transformed: any = [];
  for (const candle of candleData) {
    const date = parseDbDates(candle.OPENED_AT);
    const { year, month, day } = date;
    if (validateCandle(candle)) {
      for (const marker of markerConfig) {
        const { key, op = "=", config } = marker;
        if (
          validateAnalysisByName(marker, candle, op) &&
          includes(activeMetrics, key)
        ) {
          transformed.push({
            time: { year, month, day },
            ...config,
          });
        }
      }
    }
  }
  return transformed;
};

const parseRecession = (row: any) => {
  const format = "yyyy-MM-dd HH:mm:ss";
  const start = DateTime.fromFormat(row.PEAK, format);
  const end = DateTime.fromFormat(row.TROUGH, format);
  const { months } = end.diff(start, "months").toObject();
  return { start, months };
};

export const createRecessionMarkers = (
  recession: Array<any>,
  config: any,
  data: Array<any>
) => {
  let transformed: any = [];
  const initial = first(data)?.DATE;
  const format = "yyyy-MM-dd HH:mm:ss";
  if (initial) {
    const from = DateTime.fromFormat(initial, format);
    for (const row of recession) {
      if (!isNotNull(row.PEAK)) continue;
      const { start, months } = parseRecession(row);
      if (start > from) {
        let date = start;
        for (let m = 1; m <= months; m++) {
          const { day, month, year } = date;
          transformed.push({
            time: { year, month, day },
            ...config,
          });
          date = date.plus({ months: 1 });
        }
      }
    }
  }
  return transformed;
};

export const sortMarkers = (markers: any) => {
  return markers.sort(
    (a: any, b: any) =>
      a.time.year - b.time.year ||
      a.time.month - b.time.month ||
      a.time.day - b.time.day
  );
};
