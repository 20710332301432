import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { first } from "lodash";
import { Box } from "@mui/material";
import Grid from "../../../Common/Grid/Grid";
import { fetchSignalByOpenedAt } from "../../../../store/actions/signal";
import { fetchLastSynced } from "../../../../store/actions/period";
import { AppDispatch } from "../../../../store/configureStore";
import { TAppState } from "../../../../store/reducers";
import { signalSelector } from "../../../selectors";
import getColumns, {
  gridKey,
  getVisibility,
  getSignalRowClass,
} from "./SignalsGridConfig";
import { filterSignalData } from "../../../Common/helpers";
import {
  useGetActivePeriodsQuery,
  useGetActiveSignalsQuery,
} from "../../../../features/api";
import { listPeriodChanged } from "../../../../store/reducers/signal";

const SignalsList: React.FC = () => {
  const { data: periods, isLoading: periodsLoading } =
    useGetActivePeriodsQuery();
  const { data: signals, isLoading: signalsLoading } =
    useGetActiveSignalsQuery();
  const dispatch = useDispatch<AppDispatch>();
  const {
    list: {
      data,
      filters: { signal, period, openedAt },
    },
  } = useSelector((state: TAppState) => signalSelector(state));

  useEffect(() => {
    if (!periodsLoading && !signalsLoading) {
      const period = first(periods);
      dispatch(listPeriodChanged({ period, signals }));
    }
  }, [dispatch, periods, periodsLoading, signals, signalsLoading]);

  useEffect(() => {
    if (period) {
      const periodId = period.ID;
      dispatch(fetchLastSynced({ periodId }));
    }
  }, [dispatch, period]);

  useEffect(() => {
    if (signal && period && openedAt) {
      const signalId = signal?.ID;
      const periodId = period?.ID;
      dispatch(
        fetchSignalByOpenedAt({
          signalId,
          periodId,
          openedAt,
        })
      );
    }
  }, [dispatch, signal, openedAt, period]);

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Grid
        gridKey={gridKey}
        columns={getColumns(signal?.CODE)}
        data={filterSignalData(signal?.CODE, data)}
        visibility={getVisibility()}
        getRowClassName={(params) => {
          const { row } = params;
          return getSignalRowClass(signal?.CODE, row);
        }}
      />
    </Box>
  );
};

export default SignalsList;
