import * as React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import FaqAccordion from "../../../../Common/FaqAccordion/FaqAccordion";

type Props = {};

const BacktestingUploadInfo: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ padding: "2% 10% 10% 10%", width: "100%" }}>
      <div style={{ paddingBottom: 20 }}>
        <FaqAccordion
          prettyPrint
          title={t("BACKTESTING_CANDLES_ACCORDION_TITLE")}
          description={t("BACKTESTING_CANDLES_ACCORDION_DESCRIPTION")}
          enableCopy
        />
      </div>
      <div style={{ paddingBottom: 20 }}>
        <FaqAccordion
          prettyPrint
          title={t("BACKTESTING_SIGNALS_ACCORDION_TITLE")}
          description={t("BACKTESTING_SIGNALS_ACCORDION_DESCRIPTION")}
          enableCopy
        />
      </div>
    </Box>
  );
};

export default BacktestingUploadInfo;
