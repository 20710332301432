import React, { useRef, useImperativeHandle, Ref } from "react";
import { DateTime } from "luxon";
import { last } from "lodash";
import i18n from "i18next";
import { parseChartDates } from "../../../../utilities/helpers";

const CandleLegend = React.forwardRef(
  (props: {}, ref: Ref<{ setCandleLegend: any }>) => {
    const openRef = useRef(null) as any;
    const closeRef = useRef(null) as any;
    const highRef = useRef(null) as any;
    const lowRef = useRef(null) as any;

    useImperativeHandle(ref, () => ({ setCandleLegend }));

    const setCandleLegend = (candleData: any, time = null) => {
      const parsedDate = parseChartDates(time);
      const [candle] =
        parsedDate === null
          ? [last(candleData)]
          : candleData.filter(
              (c) =>
                DateTime.fromObject(c.time, {
                  zone: "utc",
                }).toFormat("yyyy-MM-dd") === parsedDate
            );
      if (candle !== undefined) {
        const { open, close, high, low } = candle;
        if (openRef.current != null) openRef.current.innerText = open;
        if (closeRef.current != null) closeRef.current.innerText = close;
        if (highRef.current != null) highRef.current.innerText = high;
        if (lowRef.current != null) lowRef.current.innerText = low;
      }
    };

    return (
      <div
        style={{
          display: "flex",
          gap: 10,
        }}
      >
        <div>
          <span>{i18n.t("CANDLE_OPEN")}: </span>
          <span ref={openRef}></span>
        </div>
        <div>
          <span>{i18n.t("CANDLE_CLOSE")}: </span>
          <span ref={closeRef}></span>
        </div>
        <div>
          <span>{i18n.t("CANDLE_HIGH")}: </span>
          <span ref={highRef}></span>
        </div>
        <div>
          <span>{i18n.t("CANDLE_LOW")}: </span>
          <span ref={lowRef}></span>
        </div>
      </div>
    );
  }
);

export default CandleLegend;
