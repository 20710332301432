import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Grid from "../../../../Common/Grid/Grid";
import { fetchRanking } from "../../../../../store/actions/other";
import { TAppState } from "../../../../../store/reducers";
import { AppDispatch } from "../../../../../store/configureStore";
import { otherSelector } from "../../../../selectors";
import getColumns, {
  gridKey,
  getVisibility,
  getSortModel,
} from "./RankingGridConfig";

const RankingGrid: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    ranking: { data },
  } = useSelector((state: TAppState) => otherSelector(state));
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchRanking());
  }, [dispatch]);

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <h3 style={{ textAlign: "center" }}>
        {t("OTHER_RANKING_CRYPTO_GRID_HEADER")}
      </h3>
      <Grid
        gridKey={gridKey}
        data={data}
        columns={getColumns()}
        visibility={getVisibility()}
        sortModel={getSortModel()}
      />
    </Box>
  );
};

export default RankingGrid;
