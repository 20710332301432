import { enSignalsGrid, elSignalsGrid } from "./Grid/translations";
import { enSignalsFaq, elSignalsFaq } from "./Faq/translations";
import { enSignalsBar, elSignalsBar } from "./Count/translations";

export const enSignalsPage = {
  ...enSignalsGrid,
  ...enSignalsFaq,
  ...enSignalsBar,
};

export const elSignalsPage = {
  ...elSignalsGrid,
  ...elSignalsFaq,
  ...elSignalsBar,
};
