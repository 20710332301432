export const enRankingFaq = {
  OTHER_RANKING_FAQ_TITLE_MC_RANK: "What is Market Cap Rank?",
  OTHER_RANKING_FAQ_DESCRIPTION_MC_RANK:
    "Market Cap Rank is the position of the asset if ranked by market cap (lower rank number means higher market cap).",
  OTHER_RANKING_FAQ_TITLE_MC_RANK_1D: "What is Market Cap Rank (1D)?",
  OTHER_RANKING_FAQ_DESCRIPTION_MC_RANK_1D:
    "Market Cap Rank (1D) is the market cap position(s) that an asset gained (positive) or lost (negative) in the last day.",
  OTHER_RANKING_FAQ_TITLE_MC_RANK_7D: "What is Market Cap Rank (7D)?",
  OTHER_RANKING_FAQ_DESCRIPTION_MC_RANK_7D:
    "Market Cap Rank (7D) is the market cap position(s) that an asset gained (positive) or lost (negative) in the last 7 days.",
  OTHER_RANKING_FAQ_TITLE_MC_RANK_30D: "What is Market Cap Rank (30D)?",
  OTHER_RANKING_FAQ_DESCRIPTION_MC_RANK_30D:
    "Market Cap Rank (30D) is the market cap position(s) that an asset gained (positive) or lost (negative) in the last 30 days.",
  OTHER_RANKING_FAQ_TITLE_LQ_RANK: "What is Liquidity Rank?",
  OTHER_RANKING_FAQ_DESCRIPTION_LQ_RANK:
    "Liquidity Rank is the position of the asset if ranked by liquiditiy (lower rank number means higher liquidity).",
  OTHER_RANKING_FAQ_TITLE_LQ_RANK_1D: "What is Liquidity Rank (1D)?",
  OTHER_RANKING_FAQ_DESCRIPTION_LQ_RANK_1D:
    "Liquidity Rank (1D) is the liquidity position(s) that an asset gained (positive) or lost (negative) in the last day.",
  OTHER_RANKING_FAQ_TITLE_LQ_RANK_7D: "What is Liquidity Rank (7D)?",
  OTHER_RANKING_FAQ_DESCRIPTION_LQ_RANK_7D:
    "Liquidity Rank (7D) is the liquidity position(s) that an asset gained (positive) or lost (negative) in the last 7 days.",
  OTHER_RANKING_FAQ_TITLE_LQ_RANK_30D: "What is Liquidity Rank (30D)?",
  OTHER_RANKING_FAQ_DESCRIPTION_LQ_RANK_30D:
    "Liquidity Rank (30D) is the liquidity position(s) that an asset gained (positive) or lost (negative) in the last 30 days.",
};

export const elRankingFaq = {
  OTHER_RANKING_FAQ_TITLE_MC_RANK: "Τι είναι η Κατάταξη Κεφαλαιοποίησης;",
  OTHER_RANKING_FAQ_DESCRIPTION_MC_RANK:
    "Η Κατάταξη Κεφαλαιοποίησης είναι η θέση του περιουσιακού στοιχείου εάν καταταχθεί κατά κεφαλαιοποίηση (χαμηλότερος αριθμός κατάταξης σημαίνει υψηλότερη κεφαλαιοποίηση αγοράς).",
  OTHER_RANKING_FAQ_TITLE_MC_RANK_1D:
    "Τι είναι η Κατάταξη Κεφαλαιοποίησης (1D);",
  OTHER_RANKING_FAQ_DESCRIPTION_MC_RANK_1D:
    "Η Κατάταξη Κεφαλαιοποίησης (1D) είναι η θέση(εις) κεφαλαιοποίησης που κέρδισε (θετικό) ή έχασε (αρνητικό) ένα περιουσιακό στοιχείο την τελευταία ημέρα.",
  OTHER_RANKING_FAQ_TITLE_MC_RANK_7D:
    "Τι είναι η Κατάταξη Κεφαλαιοποίησης (7D);",
  OTHER_RANKING_FAQ_DESCRIPTION_MC_RANK_7D:
    "Η Κατάταξη Κεφαλαιοποίησης (7D) είναι οι θέσεις κεφαλαιοποίησης που κέρδισε (θετικό) ή έχασε (αρνητικό) ένα περιουσιακό στοιχείο τις τελευταίες 7 ημέρες.",
  OTHER_RANKING_FAQ_TITLE_MC_RANK_30D:
    "Τι είναι η Κατάταξη Κεφαλαιοποίησης (30D);",
  OTHER_RANKING_FAQ_DESCRIPTION_MC_RANK_30D:
    "Η Κατάταξη Κεφαλαιοποίησης (30D) είναι η θέση(εις) κεφαλαιοποίησης που κέρδισε (θετικό) ή έχασε (αρνητικό) ένα περιουσιακό στοιχείο τις τελευταίες 30 ημέρες.",
  OTHER_RANKING_FAQ_TITLE_LQ_RANK: "Τι είναι η Κατάταξη Ρευστότητας;",
  OTHER_RANKING_FAQ_DESCRIPTION_LQ_RANK:
    "Κατάταξη Ρευστότητας είναι η θέση του περιουσιακού στοιχείου εάν κατατάσσεται βάσει ρευστότητας (ο χαμηλότερος αριθμός κατάταξης σημαίνει υψηλότερη ρευστότητα).",
  OTHER_RANKING_FAQ_TITLE_LQ_RANK_1D: "Τι είναι η Κατάταξη Ρευστότητας (1D);",
  OTHER_RANKING_FAQ_DESCRIPTION_LQ_RANK_1D:
    "Κατάταξη Ρευστότητας (1D) είναι η(οι) θέση(εις) ρευστότητας που ένα περιουσιακό στοιχείο κέρδισε (θετικό) ή έχασε (αρνητικό) την τελευταία ημέρα.",
  OTHER_RANKING_FAQ_TITLE_LQ_RANK_7D: "Τι είναι η Κατάταξη Ρευστότητας (7D);",
  OTHER_RANKING_FAQ_DESCRIPTION_LQ_RANK_7D:
    "Κατάταξη Ρευστότητας (7D) είναι η(οι) θέση(εις) ρευστότητας που ένα περιουσιακό στοιχείο κέρδισε (θετικό) ή έχασε (αρνητικό) τις τελευταίες 7 ημέρες.",
  OTHER_RANKING_FAQ_TITLE_LQ_RANK_30D: "Τι είναι η Κατάταξη Ρευστότητας (30D);",
  OTHER_RANKING_FAQ_DESCRIPTION_LQ_RANK_30D:
    "Κατάταξη Ρευστότητας (30D) είναι η(οι) θέση(εις) ρευστότητας που ένα περιουσιακό στοιχείο κέρδισε (θετικό) ή έχασε (αρνητικό) τις τελευταίες 30 ημέρες.",
};
