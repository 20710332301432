import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

type Props = {
  isOpen: boolean;
  children: React.ReactNode;
  onCloseCallback?: any;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog: React.FC<Props> = ({
  children,
  isOpen,
  onCloseCallback = null,
}) => {
  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={onCloseCallback}
        TransitionComponent={Transition}
        style={{
          zIndex: 200000,
        }}
      >
        {children}
      </Dialog>
    </div>
  );
};

export default FullScreenDialog;
