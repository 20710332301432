import React, { useEffect, useState } from "react";
import { Snackbar, Alert, SxProps } from "@mui/material";

type Props = {
  isOpen: boolean;
  message: string | undefined;
  isAlert?: boolean;
  alertSeverity?: "success" | "info" | "warning" | "error";
  defaultValue?: boolean;
  autoHideDuration?: number | null;
  vertical?: "top" | "bottom";
  horizontal?: "left" | "center" | "right";
  onClose?: () => void;
  snackBarSx?: SxProps;
  alertSx?: SxProps;
};

const BaseSnackbar: React.FC<Props> = ({
  isOpen,
  message,
  isAlert = false,
  alertSeverity = "info",
  autoHideDuration = 3000,
  vertical = "bottom",
  horizontal = "right",
  onClose,
  snackBarSx = {},
  alertSx = {},
}) => {
  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    onClose !== undefined && onClose();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      message={isAlert ? null : message}
      key={vertical + horizontal}
      autoHideDuration={autoHideDuration}
      sx={{ ...snackBarSx }}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={alertSeverity}
        variant="filled"
        sx={{ ...alertSx }}
        hidden={!isAlert}
      >
        {isAlert ? message : null}
      </Alert>
    </Snackbar>
  );
};

export default BaseSnackbar;
