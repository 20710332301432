import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { AppDispatch } from "../../../store/configureStore";
import { ROUTES } from "../../../constants/routes";
import { logout } from "../../../store/actions/user";
import {
  getSettingsInStorage,
  getUserInStorage,
} from "../../../utilities/helpers";

const UserMenu: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const profileData = getUserInStorage();
  const settings = getSettingsInStorage();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleMenuClick = () => {
    handleCloseUserMenu();
    try {
      if (settings) {
        const { authActive } = settings;
        authActive && dispatch(logout()).unwrap();
        navigate(authActive ? ROUTES.LOGIN : ROUTES.HOME);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar
            alt={profileData?.name || "?"}
            src="/static/images/avatar/2.jpg"
          />
        </IconButton>
      </Tooltip>
      <Menu
        disableScrollLock
        sx={{ mt: "45px" }}
        id="menu-appbar"
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem>
          <Box
            onClick={handleMenuClick}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Typography textAlign="center">
              {t("USER_MENU_ITEM_LOGOUT")}
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;
