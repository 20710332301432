import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { TAppState } from "../../../../store/reducers";
import TradingChart from "../../../Common/TradingChart/TradingChart";
import { backtestingSelector } from "../../../selectors";
import { parseCandles, parseSignals } from "./helpers";

type Props = {
  fullScreen?: boolean;
  autoSize?: boolean;
};

const BacktestingChart: React.FC<Props> = ({
  fullScreen = false,
  autoSize = false,
}) => {
  const { json } = useSelector((state: TAppState) =>
    backtestingSelector(state)
  );
  const { candles, signals } = json;
  const { symbol, candleData } = parseCandles(candles);
  const signalMarkers = parseSignals(signals);

  return (
    <Box sx={{ width: "100%" }}>
      <TradingChart
        height={500}
        symbol={symbol}
        candleData={candleData}
        markers={signalMarkers}
        fullScreen={fullScreen}
        autoSize={autoSize}
      />
    </Box>
  );
};

export default BacktestingChart;
