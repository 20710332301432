import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import LeftRightChart from "../../../../Common/LeftRightChart/LeftRightChart";
import { TAppState } from "../../../../../store/reducers";
import { otherSelector } from "../../../../selectors";
import { createChartFearGreed } from "../../../../../utilities/helpers";

const FearGreedChart: React.FC = () => {
  const {
    fearAndGreed: { crypto },
  } = useSelector((state: TAppState) => otherSelector(state));
  const { t } = useTranslation();

  return (
    <Box>
      <LeftRightChart
        fullScreen={false}
        autoSize={true}
        height={300}
        rightConfig={{
          lineWidth: 1,
          color: "#40E0D0",
          lastValueVisible: true,
          priceLineVisible: false,
        }}
        rightData={createChartFearGreed(crypto)}
        rightLegendLabel={t("OTHER_FEARGREED_CRYPTO_CHART_LEGEND")}
      />
    </Box>
  );
};

export default FearGreedChart;
