import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { first } from "lodash";
import { Box, Stack } from "@mui/material";
import BaseSelect from "../../../Common/BaseSelect/BaseSelect";
import { TAppState } from "../../../../store/reducers";
import { AppDispatch } from "../../../../store/configureStore";
import {
  chartPeriodChanged,
  chartPairChanged,
  chartAnalysisChanged,
} from "../../../../store/reducers/analysis";
import { analysisSelector } from "../../../selectors";
import {
  useGetActivePeriodsQuery,
  useGetActivePairsQuery,
  useGetActiveAnalysesQuery,
} from "../../../../features/api";

const AnalysisChartFilters: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { data: periods, isLoading: periodsLoading } =
    useGetActivePeriodsQuery();
  const { data: pairs, isLoading: pairsLoading } = useGetActivePairsQuery();
  const { data: analyses } = useGetActiveAnalysesQuery();
  const {
    chart: { filteredAnalyses },
  } = useSelector((state: TAppState) => analysisSelector(state));

  const periodSelected = (periods: any) => {
    dispatch(chartPeriodChanged({ period: first(periods), analyses }));
  };
  const analysisSelected = (analyses: any) => {
    dispatch(chartAnalysisChanged({ analysis: first(analyses) }));
  };
  const pairSelected = (pairs: any) => {
    dispatch(chartPairChanged({ pair: first(pairs) }));
  };

  return (
    <Stack
      spacing={{ xs: 2, sm: 2 }}
      direction="row"
      useFlexGap
      flexWrap="wrap"
      marginTop={2}
      marginBottom={2}
    >
      <Box>
        <BaseSelect
          data={periods}
          loading={periodsLoading}
          onChange={periodSelected}
          formatDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          selectKey="CODE"
          inputLabel={t("PERIOD_SELECT_INPUT_LABEL")}
          label={t("PERIOD_SELECT_LABEL")}
          minWidth={150}
          maxWidth={150}
          defaultValue={first(periods)?.CODE}
        />
      </Box>
      <Box>
        <BaseSelect
          data={pairs}
          loading={pairsLoading}
          onChange={pairSelected}
          selectKey="ID"
          displayKey="SYMBOL"
          inputLabel={t("PAIR_SELECT_INPUT_LABEL")}
          label={t("PAIR_SELECT_LABEL")}
          minWidth={200}
          maxWidth={200}
          defaultValue={first(pairs)?.ID}
          formatDisplay={(row, key) =>
            row[key].replaceAll(row.SYMBOL_PREFIX, "")
          }
          optionInfo={["TYPE_NAME", "PROVIDER_NAME"]}
          optionInfoStyle={{
            fontSize: "70%",
            color: "rgba(212,255,0,0.8)",
            paddingBottom: "1%",
          }}
          sortFn={(row: any) => [row.SYMBOL.replaceAll(row.SYMBOL_PREFIX, "")]}
          hasSearch
          searchKey="SYMBOL"
        />
      </Box>
      <Box>
        <BaseSelect
          data={filteredAnalyses}
          onChange={analysisSelected}
          formatDisplay={(row: any) =>
            t(`ANALYSIS_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)
          }
          selectKey="CODE"
          inputLabel={t("ANALYSIS_SELECT_INPUT_LABEL")}
          label={t("ANALYSIS_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
          defaultValue={first(filteredAnalyses)?.CODE}
        />
      </Box>
    </Stack>
  );
};

export default AnalysisChartFilters;
