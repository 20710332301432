export const enRankingGrid = {
  OTHER_RANKING_CRYPTO_GRID_HEADER: "Crypto Ranking",
  OTHER_RANKING_GRID_ICON: "Icon",
  OTHER_RANKING_GRID_CODE: "Symbol",
  OTHER_RANKING_GRID_NAME: "Name",
  OTHER_RANKING_GRID_AGE: "Age",
  OTHER_RANKING_GRID_MC_RANK: "Marketcap Rank",
  OTHER_RANKING_GRID_MC_RANK_1D: "Marketcap Rank (1D)",
  OTHER_RANKING_GRID_MC_RANK_7D: "Marketcap Rank (7D)",
  OTHER_RANKING_GRID_MC_RANK_30D: "Marketcap Rank (30D)",
  OTHER_RANKING_GRID_LQ_RANK: "Liquidity Rank",
  OTHER_RANKING_GRID_LQ_RANK_1D: "Liquidity Rank (1D)",
  OTHER_RANKING_GRID_LQ_RANK_7D: "Liquidity Rank (7D)",
  OTHER_RANKING_GRID_LQ_RANK_30D: "Liquidity Rank (30D)",
  OTHER_RANKING_GRID_ID: "Id",
};

export const elRankingGrid = {
  OTHER_RANKING_CRYPTO_GRID_HEADER: "Κατάταξη Κρυπτονομισμάτων",
  OTHER_RANKING_GRID_ICON: "Εικονίδιο",
  OTHER_RANKING_GRID_CODE: "Σύμβολο",
  OTHER_RANKING_GRID_NAME: "Όνομα",
  OTHER_RANKING_GRID_AGE: "Ηλικία",
  OTHER_RANKING_GRID_MC_RANK: "Κατάταξη Κεφαλαιοποίησης",
  OTHER_RANKING_GRID_MC_RANK_1D: "Κατάταξη Κεφαλαιοποίησης (1D)",
  OTHER_RANKING_GRID_MC_RANK_7D: "Κατάταξη Κεφαλαιοποίησης (7D)",
  OTHER_RANKING_GRID_MC_RANK_30D: "Κατάταξη Κεφαλαιοποίησης (30D)",
  OTHER_RANKING_GRID_LQ_RANK: "Κατάταξη Ρευστότητας",
  OTHER_RANKING_GRID_LQ_RANK_1D: "Κατάταξη Ρευστότητας (1D)",
  OTHER_RANKING_GRID_LQ_RANK_7D: "Κατάταξη Ρευστότητας (7D)",
  OTHER_RANKING_GRID_LQ_RANK_30D: "Κατάταξη Ρευστότητας (30D)",
  OTHER_RANKING_GRID_ID: "Αναγνωριστικό",
};
