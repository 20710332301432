import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { TAppState } from "../../../../store/reducers";
import { signalSelector } from "../../../selectors";
import { useGetActivePairsQuery } from "../../../../features/api";
import BaseBar from "../../../Common/BaseBar/BaseBar";
import {
  getPercent,
  getCountConfig,
  getSignalMatches,
} from "./SignalsCountConfig";

const SignalsCount: React.FC = () => {
  const {
    list: {
      filters: { signal },
      data,
    },
  } = useSelector((state: TAppState) => signalSelector(state));
  const { data: pairs, isLoading: pairsLoading } = useGetActivePairsQuery();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: "380px",
        margin: "auto",
        fontSize: "90%",
        background: "rgba(114, 108, 111, 0.24)",
        padding: "2%",
        borderRadius: "10%",
      }}
    >
      {
        <Box sx={{ display: "flex", fontWeight: "bold" }}>
          <Box sx={{ width: "130px" }}>{t("SIGNALS_COUNT_HEADER_SIGNAL")}</Box>
          <Box sx={{ width: "70px" }}>{t("SIGNALS_COUNT_HEADER_PAIRS")}</Box>
          <Box sx={{ width: "80px" }}>{t("SIGNALS_COUNT_HEADER_PERCENT")}</Box>
          <Box sx={{ width: "100px" }}>{t("SIGNALS_COUNT_HEADER_BAR")}</Box>
        </Box>
      }
      {signal !== null &&
        !pairsLoading &&
        getCountConfig(signal?.CODE).map((c, k) => (
          <Box sx={{ display: "flex" }} key={k}>
            <Box sx={{ width: "130px" }}>{t(c.text)}</Box>
            <Box sx={{ width: "70px" }}>
              {getSignalMatches({ key: c.key, value: c.value }, data) +
                " / " +
                pairs.length}
            </Box>
            <Box sx={{ width: "80px" }}>
              {getPercent(
                { key: c.key, value: c.value },
                data,
                pairs.length
              ).toFixed(2) + "%"}
            </Box>
            <Box sx={{ width: "100px" }}>
              <BaseBar
                color={c.color}
                value={getPercent(
                  { key: c.key, value: c.value },
                  data,
                  pairs.length
                )}
              />
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default SignalsCount;
