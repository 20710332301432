export const enLoginPage = {
  LOGIN_PAGE_HEADER: "Login",
  LOGIN_PAGE_BUTTON: "Login",
  LOGIN_EMAIL_LABEL: "Email Address",
};

export const elLoginPage = {
  LOGIN_PAGE_HEADER: "Σύνδεση",
  LOGIN_PAGE_BUTTON: "Σύνδεση",
  LOGIN_EMAIL_LABEL: "Διεύθυνση Email",
};
