import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TAppState } from "../../../../store/reducers";
import { AppDispatch } from "../../../../store/configureStore";
import ChartTopBar from "./TopBar/ChartsTopBar";
import ChartsFilterArea from "./ChartsFilterArea";
import PriceChart from "./../Common/Charts/PriceChart";
import RangeChart from "./../Common/Charts/RangeChart";
import NoRangeChart from "./../Common/Charts/NoRangeChart";
import HistogramChart from "./../Common/Charts/HistogramChart";
import { fetchSignalsByPair } from "../../../../store/actions/signal";
import { chartsSelector } from "../../../selectors";

const ChartsStoredTab: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    stored: {
      symbol,
      name,
      data,
      filters: { period, pair },
      view: { fullScreen, autoSize, indicators, patterns, signals },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));

  useEffect(() => {
    if (period && pair) {
      const periodId = period.ID;
      const pairId = pair.ID;
      dispatch(
        fetchSignalsByPair({
          periodId,
          pairId,
        })
      );
    }
  }, [dispatch, period, pair]);

  return (
    <div className="charts-stored-page">
      <ChartsFilterArea />
      <ChartTopBar />
      <PriceChart
        symbol={symbol}
        name={name}
        minMove={pair?.BASE_INCREMENT}
        indicators={indicators}
        patterns={patterns}
        signals={signals}
        renderEmpty
        data={data}
        fullScreen={fullScreen}
        autoSize={autoSize}
      />
      <RangeChart
        symbol={symbol}
        name={name}
        indicators={indicators}
        renderEmpty
        data={data}
      />
      <NoRangeChart
        symbol={symbol}
        name={name}
        indicators={indicators}
        renderEmpty
        data={data}
      />
      <HistogramChart
        symbol={symbol}
        name={name}
        indicators={indicators}
        renderEmpty
        data={data}
      />
    </div>
  );
};

export default ChartsStoredTab;
