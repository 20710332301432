import React from "react";
import { first } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import BaseSelect from "../../../Common/BaseSelect/BaseSelect";
import BaseDatePicker from "../../../Common/BaseDatePicker/BaseDatePicker";
import { AppDispatch } from "../../../../store/configureStore";
import { TAppState } from "../../../../store/reducers";
import {
  listSignalChanged,
  listPeriodChanged,
  listOpenedAtChanged,
} from "../../../../store/reducers/signal";
import { signalSelector } from "../../../selectors";
import {
  useGetActivePeriodsQuery,
  useGetActiveSignalsQuery,
} from "../../../../features/api";
import { getMaxDate, getDisabledDates } from "../../../../utilities/helpers";

const SignalsFilterArea: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { data: periods, isLoading: periodsLoading } =
    useGetActivePeriodsQuery();
  const { data: signals } = useGetActiveSignalsQuery();
  const {
    list: {
      filteredSignals,
      filters: { period, openedAt },
    },
  } = useSelector((state: TAppState) => signalSelector(state));

  const periodSelected = (periods: any) => {
    dispatch(listPeriodChanged({ period: first(periods), signals }));
  };
  const signalSelected = (signals: any) => {
    dispatch(listSignalChanged({ signal: first(signals) }));
  };
  const dateSelected = (date: any) => {
    dispatch(listOpenedAtChanged(date));
  };

  return (
    <Stack
      spacing={{ xs: 2, sm: 2 }}
      direction="row"
      useFlexGap
      flexWrap="wrap"
      marginBottom={5}
    >
      <Box>
        <BaseSelect
          data={periods}
          loading={periodsLoading}
          onChange={periodSelected}
          formatDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          selectKey="CODE"
          inputLabel={t("PERIOD_SELECT_INPUT_LABEL")}
          label={t("PERIOD_SELECT_LABEL")}
          minWidth={150}
          maxWidth={150}
          defaultValue={first(periods)?.CODE}
        />
      </Box>
      <Box>
        <BaseSelect
          data={filteredSignals}
          onChange={signalSelected}
          formatDisplay={(row: any) =>
            `${t(`SIGNAL_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)}`
          }
          selectKey="CODE"
          inputLabel={t("SIGNAL_SELECT_INPUT_LABEL")}
          label={t("SIGNAL_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
          defaultValue={first(filteredSignals)?.CODE}
        />
      </Box>
      <Box>
        {!periodsLoading && openedAt && period && (
          <BaseDatePicker
            label={t("OPENED_AT_DATE_PICKER_LABEL")}
            defaultValue={openedAt}
            maxDate={getMaxDate(period)}
            onChange={dateSelected}
            timeSteps={{ hours: 24, minutes: 60 }}
            shouldDisableDate={getDisabledDates(period)}
          />
        )}
      </Box>
    </Stack>
  );
};

export default SignalsFilterArea;
