import { createSlice } from "@reduxjs/toolkit";
import {
  AXIOS_STATUSES,
  TAppLanguage,
  TAppSettings,
  TAppTheme,
} from "../../types/entities";
import { fetchAppSettings } from "../actions/common";
import {
  getLanguageInStorage,
  getSettingsInStorage,
  getThemeInStorage,
  setLanguageInStorage,
  setSettingsInStorage,
  setThemeInStorage,
} from "../../utilities/helpers";

export interface TCommonState {
  settings: TAppSettings;
  theme: TAppTheme;
  language: TAppLanguage;
  http: {
    errorStatus: boolean;
    errorMessage: string;
  };
  status: string | null;
}

const initialState: TCommonState = {
  settings: getSettingsInStorage(),
  theme: getThemeInStorage(),
  language: getLanguageInStorage(),
  http: {
    errorStatus: false,
    errorMessage: "",
  },
  status: null,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    themeChanged: (state, action) => {
      setThemeInStorage(action.payload);
      state.theme = action.payload;
    },
    languageChanged: (state, action) => {
      setLanguageInStorage(action.payload);
      state.language = action.payload;
    },
    httpErrorChanged: (state, action) => {
      const { errorStatus = false, errorMessage = "" } = action.payload;
      state.http.errorStatus = errorStatus;
      state.http.errorMessage = errorMessage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppSettings.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchAppSettings.fulfilled, (state, action) => {
        state.status = AXIOS_STATUSES.IDLE;
        setSettingsInStorage(action.payload);
        state.settings = action.payload;
      })
      .addCase(fetchAppSettings.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      });
  },
});

export const { themeChanged, languageChanged, httpErrorChanged } =
  commonSlice.actions;
export default commonSlice.reducer;
