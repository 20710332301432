import { enCommon, elCommon } from "./Common/translations";
import { enPages, elPages } from "./Pages/translations";
import { enApp, elApp } from "./App/translations";

export const enComponent = {
  ...enCommon,
  ...enPages,
  ...enApp,
};

export const elComponent = {
  ...elCommon,
  ...elPages,
  ...elApp,
};
