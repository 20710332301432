import { createSlice } from "@reduxjs/toolkit";
import { fetchRanking, fetchFearAndGreed } from "../actions/other";
import { AXIOS_STATUSES } from "../../types/entities";

export interface TOtherState {
  ranking: {
    data: Array<any>;
  };
  fearAndGreed: {
    crypto: Array<any>;
  };
  status: string | null;
}

const initialState: TOtherState = {
  ranking: {
    data: [],
  },
  fearAndGreed: {
    crypto: [],
  },
  status: null,
};

const otherSlice = createSlice({
  name: "other",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRanking.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchRanking.fulfilled, (state, action) => {
        state.ranking.data = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchRanking.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchFearAndGreed.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchFearAndGreed.fulfilled, (state, action) => {
        state.fearAndGreed.crypto = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchFearAndGreed.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      });
  },
});

export default otherSlice.reducer;
