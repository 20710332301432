import { map, first } from "lodash";
import { ChartJsDataset } from "../../../../types/entities";
import i18n from "i18next";

export const getLegendLabel = (serie: any) => {
  const { serieExternalId, serieTitle } = serie;
  switch (serieExternalId) {
    case "EXPINF1YR":
      return i18n.t("ECONOMY_INFLATION_LEGEND_LABEL_1Y");
    case "EXPINF2YR":
      return i18n.t("ECONOMY_INFLATION_LEGEND_LABEL_2Y");
    case "EXPINF5YR":
      return i18n.t("ECONOMY_INFLATION_LEGEND_LABEL_5Y");
    default:
      return serieTitle;
  }
};

export const getLineConfig = (externalId: string) => {
  switch (externalId) {
    /* Crisis charts */
    case "STLFSI4":
      return {
        lineWidth: 1,
        lineColor: "#18A558",
        topColor: "rgba(24, 165, 88, 0.60)",
        bottomColor: "rgba(24, 165, 88, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "NFCI":
      return {
        lineWidth: 1,
        lineColor: "#F33A6A",
        topColor: "rgba(243, 58, 106, 0.60)",
        bottomColor: "rgba(243, 58, 106, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "RECPROUSM156N":
      return {
        lineWidth: 1,
        lineColor: "#007500",
        topColor: "rgba(0, 117, 0, 0.60)",
        bottomColor: "rgba(0, 117, 0, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "SAHMREALTIME":
      return {
        lineWidth: 1,
        lineColor: "#d5d96a",
        topColor: "rgba(213, 217, 106, 0.60)",
        bottomColor: "rgba(213, 217, 106, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "T10Y2YM":
      return {
        lineWidth: 1,
        lineColor: "#c80815",
        topColor: "rgba(200, 8, 21, 0.60)",
        bottomColor: "rgba(200, 8, 21, 0.30)",
        lastValueVisible: true,
      };
    case "T10Y3MM":
      return {
        lineWidth: 1,
        lineColor: "#c80815",
        topColor: "rgba(200, 8, 21, 0.60)",
        bottomColor: "rgba(200, 8, 21, 0.30)",
        lastValueVisible: true,
      };
    /* Economy charts */
    case "FYGDP":
      return {
        lineWidth: 1,
        lineColor: "#00e2ff",
        topColor: "rgba(0, 226, 255, 0.60)",
        bottomColor: "rgba(0, 226, 255, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "JTSJOL":
      return {
        lineWidth: 1,
        lineColor: "#FFB6C1",
        topColor: "rgba(255, 182, 193, 0.60)",
        bottomColor: "rgba(255, 182, 193, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "TOTDTEUSQ163N":
      return {
        lineWidth: 1,
        lineColor: "#ffa200",
        topColor: "rgba(255, 162, 0, 0.60)",
        bottomColor: "rgba(255, 162, 0, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "INDPRO":
      return {
        lineWidth: 1,
        lineColor: "#800080",
        topColor: "rgba(128, 0, 128, 0.60)",
        bottomColor: "rgba(128, 0, 128, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "DTWEXBGS":
      return {
        lineWidth: 1,
        lineColor: "#FFD100",
        topColor: "rgba(255, 209, 0, 0.60)",
        bottomColor: "rgba(255, 209, 0, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "IGREA":
      return {
        lineWidth: 1,
        lineColor: "#66FF00",
        topColor: "rgba(102, 255, 0, 0.60)",
        bottomColor: "rgba(102, 255, 0, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    /* Consumer charts */
    case "TOTALSL":
      return {
        lineWidth: 1,
        lineColor: "#ff0068",
        topColor: "rgba(255, 0, 104, 0.60)",
        bottomColor: "rgba(255, 0, 104, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "CCLACBW027SBOG":
      return {
        lineWidth: 1,
        lineColor: "#0072ff",
        topColor: "rgba(0, 114, 255, 0.60)",
        bottomColor: "rgba(0, 114, 255, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "PMSAVE":
      return {
        lineWidth: 1,
        lineColor: "#218d29",
        topColor: "rgba(33, 141, 41, 0.60)",
        bottomColor: "rgba(33, 141, 41, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "UMCSENT":
      return {
        lineWidth: 1,
        lineColor: "#ffff00",
        topColor: "rgba(255, 255, 0, 0.60)",
        bottomColor: "rgba(255, 255, 0, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    /* Other charts */
    case "EXPINF1YR":
      return {
        lineColor: "#C70018",
        topColor: "rgba(199, 0, 24, 0.60)",
        bottomColor: "rgba(199, 0, 24, 0.30)",
        lineWidth: 1,
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "EXPINF2YR":
      return {
        lineColor: "#00C770",
        topColor: "rgba(0, 199, 112, 0.60)",
        bottomColor: "rgba(0, 199, 112, 0.60)",
        lineWidth: 1,
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "EXPINF5YR":
      return {
        lineColor: "#6aa0d9",
        topColor: "rgba(106, 160, 217, 0.60)",
        bottomColor: "rgba(106, 160, 217, 0.30)",
        lineWidth: 1,
        lastValueVisible: true,
        priceLineVisible: false,
      };
    /* Default */
    default:
      return {
        color: "#21B6A8",
        topColor: "rgba(33, 182, 168, 0.60)",
        bottomColor: "rgba(33, 182, 168, 0.30)",
        lineWidth: 1,
        lastValueVisible: true,
        priceLineVisible: false,
      };
  }
};

export const hasRecession = (externalId: string) => {
  switch (externalId) {
    case "T10Y2YM":
    case "T10Y3MM":
      return true;
    default:
      return false;
  }
};

export const getHorizontalLineConfig = (externalId: string) => {
  switch (externalId) {
    default:
      return {
        color: "rgba(240, 234, 214, 0.8)",
        lineWidth: 1,
        lastValueVisible: true,
        priceLineVisible: false,
      };
  }
};

export const getYieldLabels = () => {
  return map(getYieldConfig(), "label");
};

export const getYieldConfig = () => {
  return [
    { key: "GS3M", label: i18n.t("ECONOMY_YIELDS_3M") },
    { key: "GS6M", label: i18n.t("ECONOMY_YIELDS_6M") },
    { key: "GS1", label: i18n.t("ECONOMY_YIELDS_1Y") },
    { key: "GS2", label: i18n.t("ECONOMY_YIELDS_2Y") },
    { key: "GS3", label: i18n.t("ECONOMY_YIELDS_3Y") },
    { key: "GS5", label: i18n.t("ECONOMY_YIELDS_5Y") },
    { key: "GS7", label: i18n.t("ECONOMY_YIELDS_7Y") },
    { key: "GS10", label: i18n.t("ECONOMY_YIELDS_10Y") },
    { key: "GS20", label: i18n.t("ECONOMY_YIELDS_20Y") },
    { key: "GS30", label: i18n.t("ECONOMY_YIELDS_30Y") },
  ];
};

export const getYieldDatasets = (yields: any): Array<ChartJsDataset> => {
  const data = getYieldConfig().map(
    (c) => first(yields.filter((y: any) => y.SERIE_EXT_ID === c.key)).VALUE
  );
  return [
    {
      data,
      background: "#c73c3c",
      borderColor: "#c73c3c",
      borderWidth: 1,
    },
  ];
};

export const getRecessionConfig = () => {
  return {
    position: "belowBar",
    color: "rgba(189, 195, 199, 0.7)",
    shape: "circle",
    text: "",
  };
};
