import { API } from "./settings";

export const analysisUrl = `${API.URL}/analyses`;
export const candlesUrl = `${API.URL}/candles`;
export const economyUrl = `${API.URL}/economy`;
export const fundamentalsUrl = `${API.URL}/fundamentals`;
export const periodsUrl = `${API.URL}/periods`;
export const signalsUrl = `${API.URL}/signals`;
export const userUrl = `${API.URL}/user`;
export const appUrl = `${API.URL}/app`;

const extraTimeout = [candlesUrl];

export const checkForExtendedTimeout = (url: string | undefined) =>
  url !== undefined && extraTimeout.filter((u) => url.startsWith(u)).length > 0;
