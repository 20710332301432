import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { first } from "lodash";
import { Box } from "@mui/material";
import { AppDispatch } from "../../../../store/configureStore";
import TradingChart from "../../../Common/TradingChart/TradingChart";
import { createChartCandles } from "../../../../utilities/helpers";
import { TAppState } from "../../../../store/reducers";
import { analysisSelector } from "../../../selectors";
import { getMarkersConfig } from "./AnalysisChartConfig";
import { getAnalysisMarkers } from "../../../Common/helpers";
import {
  chartPairChanged,
  chartPeriodChanged,
} from "../../../../store/reducers/analysis";
import {
  useGetActiveAnalysesQuery,
  useGetActivePairsQuery,
  useGetActivePeriodsQuery,
} from "../../../../features/api";
import { fetchAnalysisByPair } from "../../../../store/actions/analysis";

type Props = {
  fullScreen?: boolean;
  autoSize?: boolean;
};

const AnalysisChart: React.FC<Props> = ({
  fullScreen = false,
  autoSize = true,
}) => {
  const { data: periods, isLoading: periodsLoading } =
    useGetActivePeriodsQuery();
  const { data: pairs, isLoading: pairsLoading } = useGetActivePairsQuery();
  const { data: analyses, isLoading: analysesLoading } =
    useGetActiveAnalysesQuery();
  const dispatch = useDispatch<AppDispatch>();
  const {
    chart: {
      symbol,
      name,
      data,
      filters: { pair, analysis, period },
      activeMetrics,
    },
  } = useSelector((state: TAppState) => analysisSelector(state));

  const markerConfig = getMarkersConfig(analysis?.CODE);

  useEffect(() => {
    if (!periodsLoading && !analysesLoading) {
      const period = first(periods);
      dispatch(chartPeriodChanged({ period, analyses }));
    }
  }, [dispatch, periods, periodsLoading, analyses, analysesLoading]);

  useEffect(() => {
    if (!pairsLoading) {
      const pair = first(pairs);
      dispatch(chartPairChanged({ pair }));
    }
  }, [dispatch, pairs, pairsLoading, period]);

  useEffect(() => {
    if (analysis && period && pair) {
      const analysisId = analysis.ID;
      const periodId = period.ID;
      const pairId = pair.ID;
      dispatch(fetchAnalysisByPair({ analysisId, periodId, pairId }));
    }
  }, [dispatch, analysis, period, pair]);

  return (
    <Box sx={{ width: "100%" }}>
      <TradingChart
        height={400}
        symbol={symbol}
        name={name}
        minMove={pair?.BASE_INCREMENT}
        candleData={createChartCandles(data)}
        markers={getAnalysisMarkers(data, markerConfig, activeMetrics)}
        fullScreen={fullScreen}
        autoSize={autoSize}
      />
    </Box>
  );
};

export default AnalysisChart;
