import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, IconButton, MenuItem, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import { ROUTES } from "../../../constants/routes";

type Props = {
  isMobile: boolean;
};

const MainMenu: React.FC<Props> = ({ isMobile }) => {
  const { t } = useTranslation();

  const menuItems = [
    { route: ROUTES.HOME, translation: "MAIN_MENU_ITEM_HOME" },
    { route: ROUTES.SIGNALS, translation: "MAIN_MENU_ITEM_SIGNALS" },
    { route: ROUTES.ANALYSIS, translation: "MAIN_MENU_ITEM_ANALYSIS" },
    { route: ROUTES.CHARTS, translation: "MAIN_MENU_ITEM_CHARTS" },
    { route: ROUTES.ECONOMY, translation: "MAIN_MENU_ITEM_ECONOMY" },
    { route: ROUTES.OTHER, translation: "MAIN_MENU_ITEM_OTHER" },
  ];

  const [anchorElMain, setAnchorElMain] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenMenu = (event: any) => {
    setAnchorElMain(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMain(null);
  };

  return isMobile ? (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorElMain}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(anchorElMain)}
        onClose={handleCloseMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {menuItems.map((item, key) => (
          <MenuItem key={`mi_${key}`}>
            <Link
              reloadDocument
              key={`l_${key}`}
              to={item.route}
              onClick={handleCloseMenu}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Typography textAlign="center">{t(item.translation)}</Typography>
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  ) : (
    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
      {menuItems.map((item, key) => (
        <MenuItem key={`mi_${key}`}>
          <Link
            reloadDocument
            key={`l_${key}`}
            to={item.route}
            onClick={handleCloseMenu}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Typography textAlign="center">{t(item.translation)}</Typography>
          </Link>
        </MenuItem>
      ))}
    </Box>
  );
};

export default MainMenu;
