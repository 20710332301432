import React from "react";
import { useTranslation } from "react-i18next";
import { TextField, InputAdornment } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

type Props = {
  email: string;
  required?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  width?: string | number;
  onChange: (email: any) => void;
  onKeyDown?: (key: any) => void;
};

const BaseEmail: React.FC<Props> = ({
  email = "",
  required = true,
  autoComplete = "off",
  autoFocus = true,
  width = 380,
  onChange,
  onKeyDown = (key: any) => key,
}) => {
  const { t } = useTranslation();

  return (
    <TextField
      style={{ width }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <EmailIcon />
          </InputAdornment>
        ),
      }}
      margin="normal"
      required={required}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      //fullWidth
      id="email"
      name="email"
      label={t("BASE_FIELD_EMAIL_LABEL")}
      type="text"
      value={email}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      onKeyDown={(e) => {
        onKeyDown(e.key);
      }}
    />
  );
};

export default BaseEmail;
