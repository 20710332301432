import { FC } from "react";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "./styles/main.sass";
import { light, dark } from "./theme";
import { store } from "./store/configureStore";
import Header from "./components/App/Header/Header";
import Footer from "./components/App/Footer/Footer";
import { getThemeInStorage } from "./utilities/helpers";
import CustomRouter from "./components/App/CustomRouter/CustomRouter";

const App: FC = () => {
  /*
   * Cannot use a reducer here, only inside <Provider/> context
   * Therefore getting theme directly from local storage
   */
  const theme = getThemeInStorage();

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ThemeProvider theme={theme === "light" ? light : dark}>
        <CssBaseline />
        <Provider store={store}>
          <CustomRouter
            headerChild={[<Header key={1} />]}
            footerChild={[<Footer key={2} />]}
          />
        </Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
