import React from "react";
import "../../../styles/Pages/Analysis/analysis.sass";
import AnalysisGridFilters from "./Grid/AnalysisGridFilters";
import AnalysisGrid from "./Grid/AnalysisGrid";
import AnalysisFaq from "./Faq/AnalysisFaq";
import AnalysisChart from "./Chart/AnalysisChart";
import AnalysisChartFilters from "./Chart/AnalysisChartFilters";
import AnalysisChartLegends from "./Chart/AnalysisChartLegends";

const AnalysisPage: React.FC = () => {
  return (
    <div className="analysis-page">
      <AnalysisGridFilters />
      <AnalysisGrid />
      <AnalysisChartFilters />
      <AnalysisChartLegends />
      <AnalysisChart />
      <AnalysisFaq />
    </div>
  );
};

export default AnalysisPage;
