import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import FaqAccordion from "../../../Common/FaqAccordion/FaqAccordion";
import { getFaqConfig } from "../Chart/AnalysisChartConfig";

const AnalysisFaq: React.FC = () => {
  const { t } = useTranslation();
  const faqConfig = getFaqConfig();

  return (
    <Box sx={{ padding: "10%", width: "100%" }}>
      {faqConfig?.map((c, k) => (
        <div key={k} style={{ paddingBottom: 20 }}>
          <FaqAccordion title={t(c.title)} description={t(c.description)} />
        </div>
      ))}
    </Box>
  );
};

export default AnalysisFaq;
