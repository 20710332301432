import React from "react";
import SignalsGridFilters from "./Grid/SignalsGridFilters";
import SignalsGridLegend from "./Grid/SignalsGridLegend";
import SignalsGrid from "./Grid/SignalsGrid";
import SignalsFaq from "./Faq/SignalsFaq";
import SignalsCount from "./Count/SignalsCount";
import "../../../styles/Pages/Signals/signals.sass";

const SignalsPage: React.FC = () => {
  return (
    <div className="signals-page">
      <SignalsGridFilters />
      <SignalsCount />
      <SignalsGridLegend />
      <SignalsGrid />
      <SignalsFaq />
    </div>
  );
};

export default SignalsPage;
