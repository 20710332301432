import { GridCellParams } from "@mui/x-data-grid";
import clsx from "clsx";
import i18n from "i18next";

const mapPositiveNegative = (p: any) => {
  const { value } = p;
  switch (value) {
    case 1:
      return i18n.t(`ANALYSIS_GRID_VALUE_POSITIVE`);
    case 2:
      return i18n.t(`ANALYSIS_GRID_VALUE_NEGATIVE`);
    default:
      return `-`;
  }
};

const mapToppingBottoming = (p: any) => {
  const { value } = p;
  switch (value) {
    case 1:
      return i18n.t(`ANALYSIS_GRID_VALUE_TOPPING`);
    case 2:
      return i18n.t(`ANALYSIS_GRID_VALUE_BOTTOMING`);
    default:
      return `-`;
  }
};

export const getVisibility = () => {
  return {
    ID: false,
    PR_NAME: false,
    TYPE_NAME: false,
    OPENED_AT: false,
    CLOSED_AT: false,
    OPEN: false,
    CLOSE: false,
    HIGH: false,
    LOW: false,
    SYMBOL_PREFIX: false,
    RSIDIV_ST_14: false,
    RSIDIV_ST_21: false,
  };
};

export const getCommonColumns = () => {
  return [
    {
      field: `ID`,
      headerName: i18n.t(`ANALYSIS_GRID_ID`),
      width: 100,
    },
    {
      field: `PR_NAME`,
      headerName: i18n.t(`ANALYSIS_GRID_PR_NAME`),
      width: 120,
    },
    {
      field: `TYPE_NAME`,
      headerName: i18n.t(`ANALYSIS_GRID_TYPE_NAME`),
      width: 120,
    },
    {
      field: `OPENED_AT`,
      headerName: i18n.t(`CANDLE_OPENED_AT`),
      width: 160,
    },
    {
      field: `CLOSED_AT`,
      headerName: i18n.t(`CANDLE_CLOSED_AT`),
      width: 160,
    },
    {
      field: `OPEN`,
      headerName: i18n.t(`CANDLE_OPEN`),
      width: 90,
    },
    {
      field: `CLOSE`,
      headerName: i18n.t(`CANDLE_CLOSE`),
      width: 90,
    },
    {
      field: `HIGH`,
      headerName: i18n.t(`CANDLE_HIGH`),
      width: 90,
    },
    {
      field: `LOW`,
      headerName: i18n.t(`CANDLE_LOW`),
      width: 90,
    },
    {
      field: `SYMBOL_PREFIX`,
      headerName: i18n.t(`ANALYSIS_GRID_SYMBOL_PREFIX`),
      width: 40,
    },
  ];
};

const getColumns = (code: string | undefined) => {
  const common = getCommonColumns();
  switch (code) {
    case "volatility":
      return [
        {
          field: `SYMBOL`,
          headerName: i18n.t(`ANALYSIS_GRID_SYMBOL`),
          width: 110,
          valueGetter: (p) => p.row.SYMBOL.replaceAll(p.row.SYMBOL_PREFIX, ""),
        },
        {
          field: `BB_TIGHT`,
          headerName: i18n.t(`ANALYSIS_GRID_BB_TIGHT`),
          width: 115,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  highlight: params.value >= 5,
                }),
        },
        {
          field: `BB_LOOSE`,
          headerName: i18n.t(`ANALYSIS_GRID_BB_LOOSE`),
          width: 120,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  highlight: params.value >= 5,
                }),
        },
        {
          field: `BB_WIDTH`,
          headerName: i18n.t(`ANALYSIS_GRID_BB_WIDTH`),
          width: 145,
        },
        {
          field: `SMA50_D`,
          headerName: i18n.t(`ANALYSIS_GRID_SMA50_D`),
          width: 150,
          valueGetter: (p) =>
            p.row.SMA50_D ? Number(p.row.SMA50_D.toFixed(2)) : p.row.SMA50_D,
        },
        {
          field: `SMA200_D`,
          headerName: i18n.t(`ANALYSIS_GRID_SMA200_D`),
          width: 155,
          valueGetter: (p) =>
            p.row.SMA200_D ? Number(p.row.SMA200_D.toFixed(2)) : null,
        },
        {
          field: `ATH_D`,
          headerName: i18n.t(`ANALYSIS_GRID_ATH_D`),
          width: 120,
          valueGetter: (p) =>
            p.row.ATH_D ? Number(p.row.ATH_D.toFixed(2)) : null,
        },
        {
          field: `ATL_D`,
          headerName: i18n.t(`ANALYSIS_GRID_ATL_D`),
          width: 120,
          valueGetter: (p) =>
            p.row.ATL_D ? Number(p.row.ATL_D.toFixed(2)) : null,
        },
        {
          field: `FLAG_CL`,
          headerName: i18n.t(`ANALYSIS_GRID_FLAG_CL`),
          width: 150,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  highlight: params.value >= 5,
                }),
        },
        {
          field: `PRICE_UP`,
          headerName: i18n.t(`ANALYSIS_GRID_PRICE_UP`),
          width: 115,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  sell: params.value >= 5,
                }),
        },
        {
          field: `PRICE_DOWN`,
          headerName: i18n.t(`ANALYSIS_GRID_PRICE_DOWN`),
          width: 135,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  buy: params.value >= 5,
                }),
        },
        {
          field: `GREEN`,
          headerName: i18n.t(`ANALYSIS_GRID_GREEN`),
          width: 105,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  sell: params.value >= 7,
                }),
        },
        {
          field: `RED`,
          headerName: i18n.t(`ANALYSIS_GRID_RED`),
          width: 90,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  buy: params.value >= 7,
                }),
        },
        {
          field: `VIX_UP`,
          headerName: i18n.t(`ANALYSIS_GRID_VIX_UP`),
          width: 105,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  sell: params.value >= 7,
                }),
        },
        {
          field: `VIX_DOWN`,
          headerName: i18n.t(`ANALYSIS_GRID_VIX_DOWN`),
          width: 125,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  buy: params.value >= 7,
                }),
        },
        {
          field: `VIX_22`,
          headerName: i18n.t(`ANALYSIS_GRID_VIX_22`),
          width: 115,
        },
        ...common,
      ];
    case "movement":
      return [
        {
          field: `SYMBOL`,
          headerName: i18n.t(`ANALYSIS_GRID_SYMBOL`),
          width: 110,
          valueGetter: (p) => p.row.SYMBOL.replaceAll(p.row.SYMBOL_PREFIX, ""),
        },
        {
          field: `RSIDIV_14`,
          headerName: i18n.t(`ANALYSIS_GRID_RSIDIV_14`),
          width: 180,
          valueGetter: (p) => mapPositiveNegative(p),
          cellClassName: (params: GridCellParams<any, string>) =>
            params.value === "-"
              ? ""
              : clsx(`analysis-grid`, {
                  buy: params.value === "Positive",
                  sell: params.value === "Negative",
                }),
        },
        {
          field: `RSIDIV_21`,
          headerName: i18n.t(`ANALYSIS_GRID_RSIDIV_21`),
          width: 180,
          valueGetter: (p) => mapPositiveNegative(p),
          cellClassName: (params: GridCellParams<any, string>) =>
            params.value === "-"
              ? ""
              : clsx(`analysis-grid`, {
                  buy: params.value === "Positive",
                  sell: params.value === "Negative",
                }),
        },
        {
          field: `MFIDIV_7`,
          headerName: i18n.t(`ANALYSIS_GRID_MFIDIV_7`),
          width: 180,
          valueGetter: (p) => mapPositiveNegative(p),
          cellClassName: (params: GridCellParams<any, string>) =>
            params.value === "-"
              ? ""
              : clsx(`analysis-grid`, {
                  buy: params.value === "Positive",
                  sell: params.value === "Negative",
                }),
        },
        {
          field: `MFIDIV_14`,
          headerName: i18n.t(`ANALYSIS_GRID_MFIDIV_14`),
          width: 180,
          valueGetter: (p) => mapPositiveNegative(p),
          cellClassName: (params: GridCellParams<any, string>) =>
            params.value === "-"
              ? ""
              : clsx(`analysis-grid`, {
                  buy: params.value === "Positive",
                  sell: params.value === "Negative",
                }),
        },
        {
          field: `TAIL`,
          headerName: i18n.t(`ANALYSIS_GRID_TAIL`),
          width: 135,
          valueGetter: (p) => mapToppingBottoming(p),
          cellClassName: (params: GridCellParams<any, string>) =>
            params.value === "-"
              ? ""
              : clsx(`analysis-grid`, {
                  buy: params.value === "Bottoming",
                  sell: params.value === "Topping",
                }),
        },
        ...common,
      ];
    default:
      return [];
  }
};

export const gridKey = "ID";
export default getColumns;
