import { enRankingGrid, elRankingGrid } from "./Grid/translations";
import { enRankingFaq, elRankingFaq } from "./Faq/translations";

export const enRankingTab = {
  ...enRankingGrid,
  ...enRankingFaq,
};

export const elRankingTab = {
  ...elRankingGrid,
  ...elRankingFaq,
};
