import React, { Ref } from "react";

const TitleLegend = React.forwardRef(
  (props: { title: string }, ref: Ref<{}>) => {
    const { title } = props;

    return (
      <div>
        <div>{title}</div>
      </div>
    );
  }
);

export default TitleLegend;
