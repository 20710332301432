import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { TAppState } from "../../../../../store/reducers";
import { backtestingSelector } from "../../../../selectors";
import { parseSymbol, parseSignalNames } from "../helpers";

const BacktestingTopBarLeft: React.FC = () => {
  const { json } = useSelector((state: TAppState) =>
    backtestingSelector(state)
  );

  const { candles, signals } = json;
  const pairSymbol = parseSymbol(candles);
  const signalNames = parseSignalNames(signals);

  return (
    <Box
      sx={{
        textAlign: "left",
        width: "50%",
        fontSize: "80%",
        paddingLeft: "1%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div>
          <div
            style={{
              fontWeight: "bold",
            }}
          >
            {pairSymbol}
          </div>
          <div>{signalNames.join(" / ")}</div>
        </div>
      </div>
    </Box>
  );
};

export default BacktestingTopBarLeft;
