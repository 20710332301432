import i18n from "i18next";
import { DateTime } from "luxon";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import LineChart from "../../../../Common/LineChart/LineChart";
import { AppDispatch } from "../../../../../store/configureStore";
import { economySelector } from "../../../../selectors";
import { TAppState } from "../../../../../store/reducers";
import { fetchEconomyGroupData } from "../../../../../store/actions/economy";
import { useGetEconomyGroupsQuery } from "../../../../../features/api";
import { getYieldLabels, getYieldDatasets } from "../ChartLines";

const YieldChart: React.FC = () => {
  const { data: groups, isLoading } = useGetEconomyGroupsQuery();
  const dispatch = useDispatch<AppDispatch>();
  const {
    yields: { data = [] },
  } = useSelector((state: TAppState) => economySelector(state));
  const frequency = useMemo(
    () => groups?.yields?.frequency || "M",
    [groups?.yields?.frequency]
  );

  useEffect(() => {
    if (!isLoading) {
      dispatch(
        fetchEconomyGroupData({
          date: DateTime.now()
            .setZone("America/New_York")
            .minus({ days: 1 })
            .startOf("month")
            .minus({ months: 1 })
            .toFormat("yyyy-MM-dd"),
          group: "yields",
          frequency,
        })
      );
    }
  }, [dispatch, groups, frequency, isLoading]);
  return (
    <Box sx={{ width: "100%", paddingBottom: "4%" }}>
      {isLoading || data.length === 0 ? (
        <></>
      ) : (
        <div>
          <LineChart
            labels={getYieldLabels()}
            datasets={getYieldDatasets(data)}
            height={300}
            title={i18n.t(`ECONOMY_YIELD_CHART_TITLE`) || undefined}
            pointRadius={3}
          />
          <div style={{ fontSize: "80%", paddingTop: "2%" }}>
            {i18n.t(`ECONOMY_YIELD_CHART_DESCRIPTION`)}
          </div>
        </div>
      )}
    </Box>
  );
};

export default YieldChart;
