import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const DateDisplay: React.FC = () => {
  const [localDate, setLocalDate] = useState("");
  const [utcDate, setUtcDate] = useState("");
  const [newYorkDate, setNewYorkDate] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      setLocalDate(DateTime.local().toFormat("DDDD TT"));
      setUtcDate(DateTime.utc().toFormat("DDDD TT"));
      setNewYorkDate(
        DateTime.now().setZone("America/New_York").toFormat("DDDD TT")
      );
    }, 200);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, color: "white" }}>
          {t("LOCAL_DATETIME_LABEL")}:{" "}
        </div>
        <div style={{ flex: 4, color: "orange" }}>{localDate}</div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, color: "white" }}>
          {t("UTC_DATETIME_LABEL")}:{" "}
        </div>
        <div style={{ flex: 4, color: "#CF9FFF" }}>{utcDate}</div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, color: "white" }}>
          {t("NEW_YORK_DATETIME_LABEL")}:{" "}
        </div>
        <div style={{ flex: 4, color: "#32CD32" }}>{newYorkDate}</div>
      </div>
    </div>
  );
};

export default DateDisplay;
