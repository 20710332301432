import React from "react";
import { useSelector } from "react-redux";
import { TAppState } from "../../../../store/reducers";
import { backtestingSelector } from "../../../selectors";
import BacktestingTopBar from "./TopBar/BacktestingTopBar";
import BacktestingChart from "./BacktestingChart";

const BacktestingTab: React.FC = () => {
  const { fullScreen, autoSize } = useSelector((state: TAppState) =>
    backtestingSelector(state)
  );

  return (
    <div className="backtesting-page">
      <BacktestingTopBar />
      <BacktestingChart fullScreen={fullScreen} autoSize={autoSize} />
    </div>
  );
};

export default BacktestingTab;
