import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../styles/Pages/NotFound/notFound.sass";
import { ROUTES } from "../../../constants/routes";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const redirectToHomePage = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <div className="not-found-page">
      <h1 style={{ fontSize: "4em" }}> {t("NOT_FOUND_HEADER_TITLE")}</h1>
      <span style={{ cursor: "pointer" }} onClick={() => redirectToHomePage()}>
        {t("NOT_FOUND_HOME_PAGE_NAME")}
      </span>
    </div>
  );
};

export default NotFoundPage;
