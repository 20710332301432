import { enLoginPage, elLoginPage } from "./Login/translations";
import { enNotFoundPage, elNotFoundPage } from "./NotFound/translations";
import { enSignalsPage, elSignalsPage } from "./Signals/translations";
import { enAnalysisPage, elAnalysisPage } from "./Analysis/translations";
import { enChartsPage, elChartsPage } from "./Charts/translations";
import { enEconomyPage, elEconomyPage } from "./Economy/translations";
import { enOtherPage, elOtherPage } from "./Other/translations";

export const enPages = {
  PERIOD_SELECT_INPUT_LABEL: "Period",
  PERIOD_SELECT_LABEL: "Period",
  PERIOD_SELECT_DISPLAY_LABEL_1D: "Daily",
  PERIOD_SELECT_DISPLAY_LABEL_1W: "Weekly",
  ANALYSIS_SELECT_DISPLAY_LABEL_MOVEMENT: "Movement",
  ANALYSIS_SELECT_DISPLAY_LABEL_VOLATILITY: "Volatility",
  SIGNAL_SELECT_DISPLAY_LABEL_CROSS: "Cross Signal",
  SIGNAL_SELECT_DISPLAY_LABEL_MACD4CDIV: "Macd Divergence Signal",
  SIGNAL_SELECT_DISPLAY_LABEL_ESCAN: "Enhanced Scan Signal",
  SIGNAL_SELECT_DISPLAY_LABEL_USCAN: "Ultra Scan Signal",
  INDICATOR_SELECT_DISPLAY_LABEL_RSI: "Relative Strength Index",
  INDICATOR_SELECT_DISPLAY_LABEL_BOLL: "Bollinger Bands",
  INDICATOR_SELECT_DISPLAY_LABEL_STOCH: "Stochastic",
  INDICATOR_SELECT_DISPLAY_LABEL_LOWESTLOW: "Lowest Low",
  INDICATOR_SELECT_DISPLAY_LABEL_HIGHESTHIGH: "Highest High",
  INDICATOR_SELECT_DISPLAY_LABEL_SMA: "Simple Moving Average",
  INDICATOR_SELECT_DISPLAY_LABEL_ADX: "Average Directional Index",
  INDICATOR_SELECT_DISPLAY_LABEL_CCI: "Commodity Channel Index",
  INDICATOR_SELECT_DISPLAY_LABEL_KELTNER: "Keltner Channel Index",
  INDICATOR_SELECT_DISPLAY_LABEL_DONCHIAN: "Donchian Channel Index",
  INDICATOR_SELECT_DISPLAY_LABEL_AROON: "Aroon Channel Index",
  INDICATOR_SELECT_DISPLAY_LABEL_VIX: "Volatility Index",
  INDICATOR_SELECT_DISPLAY_LABEL_MACD4C:
    "Moving Average Convergence/Divergence 4C",
  INDICATOR_SELECT_DISPLAY_LABEL_FRACTAL: "William Fractals",
  INDICATOR_SELECT_DISPLAY_LABEL_MFI: "Money Flow Index",
  PATTERN_SELECT_DISPLAY_LABEL_FRACTAL_TREND: "Fractal Trend",
  PATTERN_SELECT_DISPLAY_LABEL_FRACTAL_PRICE_TREND: "Fractal Price Trend",
  PAIR_SELECT_INPUT_LABEL: "Pair",
  PAIR_SELECT_LABEL: "Pair",
  PROVIDER_SELECT_INPUT_LABEL: "Provider",
  PROVIDER_SELECT_LABEL: "Provider",
  INDICATOR_SELECT_INPUT_LABEL: "Indicator",
  INDICATOR_SELECT_LABEL: "Indicator",
  PATTERN_SELECT_INPUT_LABEL: "Pattern",
  PATTERN_SELECT_LABEL: "Pattern",
  SIGNAL_SELECT_INPUT_LABEL: "Signal",
  SIGNAL_SELECT_LABEL: "Signal",
  ANALYSIS_SELECT_INPUT_LABEL: "Analysis",
  ANALYSIS_SELECT_LABEL: "Analysis",
  OPENED_AT_DATE_PICKER_LABEL: "Opened At",
  CANDLE_OPENED_AT: "Opened At",
  CANDLE_CLOSED_AT: "Closed At",
  CANDLE_OPEN: "Open",
  CANDLE_CLOSE: "Close",
  CANDLE_HIGH: "High",
  CANDLE_LOW: "Low",
  ...enLoginPage,
  ...enNotFoundPage,
  ...enSignalsPage,
  ...enAnalysisPage,
  ...enChartsPage,
  ...enEconomyPage,
  ...enOtherPage,
};

export const elPages = {
  PERIOD_SELECT_INPUT_LABEL: "Περίοδος",
  PERIOD_SELECT_LABEL: "Περίοδος",
  PERIOD_SELECT_DISPLAY_LABEL_1D: "Ημερήσια",
  PERIOD_SELECT_DISPLAY_LABEL_1W: "Εβδομαδιαία",
  ANALYSIS_SELECT_DISPLAY_LABEL_MOVEMENT: "Movement",
  ANALYSIS_SELECT_DISPLAY_LABEL_VOLATILITY: "Volatility",
  SIGNAL_SELECT_DISPLAY_LABEL_CROSS: "Σήμα Cross",
  SIGNAL_SELECT_DISPLAY_LABEL_MACD4CDIV: "Σήμα Macd Divergence",
  SIGNAL_SELECT_DISPLAY_LABEL_ESCAN: "Σήμα Enhanced Scan",
  SIGNAL_SELECT_DISPLAY_LABEL_USCAN: "Σήμα Ultra Scan",
  INDICATOR_SELECT_DISPLAY_LABEL_RSI: "Relative Strength Index",
  INDICATOR_SELECT_DISPLAY_LABEL_BOLL: "Bollinger Bands",
  INDICATOR_SELECT_DISPLAY_LABEL_STOCH: "Stochastic",
  INDICATOR_SELECT_DISPLAY_LABEL_LOWESTLOW: "Lowest Low",
  INDICATOR_SELECT_DISPLAY_LABEL_HIGHESTHIGH: "Highest High",
  INDICATOR_SELECT_DISPLAY_LABEL_SMA: "Simple Moving Average",
  INDICATOR_SELECT_DISPLAY_LABEL_ADX: "Average Directional Index",
  INDICATOR_SELECT_DISPLAY_LABEL_CCI: "Commodity Channel Index",
  INDICATOR_SELECT_DISPLAY_LABEL_KELTNER: "Keltner Channel Index",
  INDICATOR_SELECT_DISPLAY_LABEL_DONCHIAN: "Donchian Channel Index",
  INDICATOR_SELECT_DISPLAY_LABEL_AROON: "Aroon Channel Index",
  INDICATOR_SELECT_DISPLAY_LABEL_VIX: "Volatility Index",
  INDICATOR_SELECT_DISPLAY_LABEL_MACD4C:
    "Moving Average Convergence/Divergence 4C",
  INDICATOR_SELECT_DISPLAY_LABEL_FRACTAL: "William Fractals",
  INDICATOR_SELECT_DISPLAY_LABEL_MFI: "Money Flow Index",
  PATTERN_SELECT_DISPLAY_LABEL_FRACTAL_TREND: "Fractal Trend",
  PATTERN_SELECT_DISPLAY_LABEL_FRACTAL_PRICE_TREND: "Fractal Price Trend",
  PAIR_SELECT_INPUT_LABEL: "Ζεύγος",
  PAIR_SELECT_LABEL: "Ζεύγος",
  PROVIDER_SELECT_INPUT_LABEL: "Πάροχος",
  PROVIDER_SELECT_LABEL: "Πάροχος",
  INDICATOR_SELECT_INPUT_LABEL: "Δείκτης",
  INDICATOR_SELECT_LABEL: "Δείκτης",
  PATTERN_SELECT_INPUT_LABEL: "Μοτίβο",
  PATTERN_SELECT_LABEL: "Μοτίβο",
  SIGNAL_SELECT_INPUT_LABEL: "Σήμα",
  SIGNAL_SELECT_LABEL: "Σήμα",
  ANALYSIS_SELECT_INPUT_LABEL: "Ανάλυση",
  ANALYSIS_SELECT_LABEL: "Ανάλυση",
  OPENED_AT_DATE_PICKER_LABEL: "Άνοιξε",
  CANDLE_OPENED_AT: "Άνοιξε",
  CANDLE_CLOSED_AT: "Έκλεισε",
  CANDLE_OPEN: "Άνοιγμα",
  CANDLE_CLOSE: "Κλείσιμο",
  CANDLE_HIGH: "Υψηλό",
  CANDLE_LOW: "Χαμηλό",
  ...elLoginPage,
  ...elNotFoundPage,
  ...elSignalsPage,
  ...elAnalysisPage,
  ...elChartsPage,
  ...elEconomyPage,
  ...elOtherPage,
};
