import {
  ColorType,
  Background,
  CandlestickStyleOptions,
  CrosshairLineOptions,
  CrosshairMode,
  CrosshairOptions,
  DeepPartial,
  GridLineOptions,
  GridOptions,
  LineStyle,
  LineWidth,
  PriceScaleMargins,
  PriceScaleMode,
  PriceScaleOptions,
  SeriesOptionsCommon,
  LayoutOptions,
  WatermarkOptions,
  HorzAlign,
  VertAlign,
  OverlayPriceScaleOptions,
  TimeScaleOptions,
  TickMarkFormatter,
  LocalizationOptions,
  PriceFormatterFn,
  TimeFormatterFn,
  HandleScrollOptions,
  HandleScaleOptions,
  KineticScrollOptions,
  TrackingModeExitMode,
  TrackingModeOptions,
} from "lightweight-charts";

export const getWatermark: (
  text: string,
  fontSize?: number,
  fontStyle?: string,
  fontFamily?: string,
  horzAlign?: HorzAlign,
  vertAlign?: VertAlign,
  color?: string,
  visible?: boolean
) => WatermarkOptions = (
  text,
  fontSize = 48,
  fontStyle = "",
  fontFamily = `"Calibri", "Helvetica", "Arial", sans-serif`,
  horzAlign = "right",
  vertAlign = "top",
  color = "rgba(31, 214, 254, 0.1)",
  visible = true
) => ({
  text,
  fontSize,
  fontStyle,
  fontFamily,
  visible,
  horzAlign,
  vertAlign,
  color,
});

export const getSolidBg: (color: string) => Background = (color) => ({
  type: ColorType.Solid,
  color,
});

export const getGradientBg: (
  topColor: string,
  bottomColor: string
) => Background = (topColor, bottomColor) => ({
  type: ColorType.VerticalGradient,
  topColor,
  bottomColor,
});

export const getLayout: (
  textColor?: string,
  bgColor?: string,
  fontSize?: number,
  fontFamily?: string,
  bgTopColor?: string,
  bgBottomColor?: string,
  bgColorType?: ColorType
) => LayoutOptions = (
  textColor = "rgba(255, 255, 255, 1)",
  bgColor = "#253248",
  fontSize = 11,
  fontFamily = `"Calibri", "Helvetica", "Arial", sans-serif`,
  bgTopColor = "1FD6FE",
  bgBottomColor = "#253248",
  bgColorType = ColorType.Solid
) => ({
  background:
    bgColorType === ColorType.Solid
      ? getSolidBg(bgColor)
      : getGradientBg(bgTopColor, bgBottomColor),
  textColor,
  fontSize,
  fontFamily,
});

export const getGrid: (
  vertLines?: GridLineOptions,
  horzLines?: GridLineOptions
) => GridOptions = (vertLines = getGridLine(), horzLines = getGridLine()) => ({
  vertLines,
  horzLines,
});

export const getGridLine: (
  color?: string,
  style?: LineStyle,
  visible?: boolean
) => GridLineOptions = (
  color = "#D6DCDE",
  style = LineStyle.Solid,
  visible = true
) => ({
  color,
  style,
  visible,
});

export const getCrosshair: (
  mode?: CrosshairMode,
  vertLine?: CrosshairLineOptions,
  horzLine?: CrosshairLineOptions
) => CrosshairOptions = (
  mode = CrosshairMode.Normal,
  vertLine = getCrosshairLine(),
  horzLine = getCrosshairLine()
) => ({
  mode,
  vertLine,
  horzLine,
});

export const getCrosshairLine: (
  color?: string,
  width?: LineWidth,
  style?: LineStyle,
  visible?: boolean,
  labelVisible?: boolean,
  labelBackgroundColor?: string
) => CrosshairLineOptions = (
  color = "#758696",
  width = 1,
  style = LineStyle.LargeDashed,
  visible = true,
  labelVisible = true,
  labelBackgroundColor = "#4c525e"
) => ({
  color,
  width,
  style,
  visible,
  labelVisible,
  labelBackgroundColor,
});

export const getTimeScale: (
  rightOffset?: number,
  barSpacing?: number,
  minBarSpacing?: number,
  fixLeftEdge?: boolean,
  fixRightEdge?: boolean,
  lockVisibleTimeRangeOnResize?: boolean,
  rightBarStaysOnScroll?: boolean,
  borderVisible?: boolean,
  borderColor?: string,
  visible?: boolean,
  timeVisible?: boolean,
  secondsVisible?: boolean,
  shiftVisibleRangeOnNewBar?: boolean,
  tickMarkFormatter?: TickMarkFormatter,
  ticksVisible?: boolean
) => TimeScaleOptions = (
  rightOffset = 6,
  barSpacing = 6,
  minBarSpacing = 0.5,
  fixLeftEdge = false,
  fixRightEdge = false,
  lockVisibleTimeRangeOnResize = false,
  rightBarStaysOnScroll = false,
  borderVisible = true,
  borderColor = "#2B2B43",
  visible = true,
  timeVisible = false,
  secondsVisible = true,
  shiftVisibleRangeOnNewBar = true,
  tickMarkFormatter = undefined,
  ticksVisible = false
) => ({
  rightOffset,
  barSpacing,
  minBarSpacing,
  fixLeftEdge,
  fixRightEdge,
  lockVisibleTimeRangeOnResize,
  rightBarStaysOnScroll,
  borderVisible,
  borderColor,
  visible,
  timeVisible,
  secondsVisible,
  shiftVisibleRangeOnNewBar,
  tickMarkFormatter,
  ticksVisible,
});

export const getPriceScale: (
  visible: boolean,
  mode?: PriceScaleMode,
  invertScale?: boolean,
  alignLabels?: boolean,
  autoScale?: boolean,
  borderVisible?: boolean,
  borderColor?: string,
  textColor?: string | undefined,
  entireTextOnly?: boolean,
  ticksVisible?: boolean,
  scaleMargins?: PriceScaleMargins
) => PriceScaleOptions = (
  /*
   * priceRange: PriceRange = { minValue, maxValue}
   */
  visible,
  mode = PriceScaleMode.Normal,
  invertScale = false,
  alignLabels = true,
  autoScale = true,
  borderVisible = true,
  borderColor = "#2B2B43",
  textColor = undefined,
  entireTextOnly: boolean = false,
  ticksVisible: boolean = false,
  scaleMargins: PriceScaleMargins = {
    top: 0,
    bottom: 0,
  }
) => ({
  visible,
  invertScale,
  alignLabels,
  autoScale,
  mode,
  borderVisible,
  borderColor,
  textColor,
  entireTextOnly,
  ticksVisible,
  scaleMargins,
});

export const getOverlayPriceScale: (
  mode?: PriceScaleMode,
  invertScale?: boolean,
  alignLabels?: boolean,
  borderVisible?: boolean,
  borderColor?: string,
  textColor?: string | undefined,
  entireTextOnly?: boolean,
  ticksVisible?: boolean,
  scaleMargins?: PriceScaleMargins
) => OverlayPriceScaleOptions = (
  /*
   * priceRange: PriceRange = { minValue, maxValue}
   */
  mode = PriceScaleMode.Normal,
  invertScale = false,
  alignLabels = true,
  borderVisible = true,
  borderColor = "#2B2B43",
  textColor = undefined,
  entireTextOnly: boolean = false,
  ticksVisible: boolean = false,
  scaleMargins: PriceScaleMargins = {
    top: 0,
    bottom: 0,
  }
) => ({
  invertScale,
  alignLabels,
  mode,
  borderVisible,
  borderColor,
  textColor,
  entireTextOnly,
  ticksVisible,
  scaleMargins,
});

export const getLocalization: (
  locale?: string,
  priceFormatter?: PriceFormatterFn,
  timeFormatter?: TimeFormatterFn,
  dateFormat?: string
) => LocalizationOptions = (
  locale = "en-US",
  priceFormatter = undefined,
  timeFormatter = undefined,
  dateFormat = "dd MMM 'yy"
) => ({
  locale,
  priceFormatter,
  timeFormatter,
  dateFormat,
});

export const getHandleScroll: (
  mouseWheel?: boolean,
  pressedMouseMove?: boolean,
  horzTouchDrag?: boolean,
  vertTouchDrag?: boolean
) => HandleScrollOptions = (
  mouseWheel = true,
  pressedMouseMove = true,
  horzTouchDrag = true,
  vertTouchDrag = true
) => ({
  mouseWheel,
  pressedMouseMove,
  horzTouchDrag,
  vertTouchDrag,
});

export const getHandleScale: (
  mouseWheel?: boolean,
  pinch?: boolean,
  axisPressedMouseMove?: boolean,
  axisDoubleClickReset?: boolean
) => HandleScaleOptions = (
  mouseWheel = true,
  pinch = true,
  axisPressedMouseMove = true,
  axisDoubleClickReset = true
) => ({
  mouseWheel,
  pinch,
  axisPressedMouseMove,
  axisDoubleClickReset,
});

export const getKineticOptions: (
  touch?: boolean,
  mouse?: boolean
) => KineticScrollOptions = (touch = true, mouse = false) => ({
  touch,
  mouse,
});

export const getTrackingModeOptions: (
  exitMode?: TrackingModeExitMode
) => TrackingModeOptions = (exitMode = TrackingModeExitMode.OnTouchEnd) => ({
  exitMode,
});

export const getCandleOptions: () =>
  | DeepPartial<CandlestickStyleOptions & SeriesOptionsCommon>
  | undefined = () => ({
  upColor: "#4bffb5",
  downColor: "#ff4976",
  borderDownColor: "#ff4976",
  borderUpColor: "#4bffb5",
  wickDownColor: "#838ca1",
  wickUpColor: "#838ca1",
});
