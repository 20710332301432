import { ArrowUpward } from "@mui/icons-material";
import React from "react";
import ScrollToTop from "react-scroll-to-top";

const ScrollToTopButton: React.FC = () => {
  return (
    <ScrollToTop
      smooth
      style={{
        bottom: 120,
        right: 20,
        color: "white",
        background: "rgba(96, 130, 182, 0.8)",
        borderRadius: "50%",
        boxShadow: "none",
      }}
      component={<ArrowUpward />}
    />
  );
};

export default ScrollToTopButton;
