export const enSignalsFaq = {
  SIGNALS_FAQ_CROSS_TITLE_1: "What is a cross signal?",
  SIGNALS_FAQ_CROSS_DESCRIPTION_1:
    "A cross signal is calculated based on the relation between Moving Average (50) and Moving Average (200). A death or golden cross is reflecting recent price weakness or strength. Market history suggests that it tends to precede a near-term rebound with above-average price movement.",
  SIGNALS_FAQ_MACD4CDIV_TITLE_1: "What is a macd divergence signal?",
  SIGNALS_FAQ_MACD4CDIV_DESCRIPTION_1:
    "A macd divergence signal is calculated based on the combination of MACD indicator and price action. It can be separated in classic (stronger signal) and hidden (weaker signal). Divergence warns that the current price trend may be weakening or strengthening, and in some cases may lead to price change of direction.",
  SIGNALS_FAQ_ESCAN_TITLE_1: "What is an enhanced scan signal?",
  SIGNALS_FAQ_ESCAN_DESCRIPTION_1:
    "An enhanced scan signal is calculated based on the price action of the last candlesticks. It's formula is of medium sensitivity and is able to provide forecast about medium to high long-term moves either upside or downside. When these signal get more frequent in the daily period, means that the asset has topped or bottomed out.",
  SIGNALS_FAQ_USCAN_TITLE_1: "What is an ultra scan signal?",
  SIGNALS_FAQ_USCAN_DESCRIPTION_1:
    "An ultra scan signal is calculated based on the price action of the last candlesticks. It's formula is of very low sensitivity and is able to provide forecast about high long-term moves either upside or downside. Due to it's very low sensitivity, usually these signals represent a long term opportunity.",
};

export const elSignalsFaq = {
  SIGNALS_FAQ_CROSS_TITLE_1: "Τι είναι το cross σήμα;",
  SIGNALS_FAQ_CROSS_DESCRIPTION_1:
    "Το σήμα cross υπολογίζεται με βάση τη σχέση μεταξύ κινητού μέσου όρου (50) και κινητού μέσου όρου (200). Ένα death cross ή ένας golden cross αντικατοπτρίζει την πρόσφατη αδυναμία ή δύναμη των τιμών. Η ιστορία της αγοράς υποδηλώνει ότι ένα death cross τείνει να προηγείται μιας βραχυπρόθεσμης ανάκαμψης με κίνηση τιμών άνω του μέσου όρου.",
  SIGNALS_FAQ_MACD4CDIV_TITLE_1: "Τι είναι το  macd divergence σήμα;",
  SIGNALS_FAQ_MACD4CDIV_DESCRIPTION_1:
    "Το σήμα macd divergence υπολογίζεται με βάση το συνδυασμό δείκτη MACD και δράσης τιμής. Μπορεί να διαχωριστεί σε κλασικό (δυνατότερο σήμα) και κρυφό (ασθενέστερο σήμα). Η απόκλιση προειδοποιεί ότι η τρέχουσα τάση των τιμών μπορεί να εξασθενεί ή να ενισχύεται και σε ορισμένες περιπτώσεις μπορεί να οδηγήσει σε αλλαγή κατεύθυνσης των τιμών.",
  SIGNALS_FAQ_ESCAN_TITLE_1: "Τι είναι το enhanced scan σήμα;",
  SIGNALS_FAQ_ESCAN_DESCRIPTION_1:
    "Το σήμα enhanced scan υπολογίζεται με βάση την τιμή των τελευταίων κεριών. Η φόρμουλα του είναι μέτριας ευαισθησίας και είναι σε θέση να παρέχει πρόβλεψη για μεσοπρόθεσμες έως υψηλές μακροπρόθεσμες κινήσεις είτε ανοδικές είτε καθοδικές. Όταν αυτά τα σήματα γίνονται πιο συχνά στην ημερήσια περίοδο, σημαίνει ότι το περιουσιακό στοιχείο έχει φτάσει στην κορυφή ή στο κάτω μέρος της κίνησης.",
  SIGNALS_FAQ_USCAN_TITLE_1: "Τι είναι το ultra scan σήμα;",
  SIGNALS_FAQ_USCAN_DESCRIPTION_1:
    "Το σήμα ultra scan υπολογίζεται με βάση την τιμή των τελευταίων κεριών. Η φόρμουλα του είναι πολύ χαμηλής ευαισθησίας και είναι σε θέση να παρέχει πρόβλεψη για υψηλές μακροπρόθεσμες κινήσεις είτε ανάποδες είτε καθοδικές. Λόγω της πολύ χαμηλής ευαισθησίας του, συνήθως αυτά τα σήματα αντιπροσωπεύουν μια μακροπρόθεσμη ευκαιρία.",
};
