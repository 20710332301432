import React, { useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { TAppState } from "../../../../../store/reducers";
import { AppDispatch } from "../../../../../store/configureStore";
import {
  CloseFullscreenButton,
  OpenInFullButton,
} from "../../../../App/Buttons/Buttons";
import {
  closeFullScreen,
  openInFullScreen,
} from "../../../../../store/reducers/charts";
import { chartsSelector } from "../../../../selectors";

const ChartsTopBarRight: React.FC = () => {
  const [, startTransition] = useTransition();
  const dispatch = useDispatch<AppDispatch>();
  const {
    stored: {
      view: { fullScreen },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));

  return (
    <Box
      sx={{
        textAlign: "right",
        width: "50%",
      }}
    >
      {fullScreen ? (
        <CloseFullscreenButton
          color="white"
          onClick={() => {
            startTransition(() => {
              dispatch(closeFullScreen());
            });
          }}
        />
      ) : (
        <OpenInFullButton
          color="white"
          onClick={() => {
            startTransition(() => {
              dispatch(openInFullScreen());
            });
          }}
        />
      )}
    </Box>
  );
};

export default ChartsTopBarRight;
