import React, { useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { TAppState } from "../../../../../store/reducers";
import { AppDispatch } from "../../../../../store/configureStore";
import {
  CloseFullscreenButton,
  DeleteButton,
  OpenInFullButton,
  UploadButton,
} from "../../../../App/Buttons/Buttons";
import {
  candlesJsonChanged,
  signalsJsonChanged,
  closeFullScreen,
  openInFullScreen,
  toggleUploadMode,
} from "../../../../../store/reducers/backtesting";
import { backtestingSelector } from "../../../../selectors";

const BacktestingTopBarRight: React.FC = () => {
  const [, startTransition] = useTransition();
  const dispatch = useDispatch<AppDispatch>();

  const { fullScreen, uploadMode, json } = useSelector((state: TAppState) =>
    backtestingSelector(state)
  );
  const { candles, signals } = json;

  return (
    <Box
      sx={{
        textAlign: "right",
        width: "50%",
      }}
    >
      <DeleteButton
        disabled={candles.length === 0 && signals.length === 0}
        color="white"
        onClick={() => {
          dispatch(candlesJsonChanged(""));
          dispatch(signalsJsonChanged(""));
        }}
      />
      {!uploadMode && (
        <UploadButton
          color="white"
          onClick={() => {
            startTransition(() => {
              dispatch(toggleUploadMode(true));
            });
          }}
        />
      )}
      {fullScreen ? (
        <CloseFullscreenButton
          color="white"
          onClick={() => {
            startTransition(() => {
              dispatch(closeFullScreen());
            });
          }}
        />
      ) : (
        <OpenInFullButton
          color="white"
          onClick={() => {
            startTransition(() => {
              dispatch(openInFullScreen());
            });
          }}
        />
      )}
    </Box>
  );
};

export default BacktestingTopBarRight;
