import React from "react";
import { Box } from "@mui/material";
import TradingChart from "../../../../Common/TradingChart/TradingChart";
import {
  getChartLineSeries,
  getChartMarkers,
} from "../../../../Common/helpers";
import { createChartCandles } from "../../../../../utilities/helpers";
import { getLineConfig, getVolumeConfig } from "../../ChartsLines";
import {
  getSignalConfig,
  getIndicatorConfig,
  getPatternConfig,
} from "../../ChartsSignalMarkers";

type Props = {
  symbol?: string;
  name?: string;
  minMove?: number | null;
  data: Array<any>;
  indicators: Array<any>;
  patterns: Array<any>;
  signals: Array<any>;
  renderEmpty?: boolean;
  fullScreen?: boolean;
  autoSize?: boolean;
  showHistogramLegend?: boolean;
};

const PriceChart: React.FC<Props> = ({
  symbol = "",
  name = "",
  minMove = null,
  data = [],
  indicators = [],
  signals = [],
  patterns = [],
  renderEmpty = true,
  fullScreen = false,
  autoSize = false,
  showHistogramLegend = true,
}) => {
  const lineSeries = getLineConfig();
  const volumeSeries = getVolumeConfig();
  const signalMarkers = getSignalConfig();
  const indicatorMarkers = getIndicatorConfig();
  const patternMarkers = getPatternConfig();

  return (
    <Box sx={{ width: "100%" }}>
      {data.length === 0 && !renderEmpty ? (
        <></>
      ) : (
        <TradingChart
          height={500}
          symbol={symbol}
          name={name}
          minMove={minMove}
          candleData={createChartCandles(data)}
          lineSeries={getChartLineSeries(data, lineSeries, indicators)}
          markers={getChartMarkers(
            data,
            signalMarkers,
            signals,
            indicatorMarkers,
            indicators,
            patternMarkers,
            patterns
          )}
          histogramSeries={getChartLineSeries(data, volumeSeries, ["volume_1"])}
          showHistogramLegend={showHistogramLegend}
          fullScreen={fullScreen}
          autoSize={autoSize}
          fitContent={false}
          enableLeftScale
        />
      )}
    </Box>
  );
};

export default PriceChart;
