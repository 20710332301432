import React, { SyntheticEvent } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import i18n from "i18next";
import EconomyCrisisTab from "./Charts/Crisis/EconomyCrisisTab";
import EconomyEconomyTab from "./Charts/Economy/EconomyEconomyTab";
import EconomyConsumerTab from "./Charts/Consumer/EconomyConsumerTab";
import EconomyOtherTab from "./Charts/Other/EconomyOtherTab";

const ChartsPage: React.FC = () => {
  const [value, setValue] = React.useState("crisis");

  const onTabChange = (e: SyntheticEvent, tab: string) => {
    setValue(tab);
  };

  return (
    <div className="economy-page">
      <Box sx={{ width: "100%", paddingBottom: "4%" }}>
        <Tabs value={value} onChange={onTabChange} variant="fullWidth">
          <Tab
            icon={<CrisisAlertIcon />}
            iconPosition="start"
            value="crisis"
            label={i18n.t("ECONOMY_CRISIS_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
          <Tab
            icon={<AccountBalanceIcon />}
            iconPosition="start"
            value="economy"
            label={i18n.t("ECONOMY_ECONOMY_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
          <Tab
            icon={<EmojiPeopleIcon />}
            iconPosition="start"
            value="consumer"
            label={i18n.t("ECONOMY_CONSUMER_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
          <Tab
            icon={<EqualizerIcon />}
            iconPosition="start"
            value="other"
            label={i18n.t("ECONOMY_OTHER_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        {value === "crisis" && <EconomyCrisisTab />}
        {value === "economy" && <EconomyEconomyTab />}
        {value === "consumer" && <EconomyConsumerTab />}
        {value === "other" && <EconomyOtherTab />}
      </Box>
    </div>
  );
};

export default ChartsPage;
