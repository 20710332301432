import { AppModes } from "./enums";

export const APP = {
  MODE: AppModes.Production,
};

export const URL = "https://dcatrader.com";

export const SNACKBAR_DURATION = 3000;

export const API = {
  URL: `https://api.dcatrader.com`,
  TIMEOUT: 30000,
  EXTENDED_TIMEOUT: 70000,
};
