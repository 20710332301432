import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { first } from "lodash";
import { Box, Stack } from "@mui/material";
import { AppDispatch } from "../../../../store/configureStore";
import { TAppState } from "../../../../store/reducers";
import BaseSelect from "../../../Common/BaseSelect/BaseSelect";
import {
  storedIndicatorsChanged,
  storedPatternsChanged,
  storedPairChanged,
  storedPeriodChanged,
  storedSignalsChanged,
} from "../../../../store/reducers/charts";
import {
  useGetActivePeriodsQuery,
  useGetActivePairsQuery,
  useGetActiveIndicatorsQuery,
  useGetActivePatternsQuery,
  useGetActiveSignalsQuery,
} from "../../../../features/api";
import { chartsSelector } from "../../../selectors";
import { filterSignals } from "../../../../utilities/filters";

const ChartsFilterArea: React.FC = () => {
  const { t } = useTranslation();
  const { data: periods, isLoading: periodsLoading } =
    useGetActivePeriodsQuery();
  const { data: pairs, isLoading: pairsLoading } = useGetActivePairsQuery();
  const { data: signals, isLoading: signalsLoading } =
    useGetActiveSignalsQuery();
  const { data: indicators, isLoading: indicatorsLoading } =
    useGetActiveIndicatorsQuery();
  const { data: patterns, isLoading: patternsLoading } =
    useGetActivePatternsQuery();

  const dispatch = useDispatch<AppDispatch>();

  const {
    stored: {
      filters: { period, pair },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));

  const pairSelected = (pairs) => {
    dispatch(storedPairChanged(first(pairs)));
  };
  const indicatorsSelected = (indicators) => {
    dispatch(storedIndicatorsChanged(indicators.map((i: any) => i.INDEX)));
  };
  const patternsSelected = (patterns) => {
    dispatch(storedPatternsChanged(patterns.map((i: any) => i.INDEX)));
  };
  const signalsSelected = (signals) => {
    dispatch(storedSignalsChanged(signals));
  };
  const periodSelected = (periods) => {
    dispatch(storedPeriodChanged(first(periods)));
  };

  return (
    <Stack
      spacing={{ xs: 2, sm: 2 }}
      direction="row"
      useFlexGap
      flexWrap="wrap"
      marginBottom={5}
    >
      <Box>
        {!periodsLoading && (
          <BaseSelect
            data={periods}
            loading={periodsLoading}
            onChange={periodSelected}
            formatDisplay={(row: any) =>
              `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
                row.SYMBOL
              })`
            }
            selectKey="CODE"
            inputLabel={t("PERIOD_SELECT_INPUT_LABEL")}
            label={t("PERIOD_SELECT_LABEL")}
            minWidth={150}
            maxWidth={150}
            defaultValue={period?.CODE || first(periods)?.CODE}
          />
        )}
      </Box>
      <Box>
        {!pairsLoading && (
          <BaseSelect
            data={pairs}
            loading={pairsLoading}
            onChange={pairSelected}
            selectKey="ID"
            displayKey="SYMBOL"
            inputLabel={t("PAIR_SELECT_INPUT_LABEL")}
            label={t("PAIR_SELECT_LABEL")}
            minWidth={200}
            maxWidth={200}
            defaultValue={pair?.ID || first(pairs)?.ID}
            formatDisplay={(row, key) =>
              row[key].replaceAll(row.SYMBOL_PREFIX, "")
            }
            optionInfo={["TYPE_NAME", "PROVIDER_NAME"]}
            optionInfoStyle={{
              fontSize: "70%",
              color: "rgba(212,255,0,0.8)",
              paddingBottom: "1%",
            }}
            sortFn={(row: any) => [
              row.SYMBOL.replaceAll(row.SYMBOL_PREFIX, ""),
            ]}
            hasSearch
            searchKey="SYMBOL"
          />
        )}
      </Box>
      <Box>
        {!periodsLoading && !signalsLoading && (
          <BaseSelect
            data={filterSignals(signals, period)}
            loading={signalsLoading}
            onChange={signalsSelected}
            formatDisplay={(row: any) =>
              `${t(`SIGNAL_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)}`
            }
            selectKey="CODE"
            multiselect
            inputLabel={t("SIGNAL_SELECT_INPUT_LABEL")}
            label={t("SIGNAL_SELECT_LABEL")}
            minWidth={250}
            maxWidth={250}
          />
        )}
      </Box>
      <Box>
        <BaseSelect
          data={indicators}
          loading={indicatorsLoading}
          onChange={indicatorsSelected}
          multiselect
          inputLabel={t("INDICATOR_SELECT_INPUT_LABEL")}
          label={t("INDICATOR_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
          hasHeader={(indicator) => {
            const { config } = indicator;
            const { parameters } = config;
            return period?.CODE && parameters[period.CODE];
          }}
          headerTitleKey="NAME"
          override
          overrideData={(indicator) => {
            const { CODE, config } = indicator;
            const { parameters } = config;
            let INDEX = 1;
            return period?.CODE && parameters[period.CODE]
              ? parameters[period.CODE].map((param: any) => ({
                  NAME: t(
                    `INDICATOR_SELECT_DISPLAY_LABEL_${CODE.toUpperCase()}`
                  ),
                  INDEX: `${CODE}_${INDEX++}`,
                  CODE,
                  DISPLAY: `(${Object.keys(param.calculation)
                    .map((v) => param.calculation[v])
                    .join(", ")})`,
                }))
              : [];
          }}
          displayKey="NAME"
          overrideSelectKey="INDEX"
          overrideDisplayKey="DISPLAY"
        />
      </Box>
      <Box>
        <BaseSelect
          data={patterns}
          loading={patternsLoading}
          onChange={patternsSelected}
          multiselect
          inputLabel={t("PATTERN_SELECT_INPUT_LABEL")}
          label={t("PATTERN_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
          hasHeader={(pattern) => {
            const { config } = pattern;
            const { parameters } = config;
            return period?.CODE && parameters[period.CODE];
          }}
          headerTitleKey="NAME"
          override
          overrideData={(pattern) => {
            const { CODE, config } = pattern;
            const { parameters } = config;
            let INDEX = 1;
            return period?.CODE && parameters[period.CODE]
              ? parameters[period.CODE].map((param: any) => ({
                  NAME: t(`PATTERN_SELECT_DISPLAY_LABEL_${CODE.toUpperCase()}`),
                  INDEX: `${CODE}_${INDEX++}`,
                  CODE,
                  DISPLAY: `(${Object.keys(param.calculation)
                    .map((v) => param.calculation[v])
                    .join(", ")})`,
                }))
              : [];
          }}
          displayKey="NAME"
          overrideSelectKey="INDEX"
          overrideDisplayKey="DISPLAY"
        />
      </Box>
    </Stack>
  );
};

export default ChartsFilterArea;
