import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { USER_LOGIN, USER_LOGOUT, USER_FETCH_SESSION } from "./_types";
import {
  setInterceptors,
  clearInterceptors,
} from "./../../features/interceptors/app";
import { userUrl } from "./../../constants/endpoints";
import { removeUserInStorage } from "../../utilities/helpers";

export type TLoginPayload = {
  email: string;
  password: string;
};

export const login = createAsyncThunk(
  USER_LOGIN,
  async (params: TLoginPayload) => {
    const { email, password } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.post(`${userUrl}/login`, {
      email,
      password,
    });
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const logout = createAsyncThunk(USER_LOGOUT, async () => {
  const intercepted = setInterceptors(axios.create({ withCredentials: true }));
  const response = await intercepted.instance.post(`${userUrl}/logout`, {});
  removeUserInStorage();
  clearInterceptors(intercepted);
  return response.data;
});

export const fetchSession = createAsyncThunk(USER_FETCH_SESSION, async () => {
  const intercepted = setInterceptors(axios.create({ withCredentials: true }));
  const response = await intercepted.instance.get(`${userUrl}/session`);
  clearInterceptors(intercepted);
  return response.data;
});
