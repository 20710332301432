import { combineReducers } from "@reduxjs/toolkit";
import { api } from "../../features/api";
import signalReducer, { TSignalState } from "./signal";
import analysisReducer, { TAnalysisState } from "./analysis";
import chartsReducer, { TChartsState } from "./charts";
import economyReducer, { TEconomyState } from "./economy";
import otherReducer, { TOtherState } from "./other";
import backtestingReducer, { TBacktestingState } from "./backtesting";
import commonReducer, { TCommonState } from "./common";
import userReducer, { TUserState } from "./user";

export default combineReducers({
  // Add the generated RTK Query "API slice" caching reducer
  [api.reducerPath]: api.reducer,
  signal: signalReducer,
  analysis: analysisReducer,
  charts: chartsReducer,
  economy: economyReducer,
  other: otherReducer,
  backtesting: backtestingReducer,
  common: commonReducer,
  user: userReducer,
});

export interface TAppState {
  signal: TSignalState;
  analysis: TAnalysisState;
  charts: TChartsState;
  economy: TEconomyState;
  other: TOtherState;
  backtesting: TBacktestingState;
  common: TCommonState;
  user: TUserState;
}
