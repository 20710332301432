import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import logo from "./../../../assets/images/logo192.png";

type Props = {
  isMobile: boolean;
};

const Logo: React.FC<Props> = ({ isMobile }) => {
  const { t } = useTranslation();

  return !isMobile ? (
    <Box
      component="img"
      sx={{
        height: 50,
        width: 50,
        display: { xs: "none", md: "flex" },
        mr: 1,
      }}
      src={logo}
      alt={t("APP_NAME") || undefined}
    />
  ) : (
    <Box
      component="img"
      sx={{
        height: 50,
        width: 50,
        display: { xs: "flex", md: "none" },
        mr: 1,
      }}
      src={logo}
      alt={t("APP_NAME") || undefined}
    />
  );
};

export default Logo;
