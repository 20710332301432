import i18n from "i18next";
import { GridCellParams } from "@mui/x-data-grid";
import clsx from "clsx";

const getColumns = () => {
  return [
    {
      field: `PNG32_PATH`,
      headerName: i18n.t(`OTHER_RANKING_GRID_ICON`),
      width: 70,
      renderCell: (params: any) => {
        const { row } = params;
        const { PNG32_PATH, CODE } = row;
        return <img width={20} height={20} src={PNG32_PATH} alt={CODE} />;
      },
    },
    {
      field: `CODE`,
      headerName: i18n.t(`OTHER_RANKING_GRID_CODE`),
      width: 110,
      renderCell: (params: any) => {
        const { row } = params;
        const { CODE } = row;
        return CODE.replaceAll("_", "");
      },
    },
    {
      field: `NAME`,
      headerName: i18n.t(`OTHER_RANKING_GRID_NAME`),
      width: 115,
    },
    {
      field: `AGE_DAYS`,
      headerName: i18n.t(`OTHER_RANKING_GRID_AGE`),
      width: 80,
      valueGetter: (params: any) => {
        const { value } = params;
        return value !== null ? Number(value) : "-";
      },
    },
    {
      field: `MC_RANK`,
      headerName: i18n.t(`OTHER_RANKING_GRID_MC_RANK`),
      width: 150,
      valueGetter: (params: any) => {
        const { value } = params;
        return value !== null ? Number(value) : "-";
      },
    },
    {
      field: `MC_RANK_1D`,
      headerName: i18n.t(`OTHER_RANKING_GRID_MC_RANK_1D`),
      width: 160,
      cellClassName: (params: GridCellParams<any, number>) => {
        const { value } = params;
        return value == null
          ? ""
          : clsx(`ranking-grid`, {
              up: value >= 50,
              down: value <= -50,
            });
      },
      valueGetter: (params: any) => {
        const { value } = params;
        return value !== null ? Number(value) : "-";
      },
    },
    {
      field: `MC_RANK_7D`,
      headerName: i18n.t(`OTHER_RANKING_GRID_MC_RANK_7D`),
      width: 150,
      cellClassName: (params: GridCellParams<any, number>) => {
        const { value } = params;
        return value == null
          ? ""
          : clsx(`ranking-grid`, {
              up: value >= 90,
              down: value <= -90,
            });
      },
      valueGetter: (params: any) => {
        const { value } = params;
        return value !== null ? Number(value) : "-";
      },
    },
    {
      field: `MC_RANK_30D`,
      headerName: i18n.t(`OTHER_RANKING_GRID_MC_RANK_30D`),
      width: 170,
      cellClassName: (params: GridCellParams<any, number>) => {
        const { value } = params;
        return value == null
          ? ""
          : clsx(`ranking-grid`, {
              up: value >= 90,
              down: value <= -90,
            });
      },
      valueGetter: (params: any) => {
        const { value } = params;
        return value !== null ? Number(value) : "-";
      },
    },
    {
      field: `LQ_RANK`,
      headerName: i18n.t(`OTHER_RANKING_GRID_LQ_RANK`),
      width: 150,
      valueGetter: (params: any) => {
        const { value } = params;
        return value !== null ? Number(value) : "-";
      },
    },
    {
      field: `LQ_RANK_1D`,
      headerName: i18n.t(`OTHER_RANKING_GRID_LQ_RANK_1D`),
      width: 150,
      valueGetter: (params: any) => {
        const { value } = params;
        return value !== null ? Number(value) : "-";
      },
    },
    {
      field: `LQ_RANK_7D`,
      headerName: i18n.t(`OTHER_RANKING_GRID_LQ_RANK_7D`),
      width: 150,
      valueGetter: (params: any) => {
        const { value } = params;
        return value !== null ? Number(value) : "-";
      },
    },
    {
      field: `LQ_RANK_30D`,
      headerName: i18n.t(`OTHER_RANKING_GRID_LQ_RANK_30D`),
      width: 150,
      valueGetter: (params: any) => {
        const { value } = params;
        return value !== null ? Number(value) : "-";
      },
    },
  ].concat(getHiddenColumns());
};

const getHiddenColumns = () => {
  return [
    {
      field: `ID`,
      headerName: i18n.t(`OTHER_RANKING_GRID_ID`),
      width: 100,
    },
  ];
};

export const getVisibility = () => {
  return { ID: false };
};

export const getSortModel = () => {
  return [
    {
      field: "MC_RANK_1D",
      sort: "desc",
    },
  ];
};

export default getColumns;
export const gridKey = "ID";
