import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Box } from "@mui/material";
import MeterChart from "../../../../Common/MeterChart/MeterChart";
import { TAppState } from "../../../../../store/reducers";
import { otherSelector } from "../../../../selectors";

const FearGreedMeter: React.FC = () => {
  const {
    fearAndGreed: { crypto },
  } = useSelector((state: TAppState) => otherSelector(state));
  const [current, setCurrent] = useState<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const filtered = crypto.filter(
      (fng) =>
        fng.FG_AT ===
        DateTime.utc().startOf("day").toFormat("yyyy-MM-dd HH:mm:ss")
    );
    const value = filtered.length > 0 ? filtered[0] : null;
    setCurrent(value);
  }, [crypto]);

  const getColor = (percent: number) => {
    if (percent <= 0.25) return "#FF4D17";
    else if (percent > 0.25 && percent <= 0.46) return "#FF8D18";
    else if (percent > 0.46 && percent <= 0.54) return "#FDB737";
    else if (percent > 0.54 && percent <= 0.76) return "#AEB335";
    else if (percent > 0.76) return "#4CB43C";
    else return "#FDB737";
  };

  const getChartColors = () => [
    getColor(0),
    getColor(0.26),
    getColor(0.47),
    getColor(0.55),
    getColor(0.77),
  ];

  return (
    <>
      <Box sx={{ height: 450, width: 400, margin: "auto" }}>
        <h3 style={{ textAlign: "center" }}>
          {t("OTHER_FEARGREED_CRYPTO_CHART_HEADER")}
        </h3>
        {current && (
          <>
            <h4 style={{ textAlign: "center" }}>
              <span> {t("OTHER_FEARGREED_CRYPTO_CHART_TODAY")}: </span>
              <span style={{ color: getColor(current.FG_VALUE / 100) }}>
                {current?.FG_CLASS}
              </span>
            </h4>
            <MeterChart
              arcsLength={[0.25, 0.21, 0.08, 0.22, 0.24]}
              percent={current.FG_VALUE / 100}
              colors={getChartColors()}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default FearGreedMeter;
