import { createSlice } from "@reduxjs/toolkit";

export interface TBacktestingState {
  json: {
    candles: string;
    signals: string;
  };
  fullScreen: boolean;
  autoSize: boolean;
  uploadMode: boolean;
}

const initialState: TBacktestingState = {
  json: {
    candles: "",
    signals: "",
  },
  fullScreen: false,
  autoSize: true,
  uploadMode: false,
};

const backtestingSlice = createSlice({
  name: "signal",
  initialState,
  reducers: {
    candlesJsonChanged: (state, action) => {
      state.json.candles = action.payload;
    },
    signalsJsonChanged: (state, action) => {
      state.json.signals = action.payload;
    },
    openInFullScreen: (state) => {
      state.fullScreen = true;
      state.autoSize = false;
    },
    closeFullScreen: (state) => {
      state.fullScreen = false;
      state.autoSize = true;
    },
    toggleUploadMode: (state, action) => {
      state.uploadMode = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  candlesJsonChanged,
  signalsJsonChanged,
  openInFullScreen,
  closeFullScreen,
  toggleUploadMode,
} = backtestingSlice.actions;
export default backtestingSlice.reducer;
