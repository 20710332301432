import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { TAppState } from "../../../../../store/reducers";
import { AppDispatch } from "../../../../../store/configureStore";
import FullScreenDialog from "../../../../Common/FullScreenDialog/FullScreenDialog";
import { backtestingSelector } from "../../../../selectors";
import {
  closeFullScreen,
  toggleUploadMode,
} from "../../../../../store/reducers/backtesting";
import BacktestingChart from "../BacktestingChart";
import BacktestingTopBarLeft from "./BacktestingTopBarLeft";
import BacktestingTopBarRight from "./BacktestingTopBarRight";
import BacktestingUploadTopBar from "../Upload/BacktestingUploadTopBar";
import BacktestingUploadFields from "../Upload/BacktestingUploadFields";
import BacktestingUploadInfo from "../Upload/BacktestingUploadInfo";

const BacktestingTopBar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { fullScreen, autoSize, uploadMode } = useSelector((state: TAppState) =>
    backtestingSelector(state)
  );

  return (
    <Stack
      sx={{
        width: "100%",
        background: "rgba(96, 130, 182, 0.6)",
        flexDirection: "row",
      }}
    >
      <BacktestingTopBarLeft />
      <BacktestingTopBarRight />
      <FullScreenDialog
        isOpen={fullScreen}
        children={
          <>
            <Stack
              sx={{
                width: "100%",
                background: "rgba(96, 130, 182, 0.6)",
                flexDirection: "row",
              }}
            >
              <BacktestingTopBarLeft />
              <BacktestingTopBarRight />
            </Stack>
            <BacktestingChart fullScreen={fullScreen} autoSize={autoSize} />
          </>
        }
        onCloseCallback={() => dispatch(closeFullScreen())}
      />
      <FullScreenDialog
        isOpen={uploadMode}
        children={
          <>
            <BacktestingUploadTopBar />
            <BacktestingUploadFields />
            <BacktestingUploadInfo />
          </>
        }
        onCloseCallback={() => dispatch(toggleUploadMode(false))}
      />
    </Stack>
  );
};

export default BacktestingTopBar;
