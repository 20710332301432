import React from "react";
import { Box } from "@mui/material";

const ChartsTopBarLeft: React.FC = () => {
  return (
    <Box
      sx={{
        textAlign: "left",
        width: "50%",
        fontSize: "80%",
        paddingLeft: "1%",
      }}
    ></Box>
  );
};

export default ChartsTopBarLeft;
