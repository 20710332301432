import { IconButton } from "@mui/material";

type BaseProps = {
  children: React.ReactNode;
  props: {
    [key: string]: any;
  };
};

export const BaseButton: React.FC<BaseProps> = ({ children, props }) => {
  return <IconButton {...props}>{children}</IconButton>;
};
