import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../../../../store/configureStore";
import {
  candlesJsonChanged,
  signalsJsonChanged,
} from "../../../../../store/reducers/backtesting";
import { TAppState } from "../../../../../store/reducers";
import { backtestingSelector } from "../../../../selectors";
import {
  isBacktestingCandleJson,
  isBacktestingSignalJson,
} from "./../../../../../utilities/helpers";

type Props = {};

const BacktestingUploadFields: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { json } = useSelector((state: TAppState) =>
    backtestingSelector(state)
  );
  const { candles, signals } = json;
  const onCandlesChange = (e) => {
    dispatch(candlesJsonChanged(e.target.value));
  };

  const onSignalsChange = (e) => {
    dispatch(signalsJsonChanged(e.target.value));
  };
  return (
    <Stack style={{ padding: "2%" }}>
      <Box style={{ padding: "1%" }}>
        <TextField
          multiline
          onChange={onCandlesChange}
          value={candles}
          fullWidth
          rows={11}
          label={t("BACKTESTING_CANDLES_LABEL")}
          error={!isBacktestingCandleJson(candles)}
        />
      </Box>
      <Box style={{ padding: "1%" }}>
        <TextField
          multiline
          onChange={onSignalsChange}
          value={signals}
          fullWidth
          rows={11}
          label={t("BACKTESTING_SIGNALS_LABEL")}
          error={!isBacktestingSignalJson(signals)}
        />
      </Box>
    </Stack>
  );
};

export default BacktestingUploadFields;
