import { configureStore } from "@reduxjs/toolkit";
import { api } from "../features/api";
import rootReducer from "./reducers/index";

export const store = configureStore({
  reducer: rootReducer,
  // Add the RTK Query API middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
