export const enSettingsDialog = {
  SETTINGS_DIALOG_TITLE: "Settings",
  SETTINGS_DIALOG_THEME_LABEL: "Light/Dark",
  SETTINGS_DIALOG_LANGUAGE_LABEL: "Language",
  SETTINGS_DIALOG_LANGUAGE_OPTION_EL: "Ελληνικά",
  SETTINGS_DIALOG_LANGUAGE_OPTION_EN: "English",
  SETTINGS_DIALOG_BUTTON_CANCEL: "CANCEL",
  SETTINGS_DIALOG_BUTTON_OK: "OK",
};

export const elSettingsDialog = {
  SETTINGS_DIALOG_TITLE: "Ρυθμίσεις",
  SETTINGS_DIALOG_THEME_LABEL: "Ανοιχτό/Σκούρο",
  SETTINGS_DIALOG_LANGUAGE_LABEL: "Γλώσσα",
  SETTINGS_DIALOG_LANGUAGE_OPTION_EL: "Ελληνικά",
  SETTINGS_DIALOG_LANGUAGE_OPTION_EN: "English",
  SETTINGS_DIALOG_BUTTON_CANCEL: "ΑΚΥΡΟ",
  SETTINGS_DIALOG_BUTTON_OK: "ΕΝΤΑΞΕΙ",
};
