import React, { useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { first } from "lodash";
import { Box, Stack } from "@mui/material";
import { AppDispatch } from "../../../../store/configureStore";
import BaseSelect from "../../../Common/BaseSelect/BaseSelect";
import { TAppState } from "../../../../store/reducers";
import { DeleteButton, UploadButton } from "../../../App/Buttons/Buttons";
import {
  liveJsonChanged,
  liveDataChanged,
  liveIndicatorsChanged,
  livePatternsChanged,
  liveSignalsChanged,
  toggleLiveMode,
  livePeriodChanged,
} from "../../../../store/reducers/charts";
import {
  useGetSourcePeriodsQuery,
  useGetActiveIndicatorsQuery,
  useGetActivePatternsQuery,
  useGetActiveSignalsQuery,
} from "../../../../features/api";
import { chartsSelector } from "../../../selectors";
import { filterSignals } from "../../../../utilities/filters";

const ChartsFilterArea: React.FC = () => {
  const { t } = useTranslation();
  const [, startTransition] = useTransition();
  const { data: periods, isLoading: periodsLoading } =
    useGetSourcePeriodsQuery();
  const { data: signals, isLoading: signalsLoading } =
    useGetActiveSignalsQuery();
  const { data: indicators, isLoading: indicatorsLoading } =
    useGetActiveIndicatorsQuery();
  const { data: patterns, isLoading: patternsLoading } =
    useGetActivePatternsQuery();
  const {
    live: {
      data,
      filters: { period },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));

  const dispatch = useDispatch<AppDispatch>();

  const periodSelected = (periods: any) => {
    dispatch(livePeriodChanged(first(periods)));
  };

  const indicatorsSelected = (indicators) => {
    dispatch(liveIndicatorsChanged(indicators.map((i: any) => i.INDEX)));
  };

  const patternsSelected = (patterns) => {
    dispatch(livePatternsChanged(patterns.map((i: any) => i.INDEX)));
  };

  const signalsSelected = (signals) => {
    dispatch(liveSignalsChanged(signals));
  };

  return (
    <Stack
      spacing={{ xs: 2, sm: 2 }}
      direction="row"
      useFlexGap
      flexWrap="wrap"
      marginBottom={5}
    >
      <Box>
        <BaseSelect
          data={periods}
          loading={periodsLoading}
          onChange={periodSelected}
          formatDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          selectKey="CODE"
          inputLabel={t("PERIOD_SELECT_INPUT_LABEL")}
          label={t("PERIOD_SELECT_LABEL")}
          minWidth={200}
          maxWidth={200}
          defaultValue={period?.CODE}
        />
      </Box>
      <Box>
        {!periodsLoading && !signalsLoading && (
          <BaseSelect
            data={filterSignals(signals, period)}
            loading={signalsLoading}
            onChange={signalsSelected}
            formatDisplay={(row: any) =>
              `${t(`SIGNAL_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)}`
            }
            selectKey="CODE"
            multiselect
            inputLabel={t("SIGNAL_SELECT_INPUT_LABEL")}
            label={t("SIGNAL_SELECT_LABEL")}
            minWidth={250}
            maxWidth={250}
          />
        )}
      </Box>
      <Box>
        <BaseSelect
          data={indicators}
          loading={indicatorsLoading}
          onChange={indicatorsSelected}
          multiselect
          inputLabel={t("INDICATOR_SELECT_INPUT_LABEL")}
          label={t("INDICATOR_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
          hasHeader={(indicator) => {
            const { config } = indicator;
            const { parameters } = config;
            return period?.CODE && parameters[period.CODE];
          }}
          headerTitleKey="NAME"
          override
          overrideData={(indicator) => {
            const { CODE, config } = indicator;
            const { parameters } = config;
            let INDEX = 1;
            return period?.CODE && parameters[period.CODE]
              ? parameters[period.CODE].map((param: any) => ({
                  NAME: t(
                    `INDICATOR_SELECT_DISPLAY_LABEL_${CODE.toUpperCase()}`
                  ),
                  INDEX: `${CODE}_${INDEX++}`,
                  CODE,
                  DISPLAY: `(${Object.keys(param.calculation)
                    .map((v) => param.calculation[v])
                    .join(", ")})`,
                }))
              : [];
          }}
          displayKey="NAME"
          overrideSelectKey="INDEX"
          overrideDisplayKey="DISPLAY"
        />
      </Box>
      <Box>
        <BaseSelect
          data={patterns}
          loading={patternsLoading}
          onChange={patternsSelected}
          multiselect
          inputLabel={t("PATTERN_SELECT_INPUT_LABEL")}
          label={t("PATTERN_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
          hasHeader={(pattern) => {
            const { config } = pattern;
            const { parameters } = config;
            return period?.CODE && parameters[period.CODE];
          }}
          headerTitleKey="NAME"
          override
          overrideData={(pattern) => {
            const { CODE, config } = pattern;
            const { parameters } = config;
            let INDEX = 1;
            return period?.CODE && parameters[period.CODE]
              ? parameters[period.CODE].map((param: any) => ({
                  NAME: t(`PATTERN_SELECT_DISPLAY_LABEL_${CODE.toUpperCase()}`),
                  INDEX: `${CODE}_${INDEX++}`,
                  CODE,
                  DISPLAY: `(${Object.keys(param.calculation)
                    .map((v) => param.calculation[v])
                    .join(", ")})`,
                }))
              : [];
          }}
          displayKey="NAME"
          overrideSelectKey="INDEX"
          overrideDisplayKey="DISPLAY"
        />
      </Box>
      <Box>
        <DeleteButton
          disabled={data.length === 0}
          onClick={() => {
            dispatch(liveJsonChanged(""));
            dispatch(liveDataChanged([]));
            dispatch(liveIndicatorsChanged([]));
            dispatch(liveSignalsChanged([]));
          }}
        />
      </Box>
      <Box>
        <UploadButton
          onClick={() => {
            startTransition(() => {
              dispatch(toggleLiveMode(true));
            });
          }}
        />
      </Box>
    </Stack>
  );
};

export default ChartsFilterArea;
