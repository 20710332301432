import React from "react";
import { useSelector } from "react-redux";
import { TAppState } from "../../../../store/reducers";
import ChartsFilterArea from "./ChartsFilterArea";
import PriceChart from "../Common/Charts/PriceChart";
import RangeChart from "../Common/Charts/RangeChart";
import NoRangeChart from "../Common/Charts/NoRangeChart";
import HistogramChart from "../Common/Charts/HistogramChart";
import { chartsSelector } from "../../../selectors";
import ChartLiveDialog from "./Dialog/ChartsLiveDialog";

const ChartsLiveTab: React.FC = () => {
  const {
    live: {
      data,
      view: { modeActive, indicators, patterns, signals },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));
  return (
    <div className="charts-live-page">
      {modeActive && <ChartLiveDialog />}
      <ChartsFilterArea />
      <PriceChart
        indicators={indicators}
        patterns={patterns}
        signals={signals}
        data={data}
        showHistogramLegend={false}
        renderEmpty
      />
      <RangeChart indicators={indicators} data={data} />
      <NoRangeChart indicators={indicators} data={data} />
      <HistogramChart indicators={indicators} data={data} />
    </div>
  );
};

export default ChartsLiveTab;
