import React from "react";
import { Box } from "@mui/material";
import TradingChart from "../../../../Common/TradingChart/TradingChart";
import { getChartLineSeries } from "../../../../Common/helpers";
import { getRangeConfig } from "../../ChartsLines";

type Props = {
  symbol?: string;
  name?: string;
  data: Array<any>;
  indicators: Array<any>;
  renderEmpty?: boolean;
};

const RangeChart: React.FC<Props> = ({
  symbol = "",
  name = "",
  data = [],
  indicators = [],
  renderEmpty = true,
}) => {
  const lineSeries = getRangeConfig();

  return (
    <Box sx={{ width: "100%", paddingTop: "3%" }}>
      {data.length === 0 && !renderEmpty ? (
        <></>
      ) : (
        <TradingChart
          height={150}
          symbol={symbol}
          name={name}
          lineSeries={getChartLineSeries(data, lineSeries, indicators)}
          fitContent={false}
        />
      )}
    </Box>
  );
};

export default RangeChart;
