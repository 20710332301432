import i18n from "i18next";

export const getSignalConfig = () => {
  return [
    {
      code: "cross",
      markers: [
        {
          key: "CROSS_50_200",
          value: 1,
          config: {
            position: "belowBar",
            color: "#ffd700",
            shape: "circle",
            text: i18n.t("CHART_SIGNALS_GOLDEN_CROSS_50_200"),
          },
        },
        {
          key: "CROSS_50_200",
          value: 2,
          config: {
            position: "aboveBar",
            color: "#ad5cad",
            shape: "circle",
            text: i18n.t("CHART_SIGNALS_DEATH_CROSS_50_200"),
          },
        },
      ],
    },
    {
      code: "macd4cdiv",
      markers: [
        {
          key: "CLASSIC_20_50_9",
          value: 1,
          config: {
            position: "belowBar",
            color: "#4cbb17",
            shape: "square",
            text: i18n.t("CHART_SIGNALS_MACD4CDIV_C_BULL_20_50_9"),
          },
        },
        {
          key: "CLASSIC_20_50_9",
          value: 2,
          config: {
            position: "aboveBar",
            color: "#ed1c24",
            shape: "square",
            text: i18n.t("CHART_SIGNALS_MACD4CDIV_C_BEAR_20_50_9"),
          },
        },
        {
          key: "HIDDEN_20_50_9",
          value: 1,
          config: {
            position: "belowBar",
            color: "#ace1af",
            shape: "square",
            text: i18n.t("CHART_SIGNALS_MACD4CDIV_H_BULL_20_50_9"),
          },
        },
        {
          key: "HIDDEN_20_50_9",
          value: 2,
          config: {
            position: "aboveBar",
            color: "#fd5e53",
            shape: "square",
            text: i18n.t("CHART_SIGNALS_MACD4CDIV_H_BEAR_20_50_9"),
          },
        },
      ],
    },
    {
      code: "escan",
      markers: [
        {
          key: "ESCAN_5",
          value: 1,
          config: {
            position: "belowBar",
            color: "#32cd32",
            shape: "arrowUp",
            text: i18n.t("CHART_SIGNALS_ESCAN_BUY_5"),
          },
        },
        {
          key: "ESCAN_5",
          value: 2,
          config: {
            position: "aboveBar",
            color: "#ff2800",
            shape: "arrowDown",
            text: i18n.t("CHART_SIGNALS_ESCAN_SELL_5"),
          },
        },
      ],
    },
    {
      code: "uscan",
      markers: [
        {
          key: "USCAN_5",
          value: 1,
          config: {
            position: "belowBar",
            color: "#32cd32",
            shape: "arrowUp",
            text: i18n.t("CHART_SIGNALS_USCAN_BUY_5"),
          },
        },
        {
          key: "USCAN_5",
          value: 2,
          config: {
            position: "aboveBar",
            color: "#ff2800",
            shape: "arrowDown",
            text: i18n.t("CHART_SIGNALS_USCAN_SELL_5"),
          },
        },
      ],
    },
  ];
};

export const getIndicatorConfig = () => {
  return [
    {
      code: "fractal_1",
      markers: [
        {
          key: "FRACTAL_2",
          value: 1,
          config: {
            position: "belowBar",
            color: "#ff2800",
            shape: "arrowDown",
            text: "",
          },
        },
        {
          key: "FRACTAL_2",
          value: 2,
          config: {
            position: "aboveBar",
            color: "#32cd32",
            shape: "arrowUp",
            text: "",
          },
        },
      ],
    },
  ];
};

export const getPatternConfig = () => {
  return [
    {
      code: "fractal_trend_1",
      markers: [
        {
          key: "FRACTAL_TREND_2",
          value: 1,
          config: {
            position: "aboveBar",
            color: "rgba(50, 205, 50, 0.6)",
            shape: "arrowUp",
            text: "",
          },
        },
        {
          key: "FRACTAL_TREND_2",
          value: 2,
          config: {
            position: "belowBar",
            color: "rgba(255, 40, 0, 0.6)",
            shape: "arrowDown",
            text: "",
          },
        },
        {
          key: "FRACTAL_TREND_2",
          value: 3,
          config: {
            position: "aboveBar",
            color: "rgba(255, 255, 255, 0.6)",
            shape: "circle",
            text: "",
          },
        },
      ],
    },
    {
      code: "fractal_price_trend_1",
      markers: [
        {
          key: "FRACTAL_PRICE_TREND_1",
          value: 1,
          config: {
            position: "aboveBar",
            color: "rgba(50, 205, 50, 0.6)",
            shape: "arrowUp",
            text: "",
          },
        },
        {
          key: "FRACTAL_PRICE_TREND_1",
          value: 2,
          config: {
            position: "belowBar",
            color: "rgba(255, 40, 0, 0.6)",
            shape: "arrowDown",
            text: "",
          },
        },
        {
          key: "FRACTAL_PRICE_TREND_1",
          value: 3,
          config: {
            position: "aboveBar",
            color: "rgba(255, 255, 255, 0.6)",
            shape: "circle",
            text: "",
          },
        },
      ],
    },
  ];
};
