import i18n from "i18next";

export const getLineConfig = () => {
  return [
    {
      code: "boll_1",
      label: i18n.t("CHART_SIGNALS_BOLL_LEGEND_LABEL_1"),
      lines: [
        {
          key: "BOLL_UPPER_20_2",
          config: {
            color: "#2962FF",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "BOLL_MIDDLE_20_2",
          config: {
            color: "#CD7F32",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "BOLL_LOWER_20_2",
          config: {
            color: "#2962FF",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "keltner_1",
      label: i18n.t("CHART_SIGNALS_KELTNER_LEGEND_LABEL_1"),
      lines: [
        {
          key: "KELTNER_UPPER_20",
          config: {
            color: "#87CEEB",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "KELTNER_MIDDLE_20",
          config: {
            color: "#FF6347",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "KELTNER_LOWER_20",
          config: {
            color: "#87CEEB",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "donchian_1",
      label: i18n.t("CHART_SIGNALS_DONCHIAN_LEGEND_LABEL_1"),
      lines: [
        {
          key: "DONCHIAN_UPPER_20",
          config: {
            color: "#6495ED",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "DONCHIAN_BASE_20",
          config: {
            color: "#FFE5B4",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "DONCHIAN_LOWER_20",
          config: {
            color: "#6495ED",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "lowestlow_1",
      label: i18n.t("CHART_SIGNALS_LOWESTLOW_LEGEND_LABEL_1"),
      lines: [
        {
          key: "LOWEST_LOW_5",
          config: {
            color: "#C23B22",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "lowestlow_2",
      label: i18n.t("CHART_SIGNALS_LOWESTLOW_LEGEND_LABEL_2"),
      lines: [
        {
          key: "LOWEST_LOW_7",
          config: {
            color: "#E2062C",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "highesthigh_1",
      label: i18n.t("CHART_SIGNALS_HIGHESTHIGH_LEGEND_LABEL_1"),
      lines: [
        {
          key: "HIGHEST_HIGH_5",
          config: {
            color: "#ACE1AF",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "highesthigh_2",
      label: i18n.t("CHART_SIGNALS_HIGHESTHIGH_LEGEND_LABEL_2"),
      lines: [
        {
          key: "HIGHEST_HIGH_7",
          config: {
            color: "#03C03C",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "sma_1",
      label: i18n.t("CHART_SIGNALS_SMA_LEGEND_LABEL_1"),
      lines: [
        {
          key: "SMA_50",
          config: {
            color: "#00FFFF",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "sma_2",
      label: i18n.t("CHART_SIGNALS_SMA_LEGEND_LABEL_2"),
      lines: [
        {
          key: "SMA_200",
          config: {
            color: "#FFFF4D",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
  ];
};

export const getRangeConfig = () => {
  return [
    {
      code: "aroon_1",
      label: i18n.t("CHART_SIGNALS_AROON_LEGEND_LABEL_1"),
      lines: [
        {
          key: "AROON_UPPER_14",
          config: {
            color: "#4CBB17",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "AROON_LOWER_14",
          config: {
            color: "#FF6347",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "aroon_2",
      label: i18n.t("CHART_SIGNALS_AROON_LEGEND_LABEL_2"),
      lines: [
        {
          key: "AROON_UPPER_25",
          config: {
            color: "#00BFFF",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "AROON_LOWER_25",
          config: {
            color: "#FF9F00",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "adx_1",
      label: i18n.t("CHART_SIGNALS_ADX_LEGEND_LABEL_1"),
      lines: [
        {
          key: "ADX_14",
          config: {
            color: "#FF0800",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "MDI_14",
          config: {
            color: "#D3D3D3",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "PDI_14",
          config: {
            color: "#A99A86",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "adx_2",
      label: i18n.t("CHART_SIGNALS_ADX_LEGEND_LABEL_2"),
      lines: [
        {
          key: "ADX_20",
          config: {
            color: "#FF0090",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "MDI_20",
          config: {
            color: "#F7E98E",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "PDI_20",
          config: {
            color: "#737000",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "rsi_1",
      label: i18n.t("CHART_SIGNALS_RSI_LEGEND_LABEL_1"),
      lines: [
        {
          key: "RSI_7",
          config: {
            color: "#9400D3",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "rsi_2",
      label: i18n.t("CHART_SIGNALS_RSI_LEGEND_LABEL_2"),
      lines: [
        {
          key: "RSI_14",
          config: {
            color: "#A67B5B",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "rsi_3",
      label: i18n.t("CHART_SIGNALS_RSI_LEGEND_LABEL_3"),
      lines: [
        {
          key: "RSI_21",
          config: {
            color: "#2962FF",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "stoch_1",
      label: i18n.t("CHART_SIGNALS_STOCH_LEGEND_LABEL_1"),
      lines: [
        {
          key: "STOCH_K_5_3_3",
          config: {
            color: "#FF0800",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "STOCH_D_5_3_3",
          config: {
            color: "#0093AF",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "stoch_2",
      label: i18n.t("CHART_SIGNALS_STOCH_LEGEND_LABEL_2"),
      lines: [
        {
          key: "STOCH_K_14_1_3",
          config: {
            color: "#FF6FFF",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "STOCH_D_14_1_3",
          config: {
            color: "#DCDCDC",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "stoch_3",
      label: i18n.t("CHART_SIGNALS_STOCH_LEGEND_LABEL_3"),
      lines: [
        {
          key: "STOCH_K_21_3_3",
          config: {
            color: "#0000CD",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
        {
          key: "STOCH_D_21_3_3",
          config: {
            color: "#FFE4B5",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "vix_1",
      label: i18n.t("CHART_SIGNALS_VIX_LEGEND_LABEL_1"),
      lines: [
        {
          key: "VIX_22",
          config: {
            color: "#ff8c00",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "mfi_1",
      label: i18n.t("CHART_SIGNALS_MFI_LEGEND_LABEL_1"),
      lines: [
        {
          key: "MFI_7",
          config: {
            color: "#FF00FF",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "mfi_2",
      label: i18n.t("CHART_SIGNALS_MFI_LEGEND_LABEL_2"),
      lines: [
        {
          key: "MFI_14",
          config: {
            color: "#7851A9",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
  ];
};

export const getNoRangeConfig = () => {
  return [
    {
      code: "cci_1",
      label: i18n.t("CHART_SIGNALS_CCI_LEGEND_LABEL_1"),
      lines: [
        {
          key: "CCI_14",
          config: {
            color: "#1E90FF",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "cci_2",
      label: i18n.t("CHART_SIGNALS_CCI_LEGEND_LABEL_2"),
      lines: [
        {
          key: "CCI_20",
          config: {
            color: "#03C03C",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
    {
      code: "cci_3",
      label: i18n.t("CHART_SIGNALS_CCI_LEGEND_LABEL_3"),
      lines: [
        {
          key: "CCI_21",
          config: {
            color: "#CD853F",
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          },
        },
      ],
    },
  ];
};

export const getHistogramConfig = () => {
  return [
    {
      code: "macd4c_1",
      label: i18n.t("CHART_SIGNALS_MACD4C_LEGEND_LABEL_1"),
      lines: [
        {
          key: "HISTOGRAM4C_20_50_9",
          style: {
            field: "HISTOGRAM4C_20_50_9",
            zero: true,
            upColor: "#26a69a",
            downColor: "#ef5350",
            firstCandleUpDown: false,
          },
          config: {
            color: "#CD7F32",
          },
        },
      ],
    },
  ];
};

export const getVolumeConfig = () => {
  return [
    {
      code: "volume_1",
      label: i18n.t("CHART_SIGNALS_VOLUME_LEGEND_LABEL_1"),
      lines: [
        {
          key: "VOLUME",
          style: {
            field: "CLOSE",
            zero: false,
            upColor: "#26a69a",
            downColor: "#ef5350",
            firstCandleUpDown: true,
          },
          config: {
            color: "#CD7F32",
            priceFormat: {
              type: "volume",
            },
            priceScaleId: "left",
          },
          options: {
            baseLineVisible: true,
            scaleMargins: {
              top: 0.8,
              bottom: 0,
            },
          },
        },
      ],
    },
  ];
};
