import { createSlice } from "@reduxjs/toolkit";
import { AXIOS_STATUSES, TUserProfile } from "../../types/entities";
import { login, logout } from "../actions/user";
import {
  getUserInStorage,
  removeUserInStorage,
  setUserInStorage,
} from "../../utilities/helpers";

export interface TUserState {
  auth: {
    loggedIn: boolean;
  };
  profile: TUserProfile | null;
  status: string | null;
  error: string | null;
}

const initialState: TUserState = {
  auth: {
    loggedIn: getUserInStorage() ? true : false,
  },
  profile: getUserInStorage() || null,
  status: null,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = AXIOS_STATUSES.IDLE;
        setUserInStorage(action.payload);
        state.auth.loggedIn = true;
        state.profile = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
        state.error = action.error.message || "Login failed";
      })
      .addCase(logout.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.status = "idle";
        removeUserInStorage();
        state.auth.loggedIn = false;
        state.profile = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Logout failed";
      });
  },
});

export default userSlice.reducer;
