import React, { useEffect, useRef } from "react";
import { IChartApi, createChart } from "lightweight-charts";
import i18n from "i18next";
import { sortMarkers, toPrecision } from "./../helpers";
import { TChartCandles } from "../../../types/entities";
import CandleLegend from "../ChartCommon/Legends/CandleLegend";
import LineLegend from "../ChartCommon/Legends/LineLegend";
import HistogramLegend from "../ChartCommon/Legends/HistogramLegend";
import TitleLegend from "../ChartCommon/Legends/TitleLegend";
import {
  getCandleOptions,
  getCrosshair,
  getPriceScale,
  getTimeScale,
  getGridLine,
  getLayout,
  getWatermark,
  getOverlayPriceScale,
  getLocalization,
  getHandleScroll,
  getHandleScale,
  getKineticOptions,
  getTrackingModeOptions,
} from "../ChartCommon/Settings/ChartOptions";

type Props = {
  symbol?: string;
  name?: string;
  candleData?: TChartCandles;
  lineSeries?: any;
  histogramSeries?: any;
  markers?: any;
  height?: number | undefined;
  fullScreen?: boolean;
  fullScreenOffset?: number;
  autoSize?: boolean;
  showTitleLegend?: boolean;
  showCandleLegend?: boolean;
  showLineLegend?: boolean;
  showHistogramLegend?: boolean;
  autoDestroy?: boolean;
  enableLeftScale?: boolean;
  enableRightScale?: boolean;
  fitContent?: boolean;
  minMove?: number | null;
};

const TradingChart: React.FC<Props> = ({
  symbol = "",
  name = null,
  candleData = [],
  lineSeries = {},
  histogramSeries = [],
  markers = [],
  height = 650,
  fullScreen = false,
  fullScreenOffset = 0,
  autoSize = true,
  showTitleLegend = true,
  showCandleLegend = true,
  showLineLegend = true,
  showHistogramLegend = true,
  autoDestroy = true,
  enableLeftScale = false,
  enableRightScale = true,
  fitContent = true,
  minMove = null,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const chartRef = useRef<IChartApi | null>(null);
  const candleRef = useRef<any>(null);
  const lineRef = useRef<any>(null);
  const histogramRef = useRef<any>(null);

  useEffect(() => {
    if (chartRef.current && autoDestroy) {
      chartRef.current.remove();
    }
    if (containerRef.current) {
      chartRef.current = createChart(containerRef.current, {
        width: containerRef.current.clientWidth,
        height: fullScreen ? window.innerHeight - fullScreenOffset : height,
        autoSize: fullScreen ? false : autoSize,
        watermark: getWatermark(
          i18n.t("COMMON_TRADING_CHART_WATERMARK") || "Dcatrader"
        ),
        layout: getLayout(),
        leftPriceScale: getPriceScale(enableLeftScale),
        rightPriceScale: getPriceScale(enableRightScale),
        overlayPriceScales: getOverlayPriceScale(),
        timeScale: getTimeScale(),
        crosshair: getCrosshair(),
        grid: {
          horzLines: getGridLine("#334158"),
          vertLines: getGridLine("#334158"),
        },
        localization: getLocalization(),
        handleScroll: getHandleScroll(),
        handleScale: getHandleScale(),
        kineticScroll: getKineticOptions(),
        trackingMode: getTrackingModeOptions(),
      });
    }
  });

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.subscribeCrosshairMove((params: any) => {
        if (candleRef.current) {
          const { time } = params;
          const current = candleRef.current;
          current.setCandleLegend(candleData, time);
        }
      });
    }
  }, [candleData]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.subscribeCrosshairMove((params: any) => {
        if (lineRef.current) {
          const { time } = params;
          const current = lineRef.current;
          current.setTradingLegend(lineSeries, time);
        }
      });
    }
  }, [lineSeries]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.subscribeCrosshairMove((params: any) => {
        if (histogramRef.current) {
          const { time } = params;
          const current = histogramRef.current;
          current.setTradingLegend(histogramSeries, time);
        }
      });
    }
  }, [histogramSeries]);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;
      const candleSeries = chart.addCandlestickSeries(getCandleOptions());
      candleSeries.setData(candleData);
      if (candleData.length > 0 && minMove !== null) {
        candleSeries.applyOptions({
          priceFormat: {
            type: "price",
            precision: toPrecision(minMove),
            minMove,
          },
        });
      }
      candleSeries.setMarkers(sortMarkers(markers));
      for (const code in lineSeries) {
        const { lines } = lineSeries[code];
        for (const line in lines) {
          const { config, data } = lines[line];
          const serie = chart.addLineSeries(config);
          serie.setData(data);
        }
      }
      for (const code in histogramSeries) {
        const { lines } = histogramSeries[code];
        for (const line in lines) {
          const { config, data, options } = lines[line];
          const serie = chart.addHistogramSeries(config);
          serie.priceScale().applyOptions(options);
          serie.setData(data);
        }
      }
      fitContent && chart.timeScale().fitContent();
    }
  }, [candleData, histogramSeries, lineSeries, markers, fitContent, minMove]);

  useEffect(() => {
    /* Necessary: initial legend values */
    if (candleRef.current) {
      const current = candleRef.current;
      current.setCandleLegend(candleData);
    }
    if (lineRef.current) {
      const current = lineRef.current;
      current.setTradingLegend(lineSeries);
    }
    if (histogramRef.current) {
      const current = histogramRef.current;
      current.setTradingLegend(histogramSeries);
    }
  }, [candleData, histogramSeries, lineSeries]);

  return (
    <div>
      <div
        style={{
          fontFamily: `"Calibri", "Helvetica", "Arial", sans-serif`,
          paddingLeft: "2%",
          fontSize: "85%",
          background: "#253248",
          color: "white",
        }}
      >
        {showTitleLegend && (
          <TitleLegend title={!name ? symbol : `${symbol} - ${name}`} />
        )}
        {showCandleLegend && candleData.length !== 0 && (
          <CandleLegend ref={candleRef} />
        )}
        {showLineLegend && lineSeries && <LineLegend ref={lineRef} />}
        {showHistogramLegend && histogramSeries && (
          <HistogramLegend ref={histogramRef} />
        )}
      </div>
      <div ref={containerRef} />
    </div>
  );
};

export default TradingChart;
