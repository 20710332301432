import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { first } from "lodash";
import { Box } from "@mui/material";
import Grid from "../../../Common/Grid/Grid";
import { fetchLastSynced } from "../../../../store/actions/period";
import { fetchAnalysisByOpenedAt } from "../../../../store/actions/analysis";
import { TAppState } from "../../../../store/reducers";
import { AppDispatch } from "../../../../store/configureStore";
import { analysisSelector } from "../../../selectors";
import getColumns, { gridKey, getVisibility } from "./AnalysisGridConfig";
import {
  useGetActiveAnalysesQuery,
  useGetActivePeriodsQuery,
} from "../../../../features/api";
import { listPeriodChanged } from "../../../../store/reducers/analysis";

const AnalysisGrid: React.FC = () => {
  const { data: periods, isLoading: periodsLoading } =
    useGetActivePeriodsQuery();
  const { data: analyses, isLoading: analysesLoading } =
    useGetActiveAnalysesQuery();
  const dispatch = useDispatch<AppDispatch>();
  const {
    list: {
      data,
      filters: { analysis, period, openedAt },
    },
  } = useSelector((state: TAppState) => analysisSelector(state));

  useEffect(() => {
    if (!periodsLoading && !analysesLoading) {
      const period = first(periods);
      dispatch(listPeriodChanged({ period, analyses }));
    }
  }, [analyses, analysesLoading, dispatch, periods, periodsLoading]);

  useEffect(() => {
    if (period) {
      const periodId = period.ID;
      dispatch(fetchLastSynced({ periodId }));
    }
  }, [dispatch, period]);

  useEffect(() => {
    if (analysis && period && openedAt) {
      const analysisId = analysis?.ID;
      const periodId = period?.ID;
      dispatch(
        fetchAnalysisByOpenedAt({
          analysisId,
          periodId,
          openedAt,
        })
      );
    }
  }, [dispatch, analysis, openedAt, period]);

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Grid
        gridKey={gridKey}
        columns={getColumns(analysis?.CODE)}
        data={data}
        visibility={getVisibility()}
      />
    </Box>
  );
};

export default AnalysisGrid;
