export const enAnalysisGrid = {
  ANALYSIS_GRID_ID: "Id",
  ANALYSIS_GRID_SYMBOL: "Symbol",
  ANALYSIS_GRID_PR_NAME: "Provider",
  ANALYSIS_GRID_BB_TIGHT: "Tight BB",
  ANALYSIS_GRID_BB_LOOSE: "Loose BB",
  ANALYSIS_GRID_BB_WIDTH: "BB width (%)",
  ANALYSIS_GRID_SMA50_D: "From MA50 (%)",
  ANALYSIS_GRID_SMA200_D: "From MA200 (%)",
  ANALYSIS_GRID_ATH_D: "From ATH (%)",
  ANALYSIS_GRID_ATL_D: "From ATL (%)",
  ANALYSIS_GRID_FLAG_CL: "Consolidation",
  ANALYSIS_GRID_PRICE_UP: "Price up",
  ANALYSIS_GRID_PRICE_DOWN: "Price down",
  ANALYSIS_GRID_GREEN: "Green",
  ANALYSIS_GRID_RED: "Red",
  ANALYSIS_GRID_VIX_UP: "Vix up",
  ANALYSIS_GRID_VIX_DOWN: "Vix down",
  ANALYSIS_GRID_VIX_22: "Vix (22)",
  ANALYSIS_GRID_RSIDIV_14: "RSI divergence (14)",
  ANALYSIS_GRID_RSIDIV_21: "RSI divergence (21)",
  ANALYSIS_GRID_MFIDIV_7: "MFI divergence (7)",
  ANALYSIS_GRID_MFIDIV_14: "MFI divergence (14)",
  ANALYSIS_GRID_TAIL: "Tail pattern",
  ANALYSIS_GRID_VALUE_POSITIVE: "Positive",
  ANALYSIS_GRID_VALUE_NEGATIVE: "Negative",
  ANALYSIS_GRID_VALUE_TOPPING: "Topping",
  ANALYSIS_GRID_VALUE_BOTTOMING: "Bottoming",
  ANALYSIS_GRID_SYMBOL_PREFIX: "Symbol prefix",
  ANALYSIS_GRID_TYPE_NAME: "Asset type",
};

export const elAnalysisGrid = {
  ANALYSIS_GRID_ID: "Αναγνωριστικό",
  ANALYSIS_GRID_SYMBOL: "Σύμβολο",
  ANALYSIS_GRID_PR_NAME: "Πάροχος",
  ANALYSIS_GRID_BB_TIGHT: "Στενά BB",
  ANALYSIS_GRID_BB_LOOSE: "Χαλαρά BB",
  ANALYSIS_GRID_BB_WIDTH: "Πλάτος BB (%)",
  ANALYSIS_GRID_SMA50_D: "Απόσταση MA50 (%)",
  ANALYSIS_GRID_SMA200_D: "Απόσταση MA200 (%)",
  ANALYSIS_GRID_ATH_D: "Απόσταση ATH (%)",
  ANALYSIS_GRID_ATL_D: "Απόσταση ATL (%)",
  ANALYSIS_GRID_FLAG_CL: "Παγίωση",
  ANALYSIS_GRID_PRICE_UP: "Ανοδική Τιμή",
  ANALYSIS_GRID_PRICE_DOWN: "Καθοδική Τιμή",
  ANALYSIS_GRID_GREEN: "Πράσινη Τιμή",
  ANALYSIS_GRID_RED: "Κόκκινη Τιμή",
  ANALYSIS_GRID_VIX_UP: "Άνοδος Vix",
  ANALYSIS_GRID_VIX_DOWN: "Κάθοδος Vix",
  ANALYSIS_GRID_VIX_22: "Vix (22)",
  ANALYSIS_GRID_RSIDIV_14: "Aπόκλιση RSI (14)",
  ANALYSIS_GRID_RSIDIV_21: "Aπόκλιση RSI (21)",
  ANALYSIS_GRID_MFIDIV_7: "Aπόκλιση MFI (7)",
  ANALYSIS_GRID_MFIDIV_14: "Aπόκλιση MFI (14)",
  ANALYSIS_GRID_TAIL: "Μοτίβο Ουράς",
  ANALYSIS_GRID_VALUE_POSITIVE: "Θετική",
  ANALYSIS_GRID_VALUE_NEGATIVE: "Αρνητική",
  ANALYSIS_GRID_VALUE_TOPPING: "Κορυφή",
  ANALYSIS_GRID_VALUE_BOTTOMING: "Πυθμένας",
  ANALYSIS_GRID_SYMBOL_PREFIX: "Πρόθεμα Συμβόλου",
  ANALYSIS_GRID_TYPE_NAME: "Τύπος στοιχείου",
};
