import { enDateDisplay, elDateDisplay } from "./DateDisplay/translations";
import { enMenu, elMenu } from "../App/Menu/translations";
import {
  enBaseDatePicker,
  elBaseDatePicker,
} from "./BaseDatePicker/translations";
import { enTradingChart, elTradingChart } from "./TradingChart/translations";
import {
  enLeftRightChart,
  elLeftRightChart,
} from "./LeftRightChart/translations";
import { enBaseSelect, elBaseSelect } from "./BaseSelect/translations";
import { enBaseFields, elBaseFields } from "./BaseFields/translations";

export const enCommon = {
  ...enDateDisplay,
  ...enMenu,
  ...enBaseDatePicker,
  ...enTradingChart,
  ...enLeftRightChart,
  ...enBaseSelect,
  ...enBaseFields,
};

export const elCommon = {
  ...elDateDisplay,
  ...elMenu,
  ...elBaseDatePicker,
  ...elTradingChart,
  ...elLeftRightChart,
  ...elBaseSelect,
  ...elBaseFields,
};
