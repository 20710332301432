export const enNotifications = {
  NOTIFICATIONS_HTTP_ERROR_0: "Server unavailable, please try again later.",
  NOTIFICATIONS_HTTP_ERROR_400: "Invalid request, please try again.",
  NOTIFICATIONS_HTTP_ERROR_401: "Failed to authenticate, please login first.",
  NOTIFICATIONS_HTTP_ERROR_403: "You don't have permission to access.",
  NOTIFICATIONS_HTTP_ERROR_404: "Not found, please contact support.",
  NOTIFICATIONS_HTTP_ERROR_412: "Invalid request, please try again.",
  NOTIFICATIONS_HTTP_ERROR_500: "Unknown error, please try again.",
  NOTIFICATIONS_HTTP_ERROR_503: "Service unavailable, please try again later.",
};

export const elNotifications = {
  NOTIFICATIONS_HTTP_ERROR_0:
    "Ο εξυπηρετητής δεν είναι διαθέσιμος, παρακαλώ δοκιμάστε ξανά αργότερα.",
  NOTIFICATIONS_HTTP_ERROR_400: "Μη έγκυρο αίτημα, παρακαλώ δοκιμάστε ξανά.",
  NOTIFICATIONS_HTTP_ERROR_401:
    "Αποτυχία ταυτοποίησης, παρακαλώ συνδεθείτε πρώτα.",
  NOTIFICATIONS_HTTP_ERROR_403: "Δεν έχετε άδεια πρόσβασης.",
  NOTIFICATIONS_HTTP_ERROR_404:
    "Δεν βρέθηκε, παρακαλώ επικοινωνήστε με την υποστήριξη.",
  NOTIFICATIONS_HTTP_ERROR_412: "Μη έγκυρο αίτημα, παρακαλώ δοκιμάστε ξανά.",
  NOTIFICATIONS_HTTP_ERROR_500: "Άγνωστο σφάλμα, παρακαλώ δοκιμάστε ξανά.",
  NOTIFICATIONS_HTTP_ERROR_503:
    "Η υπηρεσία δεν είναι διαθέσιμη, παρακαλώ δοκιμάστε ξανά αργότερα.",
};
