export const ROUTES = {
  WILDCARD: "*",
  HOME: "/",
  LOGIN: "/login",
  SIGNALS: "/signals",
  ANALYSIS: "/analysis",
  CHARTS: "/charts",
  ECONOMY: "/economy",
  OTHER: "/other",
};
