export const enFearGreedChart = {
  OTHER_FEARGREED_CRYPTO_CHART_HEADER: "Crypto Fear & Greed",
  OTHER_FEARGREED_CRYPTO_CHART_TODAY: "Today",
  OTHER_FEARGREED_CRYPTO_CHART_LEGEND: "Fear & Greed Index",
};

export const elFearGreedChart = {
  OTHER_FEARGREED_CRYPTO_CHART_HEADER: "Crypto Fear & Greed",
  OTHER_FEARGREED_CRYPTO_CHART_TODAY: "Σήμερα",
  OTHER_FEARGREED_CRYPTO_CHART_LEGEND: "Fear & Greed Index",
};
