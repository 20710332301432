import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { TAppState } from "../../../../../store/reducers";
import { AppDispatch } from "../../../../../store/configureStore";
import FullScreenDialog from "../../../../Common/FullScreenDialog/FullScreenDialog";
import { closeFullScreen } from "../../../../../store/reducers/charts";
import { chartsSelector } from "../../../../selectors";
import ChartsTopBarLeft from "./ChartsTopBarLeft";
import ChartsTopBarRight from "./ChartsTopBarRight";
import PriceChart from "../../Common/Charts/PriceChart";

const ChartsTopBar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    stored: {
      symbol,
      name,
      data,
      filters: { pair },
      view: { indicators, patterns, signals, fullScreen, autoSize },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));
  return (
    <Stack
      sx={{
        width: "100%",
        background: "rgba(96, 130, 182, 0.6)",
        flexDirection: "row",
      }}
    >
      <ChartsTopBarLeft />
      <ChartsTopBarRight />
      <FullScreenDialog
        isOpen={fullScreen}
        children={
          <>
            <Stack
              sx={{
                width: "100%",
                background: "rgba(96, 130, 182, 0.6)",
                flexDirection: "row",
              }}
            >
              <ChartsTopBarLeft />
              <ChartsTopBarRight />
            </Stack>
            <PriceChart
              symbol={symbol}
              name={name}
              minMove={Number(pair?.BASE_INCREMENT)}
              indicators={indicators}
              patterns={patterns}
              signals={signals}
              renderEmpty
              data={data}
              fullScreen={fullScreen}
              autoSize={autoSize}
            />
          </>
        }
        onCloseCallback={() => dispatch(closeFullScreen())}
      />
    </Stack>
  );
};

export default ChartsTopBar;
