import React, { useImperativeHandle, Ref, useState } from "react";
import { DateTime } from "luxon";
import { last, forEach } from "lodash";
import { parseChartDates } from "../../../../utilities/helpers";

const LineLegend = React.forwardRef(
  (props, ref: Ref<{ setLeftRightLegend: any; setTradingLegend: any }>) => {
    const [legends, setLegends] = useState<any[]>([]);

    useImperativeHandle(ref, () => ({ setLeftRightLegend, setTradingLegend }));

    const parse: (data: Array<any>, time: any | undefined) => any | null = (
      data,
      time = undefined
    ) => {
      const date = parseChartDates(time);
      const format = "yyyy-MM-dd";
      const [line] =
        date === undefined
          ? [last(data)]
          : data.filter(
              (c: any) =>
                DateTime.fromSeconds(c.time, {
                  zone: "utc",
                }).toFormat(format) === date
            );
      return line ? line : { time: null, value: null };
    };

    const setLeftRightLegend: (
      legendLabel: string,
      data: Array<any>,
      time: any
    ) => void = (legendLabel, data, time = undefined) => {
      let legends = new Array<any>();
      const values = parse(data, time);
      const { value } = values;
      legends.push({
        label: legendLabel,
        values: [value !== null ? value : ""],
      });
      setLegends(legends);
    };

    const setTradingLegend = (series: any, time = undefined) => {
      let legends = Array<any>();
      forEach(series, (serie: any) => {
        const { label, lines } = serie;
        let legend = { label, values: Array<any>() };
        forEach(lines, (line: any) => {
          const { data } = line;
          const values = parse(data, time);
          const { value } = values;
          legend.values.push(value !== null ? value : "");
        });
        legends.push(legend);
      });
      setLegends(legends);
    };

    return (
      <div>
        {legends.map((legend, key) => (
          <div key={key}>
            <span>{legend.label}: </span>
            <span>{legend.values.join(" ")} </span>
          </div>
        ))}
      </div>
    );
  }
);

export default LineLegend;
