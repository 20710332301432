import React, { SyntheticEvent } from "react";
import { useDispatch } from "react-redux";
import { Box, Tabs, Tab } from "@mui/material";
import ChartsStoredTab from "./Stored/ChartsStoredTab";
import ChartsLiveTab from "./Live/ChartsLiveTab";
import StorageIcon from "@mui/icons-material/Storage";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import i18n from "i18next";
import { AppDispatch } from "../../../store/configureStore";
import {
  storedIndicatorsChanged,
  storedSignalsChanged,
} from "../../../store/reducers/charts";

const ChartsPage: React.FC = () => {
  const [value, setValue] = React.useState("stored");
  const dispatch = useDispatch<AppDispatch>();

  const onTabChange = (e: SyntheticEvent, tab: string) => {
    setValue(tab);
    if (tab === "stored") {
      dispatch(storedIndicatorsChanged([]));
      dispatch(storedSignalsChanged([]));
    }
  };

  return (
    <div className="charts-page">
      <Box sx={{ width: "100%", paddingBottom: "4%" }}>
        <Tabs value={value} onChange={onTabChange} variant="fullWidth">
          <Tab
            icon={<StorageIcon />}
            iconPosition="start"
            value="stored"
            label={i18n.t("CHARTS_STORED_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
          <Tab
            icon={<LiveTvIcon />}
            iconPosition="start"
            value="live"
            label={i18n.t("CHARTS_LIVE_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        {value === "stored" && <ChartsStoredTab />}
        {value === "live" && <ChartsLiveTab />}
      </Box>
    </div>
  );
};

export default ChartsPage;
