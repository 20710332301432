import { TTextAlign } from "../../../types/entities";
import React from "react";

type Props = {
  width?: string | number;
  background: string;
  text: string;
  textAlign?: TTextAlign | undefined;
  fontSize?: string | number;
  shape?: "circle" | "rectangle";
  shapeWidth?: string | number;
  shapeHeight?: string | number;
  paddingBottom?: string | number;
};

const ColorLegend: React.FC<Props> = ({
  width = "100px",
  background,
  text,
  textAlign = "center",
  fontSize = "85%",
  shape = "rectangle",
  shapeWidth = "50px",
  shapeHeight = "50px",
  paddingBottom = "3%",
}) => {
  return (
    <div style={{ width }}>
      <div style={{ display: "flex", justifyContent: "center", paddingBottom }}>
        <div
          style={{
            background,
            width: shapeWidth,
            height: shapeHeight,
            borderRadius: shape === "circle" ? "50%" : 0,
          }}
        ></div>
      </div>
      <div style={{ textAlign, fontSize }}>{text}</div>
    </div>
  );
};

export default ColorLegend;
