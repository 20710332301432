import React from "react";
import { useTranslation } from "react-i18next";
import DateDisplay from "./../../Common/DateDisplay/DateDisplay";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  const getDateDisplayFlex = (width: number) => {
    if (width <= 500) return 3;
    else if (width > 500 && width <= 800) return 2;
    else return 1;
  };

  return (
    <div
      className="app-footer-container"
      style={{ display: "flex", fontSize: "80%" }}
    >
      <div style={{ flex: 1 }}>{t("APP_FOOTER_COPYRIGHT")}</div>
      <div style={{ flex: 1 }}></div>
      <div
        style={{
          flex: getDateDisplayFlex(window.innerWidth),
          fontSize: "85%",
        }}
      >
        <DateDisplay />
      </div>
    </div>
  );
};

export default Footer;
