import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { TAppState } from "../../../../../store/reducers";
import { AppDispatch } from "../../../../../store/configureStore";
import FullScreenDialog from "../../../../Common/FullScreenDialog/FullScreenDialog";
import { toggleLiveMode } from "../../../../../store/reducers/charts";
import { chartsSelector } from "../../../../selectors";
import ChartLiveFields from "./ChartsLiveFields";
import ChartLiveInfo from "./ChartsLiveInfo";
import ChartLiveTopBar from "./ChartsLiveTopBar";

const ChartsLiveDialog: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    live: {
      view: { modeActive },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));

  return (
    <Stack
      sx={{
        width: "100%",
        background: "rgba(96, 130, 182, 0.6)",
        flexDirection: "row",
      }}
    >
      <FullScreenDialog
        isOpen={modeActive}
        children={
          <>
            <ChartLiveTopBar />
            <ChartLiveFields />
            <ChartLiveInfo />
          </>
        }
        onCloseCallback={() => dispatch(toggleLiveMode(false))}
      />
    </Stack>
  );
};

export default ChartsLiveDialog;
