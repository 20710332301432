import { enMenu, elMenu } from "./Menu/translations";
import { enHeader, elHeader } from "./Header/translations";
import { enFooter, elFooter } from "./Footer/translations";
import { enNotifications, elNotifications } from "./Notifications/translations";
import {
  enSettingsDialog,
  elSettingsDialog,
} from "./SettingsDialog/translations";

export const enApp = {
  ...enMenu,
  ...enHeader,
  ...enFooter,
  ...enNotifications,
  ...enSettingsDialog,
};

export const elApp = {
  ...elMenu,
  ...elHeader,
  ...elFooter,
  ...elNotifications,
  ...elSettingsDialog,
};
