import { enAnalysisGrid, elAnalysisGrid } from "./Grid/translations";
import { enAnalysisFaq, elAnalysisFaq } from "./Faq/translations";

export const enAnalysisPage = {
  ...enAnalysisGrid,
  ...enAnalysisFaq,
};

export const elAnalysisPage = {
  ...elAnalysisGrid,
  ...elAnalysisFaq,
};
