import i18n from "i18next";
import {
  isBuy,
  isSell,
  isBullish,
  isBearish,
  isGolden,
  isDeath,
} from "../../../../utilities/helpers";
import { validateSignalByCode } from "../../../Common/helpers";

const mapBuySell = (p: any) => {
  const { value } = p;
  switch (value) {
    case 1:
      return i18n.t(`SIGNALS_GRID_VALUE_BUY`);
    case 2:
      return i18n.t(`SIGNALS_GRID_VALUE_SELL`);
    default:
      return `-`;
  }
};

const mapGoldenDeath = (p: any) => {
  const { value } = p;
  switch (value) {
    case 1:
      return i18n.t(`SIGNALS_GRID_VALUE_GOLDEN`);
    case 2:
      return i18n.t(`SIGNALS_GRID_VALUE_DEATH`);
    default:
      return `-`;
  }
};

const mapBullishBearish = (p: any) => {
  const { value } = p;
  switch (value) {
    case 1:
      return i18n.t(`SIGNALS_GRID_VALUE_BULLISH`);
    case 2:
      return i18n.t(`SIGNALS_GRID_VALUE_BEARISH`);
    default:
      return `-`;
  }
};

const getSpecific = (code: string) => {
  switch (code) {
    case "cross":
      return [
        {
          field: `CROSS_50_200`,
          headerName: i18n.t(`SIGNALS_GRID_CROSS_50_200`),
          width: 120,
          valueGetter: (p: any) => mapGoldenDeath(p),
        },
        {
          field: `GOLDEN_ELAPSED_50_200`,
          headerName: i18n.t(`SIGNALS_GRID_CROSS_GOLDEN_ELAPSED_50_200`),
          width: 120,
        },
        {
          field: `DEATH_ELAPSED_50_200`,
          headerName: i18n.t(`SIGNALS_GRID_CROSS_DEATH_ELAPSED_50_200`),
          width: 120,
        },
      ];
    case "macd4cdiv":
      return [
        {
          field: `CLASSIC_20_50_9`,
          headerName: i18n.t(`SIGNALS_GRID_MACD4CDIV_CLASSIC_20_50_9`),
          width: 120,
          valueGetter: (p: any) => mapBullishBearish(p),
        },
        {
          field: `HIDDEN_20_50_9`,
          headerName: i18n.t(`SIGNALS_GRID_MACD4CDIV_HIDDEN_20_50_9`),
          width: 120,
          valueGetter: (p: any) => mapBullishBearish(p),
        },
      ];
    case "escan":
      return [
        {
          field: `ESCAN_5`,
          headerName: i18n.t(`SIGNALS_GRID_ESCAN_5`),
          width: 120,
          valueGetter: (p: any) => mapBuySell(p),
        },
        {
          field: `ELAPSED_5`,
          headerName: i18n.t(`SIGNALS_GRID_ELAPSED`),
          width: 120,
        },
      ];
    case "uscan":
      return [
        {
          field: `USCAN_5`,
          headerName: i18n.t(`SIGNALS_GRID_USCAN_5`),
          width: 120,
          valueGetter: (p) => mapBuySell(p),
        },
        {
          field: `ELAPSED_5`,
          headerName: i18n.t(`SIGNALS_GRID_ELAPSED`),
          width: 120,
        },
      ];
    default:
      return [];
  }
};

const getColumns = (code: string) => {
  const specific = getSpecific(code);
  return [
    {
      field: `ID`,
      headerName: i18n.t(`SIGNALS_GRID_ID`),
      width: 50,
    },
    {
      field: `SYMBOL`,
      headerName: i18n.t(`SIGNALS_GRID_SYMBOL`),
      width: 120,
      valueGetter: (p) => p.row.SYMBOL.replaceAll(p.row.SYMBOL_PREFIX, ""),
    },
    {
      field: `TYPE_NAME`,
      headerName: i18n.t(`SIGNALS_GRID_TYPE_NAME`),
      width: 130,
    },
    {
      field: `PR_NAME`,
      headerName: i18n.t(`SIGNALS_GRID_PR_NAME`),
      width: 100,
    },
    {
      field: `OPENED_AT`,
      headerName: i18n.t(`CANDLE_OPENED_AT`),
      width: 170,
    },
    {
      field: `CLOSED_AT`,
      headerName: i18n.t(`CANDLE_CLOSED_AT`),
      width: 170,
    },
    {
      field: `OPEN`,
      headerName: i18n.t(`CANDLE_OPEN`),
      width: 100,
    },
    {
      field: `CLOSE`,
      headerName: i18n.t(`CANDLE_CLOSE`),
      width: 100,
    },
    {
      field: `HIGH`,
      headerName: i18n.t(`CANDLE_HIGH`),
      width: 100,
    },
    {
      field: `LOW`,
      headerName: i18n.t(`CANDLE_LOW`),
      width: 100,
    },
    {
      field: `SYMBOL_PREFIX`,
      headerName: i18n.t(`SIGNALS_GRID_SYMBOL_PREFIX`),
      width: 100,
    },
  ].concat(specific);
};

export const getVisibility = () => {
  return {
    ID: false,
    ELAPSED_5: false,
    ELAPSED_26: false,
    SYMBOL_PREFIX: false,
  };
};

export const getLegendsConfig = (code: string) => {
  switch (code) {
    case "cross":
      return [
        {
          background: "rgba(218, 165, 32, 0.5)",
          text: "SIGNALS_CROSS_IS_GOLDEN_50_200",
        },
        {
          background: "rgba(128, 0, 128, 0.5)",
          text: "SIGNALS_CROSS_IS_DEATH_50_200",
        },
      ];
    case "macd4cdiv":
      return [
        {
          background: "rgba(8, 153, 29, 0.5)",
          text: "SIGNALS_MACD4CDIV_C_BULL_20_50_9",
        },
        {
          background: "rgba(242, 54, 69, 0.5)",
          text: "SIGNALS_MACD4CDIV_C_BEAR_20_50_9",
        },
        {
          background: "rgba(8, 153, 29, 0.2)",
          text: "SIGNALS_MACD4CDIV_H_BULL_20_50_9",
        },
        {
          background: "rgba(242, 54, 69, 0.2)",
          text: "SIGNALS_MACD4CDIV_H_BEAR_20_50_9",
        },
      ];
    case "escan":
      return [
        {
          background: "rgba(8, 153, 29, 0.5)",
          text: "SIGNALS_ESCAN_IS_BUY_5",
        },
        {
          background: "rgba(242, 54, 69, 0.5)",
          text: "SIGNALS_ESCAN_IS_SELL_5",
        },
      ];
    case "uscan":
      return [
        {
          background: "rgba(8, 153, 29, 0.5)",
          text: "SIGNALS_USCAN_IS_BUY_5",
        },
        {
          background: "rgba(242, 54, 69, 0.5)",
          text: "SIGNALS_USCAN_IS_SELL_5",
        },
      ];
    default:
      return [];
  }
};

export const getFaqConfig = (code: string) => {
  switch (code) {
    case "cross":
      return [
        {
          title: "SIGNALS_FAQ_CROSS_TITLE_1",
          description: "SIGNALS_FAQ_CROSS_DESCRIPTION_1",
        },
      ];
    case "macd4cdiv":
      return [
        {
          title: "SIGNALS_FAQ_MACD4CDIV_TITLE_1",
          description: "SIGNALS_FAQ_MACD4CDIV_DESCRIPTION_1",
        },
      ];
    case "escan":
      return [
        {
          title: "SIGNALS_FAQ_ESCAN_TITLE_1",
          description: "SIGNALS_FAQ_ESCAN_DESCRIPTION_1",
        },
      ];
    case "uscan":
      return [
        {
          title: "SIGNALS_FAQ_USCAN_TITLE_1",
          description: "SIGNALS_FAQ_USCAN_DESCRIPTION_1",
        },
      ];
    default:
      return [];
  }
};

export const getSignalRowClass = (code: string, row: any) => {
  let className = `signal-row--`;
  switch (code) {
    case "cross":
      if (isGolden(row.CROSS_50_200)) className += `${code}--golden`;
      else if (isDeath(row.CROSS_50_200)) className += `${code}--death`;
      else className += `${code}`;
      break;
    case "macd4cdiv":
      if (isBullish(row.CLASSIC_20_50_9)) className += `${code}--bull-strong`;
      else if (isBearish(row.CLASSIC_20_50_9))
        className += `${code}--bear-strong`;
      else if (isBullish(row.HIDDEN_20_50_9))
        className += `${code}--bull-light`;
      else if (isBearish(row.HIDDEN_20_50_9))
        className += `${code}--bear-light`;
      else className += `${code}`;
      break;
    case "escan":
      if (isBuy(row.ESCAN_5, row.ELAPSED_5)) className += `${code}--buy`;
      else if (isSell(row.ESCAN_5, row.ELAPSED_5)) className += `${code}--sell`;
      else className += `${code}`;
      break;
    case "uscan":
      if (isBuy(row.USCAN_5, row.ELAPSED_5)) className += `${code}--buy`;
      else if (isSell(row.USCAN_5, row.ELAPSED_5)) className += `${code}--sell`;
      else className += `${code}`;
      break;
  }
  return className;
};

export const filterGridData = (code: string, data: Array<any>) => {
  return data.filter((row) => validateSignalByCode(code, row));
};

export const gridKey = "ID";
export default getColumns;
