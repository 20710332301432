import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchFearAndGreed } from "../../../../store/actions/other";
import { AppDispatch } from "../../../../store/configureStore";
import FearGreedMeter from "./Chart/FearGreedMeter";
import FearGreedChart from "./Chart/FearGreedChart";

const FearGreedTab: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchFearAndGreed({ type: "crypto" }));
  }, [dispatch]);

  return (
    <div className="feargreed-page">
      <FearGreedMeter />
      <FearGreedChart />
    </div>
  );
};

export default FearGreedTab;
