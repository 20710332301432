import React from "react";
import RankingGrid from "./Grid/RankingGrid";
import RankingFaq from "./Faq/RankingFaq";

const RankingTab: React.FC = () => {
  return (
    <div className="ranking-page">
      <RankingGrid />
      <RankingFaq />
    </div>
  );
};

export default RankingTab;
